import { appInsights } from './appinsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
export class Logger {
    setUserContext(claims) {
        if (appInsights) {
            appInsights.setAuthenticatedUserContext(claims.sub, undefined, true);
        }
    }
    logException(error) {
        if (appInsights) {
            appInsights.trackException({
                error,
                severityLevel: SeverityLevel.Error,
            });
        }
    }
    logError(message, properties = {}) {
        this.logTrace(message, properties, 'Error');
    }
    logInfo(message, properties = {}) {
        this.logTrace(message, properties, 'Information');
    }
    logVerbose(message, properties = {}) {
        this.logTrace(message, properties, 'Verbose');
    }
    logWarning(message, properties = {}) {
        this.logTrace(message, properties, 'Warning');
    }
    logTrace(message, properties = {}, level) {
        const severityLevel = level === 'Information'
            ? SeverityLevel.Information
            : level === 'Verbose'
                ? SeverityLevel.Verbose
                : level === 'Warning'
                    ? SeverityLevel.Warning
                    : SeverityLevel.Error;
        if (appInsights) {
            appInsights.trackTrace({
                message,
                severityLevel,
            }, properties);
        }
    }
}
export const logger = new Logger();
