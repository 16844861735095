import './horizon';
import './main.scss';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Host } from './Host';
import { PROXY_NAMES } from './config';
// if using mocks...
if (process.env.USE_MSW) {
    // use localhost for API requests
    sessionStorage.setItem('com.unboundid.kmxpos.proxy', PROXY_NAMES.mock);
    // start the mock service worker unless specifically disabled by tests
    if (!window.E2EMSWEnabled) {
        console.log('Starting Mock API...');
        const { worker } = require('./.mocks/mswBrowser');
        worker.start();
    }
    // If window.CashDrawer is already defined, then we're running in Electron.
    // In that case, use the Electron provided api instead.
    if (!window.CashDrawer) {
        console.log('Adding Mock Cash Drawer...');
        require('./.mocks/posCashDrawer');
    }
    // If window.CashDrawer is already defined, then we're running in Electron.
    // In that case, use the Electron provided api instead.
    if (!window.CardFlow) {
        console.log('Adding Mock Card Reader...');
        require('./.mocks/posCardFlow');
    }
    // If window.SystemInteraction is already defined, then we're running in Electron.
    // In that case, use the Electron provided api instead.
    if (!window.SystemInteraction) {
        console.log('Adding Mock System Interaction...');
        require('./.mocks/posSystemInteraction');
    }
    // Use mock claims
    sessionStorage.setItem('com.unboundid.kmxpos.profile', JSON.stringify({
        name: 'Dev Loper',
        sub: '123456',
        email: 'Dev_L_Loper@bogus.com',
        'kmx.managerid': '234567',
        'kmx.jobcode': 'OFFMC',
        'kmx.jobtitle': 'Business Office Manager',
        'kmx.locationnumber': ['7101'],
        'kmx.managementlevel': 'Sr Manager',
        'kmx.jobfunction': 'Business Office',
        'kmx.roles': ['DEVMODE', 'POS-ADMIN'],
    }));
}
else {
    sessionStorage.setItem('com.unboundid.kmxpos.proxy', PROXY_NAMES.pathfinders_oauthproxy);
}
// configure
const root = createRoot(document.getElementById('main-content'));
root.render(React.createElement(Host, null));
