import { useCreateOrdersMutation, useGetBasketQuery, useGetOrderTenderSummaryQuery, } from '@src/api/CheckoutApi';
import { Card, CardRow } from '@src/components/Card';
import { FlexColumn } from '@src/components/Flex';
import { HeadedContainerCard } from '@src/components/HeadedContainerCard';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { SummaryTotal } from '@src/components/SummaryTotal';
import { Suspense } from '@src/components/Suspense';
import { addToast } from '@src/components/toast/ToastSlice';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { usePageTitle } from '@src/hooks/usePageTitle';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';
const TenderOrderDetails = ({ summary }) => {
    return (React.createElement(HeadedContainerCard, { title: "Appraisal Purchase Order" },
        React.createElement("div", { className: "d-flex flex-column gap-3" },
            React.createElement(CardRow, { label: "Vehicle", value: summary.vehicleName }),
            React.createElement("hzn-divider", null),
            React.createElement(CardRow, { label: "Offer Amount", value: AsCurrency(summary.vehicleOfferValue) }),
            React.createElement("hzn-divider", null),
            summary.espCancellationValue > 0 && (React.createElement(React.Fragment, null,
                React.createElement(CardRow, { label: "GAP Cancellation", value: AsCurrency(summary.gapCancellationValue) }),
                React.createElement("hzn-divider", null))),
            summary.espCancellationValue > 0 && (React.createElement(React.Fragment, null,
                React.createElement(CardRow, { label: "ESP Cancellation", value: AsCurrency(summary.espCancellationValue) }),
                React.createElement("hzn-divider", null))),
            summary.payoffToLienholder > 0 && (React.createElement(React.Fragment, null,
                React.createElement(CardRow, { label: `Payoff to ${summary.lienholderName}`, 
                    // TODO: add lienholder name to DTO, adjust mock data layer
                    value: AsCurrency(summary.payoffToLienholder) }),
                React.createElement("hzn-divider", null))),
            React.createElement(CardRow, { label: "Customer equity", value: React.createElement("hzn-text", { tone: summary.equityTotal < 0 ? 'critical' : 'body' }, AsCurrency(summary.equityTotal)) }),
            React.createElement("hzn-divider", null),
            React.createElement(CardRow, { label: React.createElement("hzn-text", { weight: "bold" },
                    "Due to ",
                    summary.equityTotal >= 0 ? 'Customer' : 'CarMax'), className: "pt-3", value: React.createElement("hzn-text", { weight: "bold" }, AsCurrency(Math.abs(summary.equityTotal))) }))));
};
export const TenderOrder = ({ basketId }) => {
    usePageTitle('Tender Order');
    const dispatch = useAppDispatch();
    const basket = useGetBasketQuery({ basketId });
    const summary = useGetOrderTenderSummaryQuery({ basketId });
    const [tenderOrder, tenderOrderStatus] = useCreateOrdersMutation();
    // TODO: place redirect for tender order validation when it's done.
    const calculateTotalDueToday = (summary) => {
        const diff = summary.equityTotal + summary.processedPaymentsTotal;
        // if diff is negative, customer owes that much. otherwise, they owe nothing.
        return diff < 0 ? diff * -1 : 0;
    };
    const onTenderOrder = () => tenderOrder({ orderTenderRequestDto: { basketId } })
        .unwrap()
        .then(() => dispatch(addToast({ tone: 'positive', message: 'Order tendered successfully.' })))
        .then(() => basket.refetch())
        .catch(err => dispatch(addToast({ tone: 'critical', message: `Order tender error: ${err}` })));
    return (React.createElement("div", { className: "d-flex flex-column gap-4" },
        React.createElement(Card, { title: "Tender Order" },
            React.createElement(Suspense, { suspended: summary.isLoading }, summary.isSuccess && summary.data != null && (React.createElement(FlexColumn, null,
                React.createElement(TenderOrderDetails, { summary: summary.data }),
                React.createElement(SummaryTotal, { summary: summary.data }))))),
        React.createElement("div", { className: "d-flex flex-row-reverse" },
            React.createElement(HorizonButton, { variant: "cta", loading: tenderOrderStatus.isLoading, disabled: basket.isFetching || basket.data.basketStatus == 'COMPLETED', onClick: onTenderOrder }, "Tender Order"))));
};
