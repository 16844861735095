import { CurrencyInput } from '@src/components/horizon/CurrencyInput';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import './PaymentAddCard.scss';
import React, { useMemo, useRef, useState } from 'react';
import Select from '@src/components/horizon/Select';
import '@horizon/icons/individual/hzn-trash';
import '@horizon/icon-button/hzn-icon-button';
import { useAddBasketPaymentMutation, useUpdatePaymentMutation, } from '@src/api/CheckoutApi';
import { FlexRow } from '@src/components/Flex';
const MaxCheckAmount = 250;
const MinAmount = 0.01;
const ValidDollarAmtRequiredMessage = 'A valid dollar amount is required.';
const MaxCheckAmountMessage = 'A maximum amount of $250 will be accepted.';
const MaxNonCheckAmountMessage = 'Amount cannot be greater than outstanding balance.';
const checkValidations = {
    min: MinAmount,
    max: MaxCheckAmount,
    errorMessages: {
        valueMissing: ValidDollarAmtRequiredMessage,
        valid: ValidDollarAmtRequiredMessage,
        rangeUnderflow: ValidDollarAmtRequiredMessage,
        rangeOverflow: MaxCheckAmountMessage,
    }
};
const nonCheckValidations = (max) => ({
    min: MinAmount,
    max,
    errorMessages: {
        valueMissing: ValidDollarAmtRequiredMessage,
        valid: ValidDollarAmtRequiredMessage,
        rangeUnderflow: ValidDollarAmtRequiredMessage,
        rangeOverflow: MaxNonCheckAmountMessage,
    }
});
export const PaymentAddCard = ({ basketId, partyId, payment, balance, onClose }) => {
    const [addPayment, addPaymentState] = useAddBasketPaymentMutation();
    const [updatePayment, updatePaymentState] = useUpdatePaymentMutation();
    const currencyRef = useRef();
    const [paymentType, setPaymentType] = useState(payment?.paymentType ?? 'CASH');
    const [paymentAmount, setPaymentAmount] = useState(payment?.amount);
    const paymentValidations = useMemo(() => (paymentType === 'CHECK') ? checkValidations : nonCheckValidations(balance), [paymentType, balance]);
    const onSavePayment = () => {
        if (!currencyRef.current.validate())
            return;
        if (!payment) {
            addPayment({
                basketId,
                body: {
                    paymentType,
                    amount: paymentAmount,
                    lineOfBusinessProvided: false,
                    direction: 'INBOUND',
                    partyRole: {
                        partyId,
                        relationship: 'SELLER',
                    },
                },
            })
                .unwrap()
                .then(() => onClose())
                .catch(err => console.log('Save payment error', err));
        }
        else {
            updatePayment({
                basketId,
                paymentId: payment.id,
                body: { paymentType, amount: paymentAmount },
            })
                .unwrap()
                .then(onClose)
                .catch(err => console.log('Update payment error', err));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexRow, { className: 'align-items-top flex-fill' },
            React.createElement(Select, { className: 'paymentInput', value: paymentType, onSelectionChanged: value => setPaymentType(value), label: "Select a payment method" },
                React.createElement("option", { value: "CASH" }, "Cash"),
                React.createElement("option", { value: "CERTIFIED_FUNDS" }, "Certified Funds"),
                React.createElement("option", { value: "CHECK" }, "Check (max $250)"),
                React.createElement("option", { value: "DEBIT" }, "Debit Card")),
            React.createElement(CurrencyInput, { ref: currencyRef, label: 'Amount', value: paymentAmount, min: paymentValidations.min, max: paymentValidations.max, errorMessages: paymentValidations.errorMessages, onValueChanged: setPaymentAmount, className: 'w-50', style: { minHeight: '7rem' } })),
        React.createElement(FlexRow, null,
            React.createElement(HorizonButton, { compact: true, variant: "primary", onClick: () => onSavePayment(), loading: addPaymentState.isLoading || updatePaymentState.isLoading }, "Save Payment"),
            React.createElement(HorizonButton, { compact: true, variant: "secondary", onClick: () => onClose(), disabled: addPaymentState.isLoading || updatePaymentState.isLoading }, "Cancel"))));
};
