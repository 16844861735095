import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { FlexColumn } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { useHardwareSwipeCardMutation } from '@src/api/CardFlowApi';
import { selectCardReaderReady } from '@src/store/HardwareSlice';
import { CardFlowEvent } from '@src/api/electron/CardFlowApi';
import { Modal } from 'react-bootstrap';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { DebitPaymentDescription } from './DebitPaymentDescription';
const tryAgain = 'Try processing the payment again. If the issue is not resolved, try processing this payment again from a different computer, then partner with the Manager on Duty and enter a Service Station ticket.';
// TODO: Describe all error and recovery paths
const getCardErrorReason = (result) => {
    switch (result.cardFlowEvent) {
        case CardFlowEvent.TransactionRejected:
            return 'The transaction was rejected.';
        case CardFlowEvent.CardEntryErrored:
            return 'There was a card entry error.';
        case CardFlowEvent.CardEntryCancelled:
            return 'The card entry was cancelled.';
        default:
            return 'Some other error occurred.';
    }
};
export const ReceiveCardData = (props) => {
    const cardReaderReady = useAppSelector(selectCardReaderReady);
    const [swipeCard, swipeCardResult] = useHardwareSwipeCardMutation();
    const [cardErrorReason, setCardErrorReason] = useState(undefined);
    const [retry, setRetry] = useState(false);
    const handleCardSwipeResult = (result) => {
        switch (result.cardFlowEvent) {
            case CardFlowEvent.CardEntrySuccess:
            case CardFlowEvent.PINEntrySuccess: {
                props.onReceived(result.data);
                break;
            }
            default: {
                setCardErrorReason(getCardErrorReason(result));
                setRetry(true);
                break;
            }
        }
    };
    const receiveCardData = () => {
        console.log('receiveCardData()');
        setCardErrorReason(undefined);
        swipeCard({})
            .unwrap()
            .then(handleCardSwipeResult)
            .catch(err => setCardErrorReason(`The card reader malfunctioned.`));
    };
    useEffect(() => {
        if (cardReaderReady) {
            receiveCardData();
        }
    }, []);
    console.log('swipeCardResult', swipeCardResult);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal.Header, null,
            React.createElement("hzn-heading", { size: "xsmall" }, "Processing payment...")),
        React.createElement(Modal.Body, null,
            React.createElement(FlexColumn, null,
                React.createElement(DebitPaymentDescription, null),
                React.createElement(HorizonAlert, { show: swipeCardResult.isLoading, heading: "Please follow the instructions on the card reader.", tone: "info" }),
                React.createElement(HorizonAlert, { show: !!cardErrorReason, heading: cardErrorReason, "sub-heading": tryAgain, tone: "critical" }),
                React.createElement(HorizonAlert, { show: cardReaderReady === false, heading: "The card reader is not responding.", "sub-heading": tryAgain, tone: "caution" }))),
        retry && (React.createElement(Modal.Footer, null,
            React.createElement(FlexColumn, { className: "w-100" },
                React.createElement(HorizonButton, { variant: "primary", onClick: () => {
                        receiveCardData();
                        setRetry(false);
                    } }, "Try Again"),
                React.createElement(HorizonButton, { variant: "tertiary", onClick: () => props.onCancel() }, "Cancel"))))));
};
