import { LitElement, html } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { eventEmitter } from '@horizon/common/events';

/**
 *
 * @tag hzn-radio
 * @tagname hzn-radio
 * @summary A single radio input element for use in hzn-radios
 */

export class HznRadio extends LitElement {

  /**
   * @private
   */
  #emit = eventEmitter(this);

  /**
   * Set the value for this radio button
   */
  @property({ type: String }) value = '';

  /**
   * Set the help text for this radio
   */
  @property({ type: String, attribute: 'helper-text' }) helperText?: string = '';

  /**
   * Set the radio to be checked
   */
  @property({ type: Boolean }) checked?: boolean = false;

  connectedCallback(): void {
    super.connectedCallback();
    this.#emit({ type: 'slotchange' });
  }

  /**
   * @private
   */
  updated(): void {
    this.#emit({ type: 'slotchange' });
  }

  /**
   * @private
   */
  #handleSlotChange() {
    this.#emit({ type: 'slotchange' })
  }

  render() {
    return html`<slot @slotchange=${this.#handleSlotChange}></slot>`;
  }
}
