import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    accessToken: undefined,
    claims: undefined,
};
export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthState: (state, action) => {
            state.claims = action.payload.claims;
            state.accessToken = action.payload.accessToken;
        },
    },
});
export const { setAuthState } = AuthSlice.actions;
export const selectClaims = (state) => state.auth.claims;
export const selectIsLoggedIn = (state) => state.auth.claims !== undefined;
export default AuthSlice.reducer;
