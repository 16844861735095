import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import './NavMenu.scss';
import '@horizon/icons/individual/hzn-person';
import '@horizon/icons/individual/hzn-location';
const NavMenuBlock = ({ label, icon, className }) => (React.createElement("div", { className: className },
    React.createElement("div", { className: "Nav-menu-block d-flex flex-row gap-2" },
        icon && React.createElement("div", { className: "Nav-menu-block_icon" }, icon),
        React.createElement("hzn-text", { inline: true }, label))));
const CarmaxLogo = () => (React.createElement("svg", { className: "d-none d-lg-inline me-3 header_logo", role: "presentation", xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", viewBox: "0 0 327 64", height: "24", width: "124" },
    React.createElement("path", { d: "M289.8 5.05a1.1 1.1 0 0 0 .85-1.12c0-1.17-1.23-1.17-1.57-1.17h-1.65V7.4h.64V5.3h.8c.76 0 .94.2 1 .9a5.26 5.26 0 0 0 .23 1.2h.66a6.74 6.74 0 0 1-.28-1.46c-.05-.57-.2-.77-.7-.9zm-.72-.3h-1V3.3h1c.34 0 .92 0 .92.74s-.6.7-.92.7zM289 .8a4.33 4.33 0 1 0 4.32 4.32A4.34 4.34 0 0 0 289 .8zm0 8.2a3.83 3.83 0 1 1 3.8-3.84A3.82 3.82 0 0 1 289 9z" }),
    React.createElement("path", { d: "M129.42 54.55h32.32V64h-32.32v-9.45zm41.53 0V64h32.32v-9.45zm41.52 0V64h32.32v-9.45zm41.52 0V64h32.3v-9.45zm41.52 0V64h32.32v-9.45z" }),
    React.createElement("path", { d: "M129.57 42.43V1.23h16.26l.4 6.24h.15c2.82-5.9 7-7.47 10.88-7.47 5.3 0 9.16 2.38 11.3 7.47 2.82-5.9 7-7.47 10.88-7.47 7 0 12 4.1 12 15v27.43h-16.7V16.58c0-2.87-1.3-4.43-2.88-4.43s-3 1.3-3 4.6v25.68h-16.7V16.58c0-2.87-1.3-4.43-2.9-4.43s-3 1.3-3 4.6v25.68zm138.26 0h16.75l-11.48-22 9.35-19H265l-4.64 13-5.6-13H237.3l10.16 19-11.15 22h16.95l7.1-15 7.48 15zm-48.7-14.2V24.8h-2.6c-5.1 0-6.2 2.46-6.2 4.74 0 2.62 1.5 4.25 4.44 4.25 3.34 0 4.37-2.2 4.37-5.56zm0-11.32v-4.23c0-2-1-3.27-3.72-3.27-2.37 0-3.65.82-3.88 4.25h-15.86c.64-11 10.5-13.4 20.57-13.4 15.33 0 20.25 5 20.25 14.54v27.6h-16.85l-.5-3.88c-2.78 3.76-7 5.15-11.36 5.15-8.1 0-13.8-4.4-13.8-12.5 0-10.12 7.93-14.3 22.86-14.3z" }),
    React.createElement("path", { d: "M25.18 27.16H40.5c0 11.38-9.1 16.35-19.32 16.35C6.7 43.5 0 34.7 0 21.6-.38 8.75 7.28.06 21.23.06c10.4 0 19.22 4.23 19.28 16.36h-15c-.22-4.68-1.93-5.6-3.93-5.6-4.24 0-4.64 4.35-4.64 11 0 6.3.35 11 4.46 11 2.1 0 3.54-1.26 3.77-5.6zm78.18-8.77h1.37c3.15 0 5.43-1.1 5.43-4.18s-2.1-3.66-5.3-3.66h-1.5v7.83zm-16 24V1.2h24.4c8.84 0 14 2.68 14 10.45 0 4.74-2 8.34-8.18 9.94 4 .33 6.17 2.1 7 5.24.9 3.54 1.3 8.08 2.07 15.6h-16c-1.5-9.25-.88-8.3-1.45-11.83-.35-2.05-1.37-2.85-4-2.85h-1.9v14.68zM58.53 25.72h7l-3.4-15.55-3.6 15.55zm-19.8 16.7L52 1.13h21.17l13.27 41.3H69.5l-1.6-6.64H56.08l-1.66 6.63z" })));
export const NavMenu = ({ userdata }) => {
    const { storeLocationId } = useParams();
    const navigate = useNavigate();
    const returnToCheckout = () => {
        navigate(`/checkout/${storeLocationId}`);
    };
    return (React.createElement("header", null,
        React.createElement(Navbar, { expand: "lg fixed-top" },
            React.createElement(Container, { fluid: true, className: "p-0" },
                React.createElement(Navbar.Brand, { className: "ms-3", onClick: returnToCheckout, style: { cursor: 'pointer' } },
                    React.createElement(CarmaxLogo, null),
                    React.createElement("hzn-text", { inline: true, size: "large", tone: "brand" }, "Checkout")),
                React.createElement(NavMenuBlock, { className: "ms-auto", label: userdata?.name, icon: React.createElement("hzn-icon-person", null) }),
                React.createElement(NavMenuBlock, { label: `#${storeLocationId}`, icon: React.createElement("hzn-icon-location", null) })))));
};
export const NavErrorBoundary = () => (React.createElement("div", { className: "p-4" },
    React.createElement("hzn-alert", { show: true, role: "alert", tone: "critical", "aria-label": "Something went wrong...", heading: "Something went wrong...", "sub-heading": "Please refresh the page and try again. If the problem persists please contact the Solution Center." })));
