import { useHandleBasketPaymentActionMutation } from '@src/api/CheckoutApi';
import { useSystemInteractionGetComputerNameQuery } from '@src/api/SystemInteractionApi';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
/**
 *  ProcessCashPayment submits the payment details to the API for processing.
 */
export const ProcessPayment = (props) => {
    const { storeLocationId, basketId } = useParams();
    const { payment, startTime, paymentDetails } = useAppSelector(selectPayment);
    const workstationName = useSystemInteractionGetComputerNameQuery('');
    const [processPayment, processPaymentStatus] = useHandleBasketPaymentActionMutation();
    const [isRetry, setIsRetry] = useState(false);
    const doProcessPayment = () => {
        processPayment({
            basketId,
            paymentId: payment.id,
            body: {
                paymentAction: 'PROCESS',
                storeLocationId,
                workstationName: workstationName.data,
                startTime,
                endTime: formatISO(Date.now()),
                paymentDetails,
            },
        })
            .unwrap()
            .then(() => props.onComplete())
            .catch(err => {
            setIsRetry(true);
            console.log('Error processing payment', err);
        });
    };
    useEffect(() => {
        if (!!workstationName.data) {
            doProcessPayment();
        }
    }, [workstationName]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal.Header, null,
            React.createElement("hzn-heading", { size: "xsmall" }, "Processing payment...")),
        React.createElement(Modal.Body, null,
            React.createElement(FlexColumn, null,
                props.children,
                !isRetry && processPaymentStatus.isLoading && (React.createElement(FlexRow, { className: "justify-content-center" },
                    React.createElement("hzn-loading", null))),
                React.createElement(HorizonAlert, { show: isRetry, heading: "Failed to process the payment.", tone: "caution", "sub-heading": "Try processing the payment again. If the issue is not resolved please notify the MOD." }))),
        isRetry && (React.createElement(Modal.Footer, null,
            React.createElement(FlexColumn, { className: "w-100" },
                React.createElement(HorizonButton, { variant: "primary", loading: processPaymentStatus.isLoading, onClick: () => doProcessPayment() }, "Try Again"),
                React.createElement(HorizonButton, { variant: "tertiary", onClick: props.onCancel }, "Cancel"))))));
};
