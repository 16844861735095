import { FlexColumn } from '@src/components/Flex';
import { CurrencyInput } from '@src/components/horizon/CurrencyInput';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CashPaymentDescription } from './CashPaymentDescription';
/**
 * ConfirmCashAmount asks the BOA to enter the amount of cash provided by the customer for a cash payment.
 * This will be used to calculate any necessary change.
 */
export const ConfirmCashAmount = (props) => {
    const { payment } = useAppSelector(selectPayment);
    const currencyRef = useRef();
    const [collected, setCollected] = useState(undefined);
    const onConfirm = () => {
        if (!currencyRef.current.validate())
            return;
        props.OnContinue(collected);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal.Header, null,
            React.createElement("hzn-heading", { size: "xsmall" }, "Confirm amount to be processed.")),
        React.createElement(Modal.Body, null,
            React.createElement(FlexColumn, null,
                React.createElement("hzn-text", null, "Confirm the cash amount collected from the customer."),
                React.createElement(CashPaymentDescription, null),
                React.createElement("hzn-divider", null),
                React.createElement(CurrencyInput, { className: "ps-2 pe-2", ref: currencyRef, label: "Amount collected from customer", required: true, min: payment.amount, onValueChanged: setCollected, errorMessages: {
                        valueMissing: 'A valid dollar amount is required',
                        rangeUnderflow: 'Cannot be less that the amount to be processed.',
                    } }))),
        React.createElement(Modal.Footer, null,
            React.createElement(FlexColumn, { className: "w-100" },
                React.createElement(HorizonButton, { variant: "primary", onClick: onConfirm }, "Confirm Amount"),
                React.createElement(HorizonButton, { variant: "tertiary", onClick: props.onCancel }, "Cancel")))));
};
