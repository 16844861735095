// class definition
import { html, LitElement, nothing, TemplateResult } from '@horizon/base';
import { property, query, state } from '@horizon/base/decorators';
import { classMap, ifDefined, live } from '@horizon/base/directives';
import { eventEmitter } from '@horizon/common/events';
import { maxLengthValidator, minLengthValidator, requiredValidator, customValidator } from '@horizon/common/mixins';
import { FormControlMixin } from '@open-wc/form-control';
import { ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import 'element-internals-polyfill';

import { HznInline } from '@horizon/inline';
import { HznStack } from '@horizon/stack';
import { HznText } from '@horizon/text';

import InputStyles from './textarea.css.js';
import { HznTextareaAutoCapitalize, HznTextareaEnterKeyHint, HznTextareaInputMode, HznTextareaResize, HznTextareaSpellcheck } from '../types.js';

const VALIDITY_PROPS = [
  'value',
  'required',
  'minlength',
  'maxlength'
]

/**
 *
 * @tag hzn-textarea
 * @tagname hzn-textarea
 * @summary An textarea input for entering larger sections of text
 *
 * @fires {HznTextareaInputEvent} input - Emitted every time a character is typed into the textarea
 * @fires {HznTextareaChangeEvent} change - Emitted when the texarea is changed
 */
export class HznTextarea extends FormControlMixin(ScopedElementsMixin(LitElement) as typeof LitElement) {
  /**
   * @private
   */
  #emit = eventEmitter(this);

  static styles = [InputStyles];

  static get scopedElements() {
    return {
      'hzn-stack': HznStack,
      'hzn-text': HznText,
      'hzn-inline': HznInline,
    };
  }

  static get formControlValidators() {
    return [
      requiredValidator,
      minLengthValidator,
      maxLengthValidator,
      customValidator
    ];
  }

  // INTERNAL ACCESS PROPERTIES
  /**
   * The internal native textarea element
   */
  @query('textarea') innerTextarea!: HTMLTextAreaElement;

  /**
   * @private
   */
  get characterCount() {
    return this.value ? this.value.length : 0;
  }

  /**
   * @private
   */
  @state() internalErrorMessage!: string;

  /**
   * @private
   * The native textarea inside the host element
   */
  @query('textarea') validationTarget!: HTMLTextAreaElement;

  /**
   * Sets the textarea name
   */
  @property() name?: string;

  /**
   * Whether or not the textarea is compact size
   */
  @property({ type: Boolean }) compact?: boolean = false;

  /**
   * Sets the textarea value
   */
  @property({ type: String, reflect: true }) value = '';

  /**
   * Set whether or not the textarea is required to have a value
   */
  @property({ type: Boolean }) required?: boolean = false;

  /**
   * Set the textarea to be disabled
   */
  @property({ type: Boolean, reflect: true }) disabled?: boolean = false;

  /**
   * Set the textarea to be read-only
   */
  @property({ type: Boolean, reflect: true }) readonly?: boolean = false;

  /**
   * Set the help text for the textarea
   */
  @property({ type: String, attribute: 'helper-text' }) helperText?: string = '';

  /**
   * Hides the help text for the textarea
   */
  @property({ type: Boolean, attribute: 'hide-helper-text' }) hideHelperText?: boolean = false;

  /**
   * Whether or not to show the character counter
   */
  @property({ type: Boolean, attribute: 'show-character-count' }) showCharacterCount?: boolean;

  /**
   * Set the resize for the textarea
   * @playroomValues {'none' | 'vertical' | 'content'}
   */
  @property({ type: String }) resize?: HznTextareaResize = 'vertical';

  /**
   * The number of rows shown in the textarea
   */
  @property({ type: Number }) rows?: number;

  /**
   * The minimum length of textarea that will be considered valid
   */
  @property({ type: Number }) minlength?: number;

  /**
   * The maximum length of textarea that will be considered valid
   */
  @property({ type: Number }) maxlength?: number;

  /**
   * Whether or not to visually hide the textarea's label. A label should always be present even when hidden
   */
  @property({ type: Boolean, attribute: 'hide-label' }) hideLabel?: boolean = false;

  /**
   * The textarea's placeholder text
   */
  @property() placeholder?: string;

  /**
   * The textarea's autocorrect attribute
   */
  @property() autocorrect?: string;

  /**
   * The textarea's autocomplete attribute
   */
  @property() autocomplete?: string;

  /**
   * The textarea's autocapitalize attribute
   * @playroomValues {'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters'}
   */
  @property() autocapitalize!: HznTextareaAutoCapitalize;

  /**
   * The textarea's autofocus attribute
   */
  @property({ type: Boolean }) autofocus!: boolean;

  /**
   * The textarea's enterkeyhint attribute. This can be used to customize the label or icon of the Enter key on virtual
   * keyboards.
   * @playroomValues {'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send'}
   */
  @property() enterkeyhint?: HznTextareaEnterKeyHint;

  /**
   * Enables spell checking on the textarea
   * @playroomValues {'true' | 'false'}
   */
  @property({ type: String, attribute: 'spellcheck' }) spellCheck?: HznTextareaSpellcheck;

  /**
   * The textarea's inputmode attribute
   * @playroomValues {'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url'}
   */
  @property() inputmode?: HznTextareaInputMode;

  /**
   * Set a display name on the textarea - used in error message customization
   */
  @property({ type: String, attribute: 'display-name' }) displayName?: string;

  /**
   * Overwrite standard scenario error messages with a custom one
   */
  @property({ attribute: false }) errorMessages?: Partial<Record<keyof ValidityState, string | ((instance: HznTextarea) => string)>>;

  /**
   * Set an error state and message on the textarea
   */
  @property({ type: String, attribute: false }) validation?: (instance: HznTextarea, value: string) => { valid: boolean; message: string };

  constructor() {
    super();
    this.addEventListener('invalid', this.#onInvalid);

  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.removeEventListener('invalid', this.#onInvalid);
  }

  /**
   * @private
   */
  resetFormControl(): void {
    this.value = '';
  }

  /**
   * @private
   */
  #onInvalid = (event: Event): void => {
    event.preventDefault();
    this.validationTarget?.focus();
  };

  /**
   * @private
   */
  get showErrors() {
    return this.showError && !this.disabled && !this.readonly;
  }

  /**
   * @private
   */
  validationMessageCallback(message: string): void {
    this.internalErrorMessage = message;
  }

  /**
   * @private
   */
  protected updated(changed: Map<string, unknown>): void {
    // recalculate the validity when any props relating to validity are changed
    if (VALIDITY_PROPS.some((prop) => changed.has(prop))) {
      this.setValue(this.value);
    }
  }

  /** Sets focus on the input. */
  focus(options?: FocusOptions) {
    this.validationTarget.focus(options);
  }

  /** Removes focus from the input. */
  blur() {
    this.validationTarget.blur();
  }

  #setTextareaHeight() {
    if (this.resize === 'content') {
      this.validationTarget.style.height = 'auto';
      this.validationTarget.style.height = `${this.validationTarget.scrollHeight}px`;
    } else {
      (this.validationTarget.style.height as string | undefined) = undefined;
    }
  }

  /**
   * @private
   */
  #handleInput(event: Event) {
    this.value = (event.target as HTMLTextAreaElement).value;
    this.#setTextareaHeight();
  }

  /**
   * @private
   */
  #handleChange(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.#emit({ type: 'change' });
  }

  /**
   * @private
   */
  #renderCharacterCounter() {
    if (this.showCharacterCount && !!this.maxlength && this.helperText) {
      return html`<hzn-inline>
        <hzn-text class="${'flex-grow' + (this.hideHelperText ? ' visually-hidden' : '')}" id="helper-text" tone="subdued" variant="caption">
          ${this.helperText}
        </hzn-text>
        <hzn-text class="textarea-character-count" id="character-count" tone="subdued" variant="caption">
          <span aria-hidden="true">${this.characterCount}/${this.maxlength}</span>
          <span class="visually-hidden">${this.maxlength - this.characterCount} characters remaining.</span>
        </hzn-text>
      </hzn-inline>`;
    }
    else if (this.showCharacterCount && !!this.maxlength && !this.helperText) {
      return html`<hzn-inline>
        <hzn-text class="textarea-character-count" id="character-count" tone="subdued" variant="caption">
          <span aria-hidden="true">${this.characterCount}/${this.maxlength}</span>
          <span class="visually-hidden">${this.maxlength - this.characterCount} characters remaining.</span>
        </hzn-text>
      </hzn-inline>`;
    }
    else if (this.showCharacterCount && !this.maxlength && !this.helperText) {
      return html`<hzn-inline>
        <hzn-text class="textarea-character-count" id="character-count" tone="subdued" variant="caption">
          <span aria-hidden="true">${this.characterCount}</span>
          <span class="visually-hidden">${this.characterCount} characters.</span>
        </hzn-text>
      </hzn-inline>`;
    }
    else if (this.showCharacterCount && !this.maxlength && this.helperText) {
      return html`<hzn-inline>
        <hzn-text class="${this.hideHelperText ? 'visually-hidden' : ''}" id="helper-text" tone="subdued" variant="caption">
          ${this.helperText}
        </hzn-text>
        <hzn-text class="textarea-character-count" id="character-count" tone="subdued" variant="caption">
          <span aria-hidden="true">${this.characterCount}</span>
          <span class="visually-hidden">${this.characterCount} characters.</span>
        </hzn-text>
      </hzn-inline>`;
    }
    else if (!this.showCharacterCount && this.helperText) {
      return html`<hzn-text class="${this.hideHelperText ? 'visually-hidden' : ''}" id="helper-text" tone="subdued" variant="caption">
        ${this.helperText}
      </hzn-text>`;
    }
    return nothing;
  }

  render(): TemplateResult {

    return html`<hzn-stack space="xsmall">
      <label id="label" for="input" class="${this.hideLabel ? 'visually-hidden' : ''}">
        <hzn-text variant="text">
        <slot>${this.displayName || this.name || 'Textarea'}</slot>
        </hzn-text>
      </label>

      <textarea
        class="${classMap({
      'textarea-container': true,
      'is-compact': Boolean(this.compact),
      'is-disabled': Boolean(this.disabled),
      'is-error': this.showErrors,
      'is-readonly': Boolean(this.readonly),
      'is-resize-content': this.resize === 'content',
      'is-resize-none': this.resize === 'none',
      'is-resize-vertical': this.resize === 'vertical',
    })}"
        title=""
        id="input"
        name=${ifDefined(this.name)}
        .value=${live(this.value)}
        ?disabled=${this.disabled}
        ?readonly=${this.readonly}
        ?required=${this.required}
        placeholder=${ifDefined(this.placeholder)}
        rows=${ifDefined(this.rows)}
        minlength=${ifDefined(this.minlength)}
        maxlength=${ifDefined(this.maxlength)}
        autocomplete=${ifDefined(this.autocomplete)}
        autocapitalize=${ifDefined(this.autocapitalize)}
        autocorrect=${ifDefined(this.autocorrect)}
        ?autofocus=${this.autofocus}
        spellcheck=${ifDefined(this.spellcheck ? this.spellCheck : undefined)}
        enterkeyhint=${ifDefined(this.enterkeyhint)}
        inputmode=${ifDefined(this.inputmode)}
        aria-labelledby="${this.helperText ? 'label helper-text' : ''}"
        aria-describedby="${this.showCharacterCount && !!this.maxlength ? 'character-count' : ''}"
        @input=${this.#handleInput}
        @change=${this.#handleChange}
      ></textarea>

      ${this.#renderCharacterCounter()}
      ${this.showErrors
        ? html`<hzn-text role="alert" id="error-message" variant="caption" tone="critical"
            >${this.internalErrorMessage}</hzn-text
          >`
        : nothing}
    </hzn-stack>`;
  }
}
