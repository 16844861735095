import { LitElement, TemplateResult } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { unsafeStatic, html } from '@horizon/base/static-html.js';

import InlineStyles from './inline.css.js';
import {
  HznInlineAlignX,
  HznInlineAlignY,
  HznInlineAs,
  HznInlineCollapseBelow,
  HznInlineSpace,
  inlineTags
} from '../types.js';
import { classMap } from '@horizon/base/directives';


/**
 *
 * @tag hzn-inline
 * @tagname hzn-inline
 * @summary A layout component for establishing a horizontal row of items
 */
export class HznInline extends LitElement {
  static styles = [InlineStyles];

  /**
   * Set the gap between inline items. Accepts a single value, or two values separated by a space. When two values are provided the order is space="horizontal vertical", ie space="large small" sets the column-gap to 'small' and the row-gap to large
   * @playroomValues {'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'}
   */
  @property({ type: String }) space?: HznInlineSpace = 'base';

  /**
   * Set the HTML element the inline element will render as (div, ol, ul)
   * @playroomValues {'ul' | 'ol' | 'div'}
   */
  @property({ type: String }) as?: HznInlineAs = 'div';

  /**
   * Set the responsive breakpoint below which all inlined elements will collapse into a single column
   * @playroomValues {'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'}
   */
  @property({ type: String, attribute: 'collapse-below' }) collapseBelow?: HznInlineCollapseBelow;

  /**
   * Set the vertical alignment of the child elements relative to each other in their flex row
   * @playroomValues {'stretch' | 'start' | 'end' | 'center' | 'baseline'}
   */
  @property({ type: String, attribute: 'align-y' }) alignY?: HznInlineAlignY = 'center';

  /**
   * Set the horizontal alignment of the child elements relative to the hzn-inline container
   * @playroomValues {'stretch' | 'start' | 'end' | 'center' | 'between' | 'evenly' | 'around'}
   */
  @property({ type: String, attribute: 'align-x' }) alignX?: HznInlineAlignX = 'start';

  /**
   * Reverse the order of inline child elements
   */
  @property({ type: Boolean }) reverse?: boolean = false;

  /**
   * Set inlined child elements to wrap to the next row
   */
  @property({ type: Boolean, attribute: 'wrap' }) wrap?: boolean = false;

  #computeSpacing() {
    const spacing = {
      row: 'base',
      column: 'base'
    };

    if (this.space) {
      const spaceSplit = this.space.split(' ');
      if (spaceSplit.length > 1) {
        // if splitting yields two values, the first is column (x), second is row (y)
        spacing.column = spaceSplit[0];
        spacing.row = spaceSplit[1];
      } else {
        // if the space prop is just a single value, use that value for both column and row
        spacing.column = spacing.row = spaceSplit[0];
      }
    }
    // if no space property is set, the return is the default spacing
    return spacing;
  }

  render(): TemplateResult {
    const { column, row} = this.#computeSpacing();

    return html`<${unsafeStatic(this.as && inlineTags.includes(this.as) ? this.as : 'div')}
      class=${classMap({
        inline: true,
        'is-reversed': Boolean(this.reverse),
        'is-wrapped': Boolean(this.wrap),
        'is-column-gap-none': column === 'none',
        'is-column-gap-xxsmall': column === 'xxsmall',
        'is-column-gap-xsmall': column === 'xsmall',
        'is-column-gap-small': column === 'small',
        'is-column-gap-base': column === 'base',
        'is-column-gap-medium': column === 'medium',
        'is-column-gap-large': column === 'large',
        'is-column-gap-xlarge': column === 'xlarge',
        'is-column-gap-xxlarge': column === 'xxlarge',
        'is-column-gap-xxxlarge': column === 'xxxlarge',
        'is-row-gap-none': row === 'none',
        'is-row-gap-xxsmall': row === 'xxsmall',
        'is-row-gap-xsmall': row === 'xsmall',
        'is-row-gap-small': row === 'small',
        'is-row-gap-base': row === 'base',
        'is-row-gap-medium': row === 'medium',
        'is-row-gap-large': row === 'large',
        'is-row-gap-xlarge': row === 'xlarge',
        'is-row-gap-xxlarge': row === 'xxlarge',
        'is-row-gap-xxxlarge': row === 'xxxlarge',
        'is-collapse-below-small': this.collapseBelow === 'small',
        'is-collapse-below-medium': this.collapseBelow === 'medium',
        'is-collapse-below-large': this.collapseBelow === 'large',
        'is-collapse-below-xlarge': this.collapseBelow === 'xlarge',
        'is-collapse-below-xxlarge': this.collapseBelow === 'xxlarge',
        'is-align-x-stretch': this.alignX === 'stretch',
        'is-align-x-start': this.alignX === 'start',
        'is-align-x-center': this.alignX === 'center',
        'is-align-x-end': this.alignX === 'end',
        'is-align-x-between': this.alignX === 'between',
        'is-align-x-around': this.alignX === 'around',
        'is-align-x-evenly': this.alignX === 'evenly',
        'is-align-y-stretch': this.alignY === 'stretch',
        'is-align-y-start': this.alignY === 'start',
        'is-align-y-center': this.alignY === 'center',
        'is-align-y-end': this.alignY === 'end',
        'is-align-y-baseline': this.alignY === 'baseline'

      })}>
      <slot></slot>
    </${unsafeStatic(this.as && inlineTags.includes(this.as) ? this.as : 'div')}>`;
  }
}
