import { FlexRow } from '@src/components/Flex';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';
export const CashPaymentDescription = () => {
    const { payment, paymentDetails } = useAppSelector(selectPayment);
    const cashPaymentDetails = paymentDetails;
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexRow, { className: 'justify-content-between pt-3 ps-2 pe-2' },
            React.createElement("hzn-text", { tone: "subdued" }, "Payment method"),
            React.createElement("hzn-text", null, "Cash")),
        React.createElement("hzn-divider", null),
        !cashPaymentDetails && (React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
            React.createElement("hzn-text", { tone: "subdued" }, "Amount"),
            React.createElement("hzn-text", null, AsCurrency(payment.amount)))),
        cashPaymentDetails && (React.createElement(React.Fragment, null,
            React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
                React.createElement("hzn-text", { tone: "subdued" }, "Amount collected from customer"),
                React.createElement("hzn-text", null, AsCurrency(cashPaymentDetails.cashCollected))),
            React.createElement("hzn-divider", null),
            React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
                React.createElement("hzn-text", { tone: "subdued" }, "Amount applied towards balance"),
                React.createElement("hzn-text", null, AsCurrency(payment.amount))),
            React.createElement("hzn-divider", null),
            React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
                React.createElement("hzn-text", { tone: "subdued" }, "Change due"),
                React.createElement("hzn-text", { weight: "bold" }, AsCurrency(cashPaymentDetails.cashCollected - payment.amount)))))));
};
