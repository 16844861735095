import React from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import { HznBool } from '../../utils/Horizon';
export const HorizonButton = ({ className, disabled, loading, children, onClick, ...props }) => {
    const ref = React.useRef(undefined);
    useEventListener(ref, 'click', onClick);
    return (
    // @ts-ignore
    React.createElement("hzn-button", { ref: ref, class: className, loading: HznBool(loading), disabled: HznBool(disabled), ...props }, children));
};
