import React from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import CheckoutStepper from './CheckoutStepper';
import { CartDetails } from '@src/components/CartDetails';
import { Suspense } from '@src/components/Suspense';
import { Payment } from './Payment/Payment';
import { useGetBasketQuery } from '@src/api/CheckoutApi';
import { TenderOrder } from '../TenderOrder/TenderOrder';
export const BasketCheckout = ({ step }) => {
    const { basketId } = useParams();
    const basket = useGetBasketQuery({ basketId });
    return (React.createElement(Suspense, { suspended: basket.isLoading }, basket.data && (React.createElement("hzn-stack", { space: "large" },
        React.createElement(Header, { basketDetails: basket.data }),
        React.createElement("hzn-columns", { space: "large", "collapse-below": "xlarge" },
            React.createElement("hzn-column", { width: "1/3" },
                React.createElement("hzn-stack", null,
                    React.createElement(CartDetails, { basket: basket.data }),
                    React.createElement(CheckoutStepper, { basketId: basketId }))),
            React.createElement("hzn-column", { width: "2/3" },
                step === 'PAYMENT' &&
                    React.createElement(Payment, { basket: basket.data }),
                step === 'TENDER_ORDER' &&
                    React.createElement(TenderOrder, { basketId: basketId })))))));
};
