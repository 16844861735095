import React from 'react';
import { useLocation } from 'react-router-dom';
export const GenericNotFound = (props) => {
    const location = useLocation();
    return (React.createElement("div", { style: {
            background: 'var(--hzn-color-background)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            minHeight: '100vh',
        } },
        React.createElement("hzn-card", { padding: "large", "border-radius": "base", "rounded-above": "medium", elevation: "medium", style: {
                width: '90%',
                marginTop: '24px'
            } },
            React.createElement("hzn-stack", { space: "medium" },
                React.createElement("hzn-heading", null, "Not Found"),
                React.createElement("hzn-text", { inline: true },
                    React.createElement("i", null, "Oops!"),
                    " The content you were looking for could not be found."),
                React.createElement("hzn-button", { variant: "primary", href: "/checkout/baskets" }, "Return to Dashboard"),
                React.createElement("hzn-divider", null),
                React.createElement("hzn-text", { inline: true, variant: "eyebrow" }, "Technical Details"),
                React.createElement("hzn-text", { variant: "caption" },
                    "HTTP 404. The provided route, ",
                    location.pathname,
                    ", was invalid.")))));
};
