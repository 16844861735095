import { LitElement, TemplateResult } from '@horizon/base';
import { unsafeStatic, html } from '@horizon/base/static-html.js';
import { property } from '@horizon/base/decorators.js';
import { classMap } from '@horizon/base/directives';

import cardStyles from './card.css.js';
import {
  AllowedCardElements,
  HznCardBorderRadius,
  HznCardElevation,
  HznCardPadding,
  HznCardRoundedAbove,
  HznCardTone,
  HznCardAs
} from '../types.js';

/**
 *
 * @tag hzn-card
 * @tagname hzn-card
 * @summary A component for creating cards
 */
export class HznCard extends LitElement {
  static styles = [cardStyles];

  /**
   * Sets the background color of card
   * @playroomValues {'neutral' | 'brand' | 'brand-accent'}
   */
  @property({ type: String }) tone: HznCardTone = 'neutral';

  /**
   * Sets the border radius of card
   * @playroomValues {'none' | 'small' | 'base' | 'medium' | 'large'}
   */
  @property({ type: String, attribute: 'border-radius' }) borderRadius?: HznCardBorderRadius = 'none';

  /**
   * Sets the box shadow/elevation of card
   * @playroomValues {'none' | 'flat' | 'small' | 'medium' | 'large' | 'xlarge'}
   */
  @property({ type: String }) elevation?: HznCardElevation = 'none';

  /**
   * Sets the padding of card
   * @playroomValues {'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'}
   */
  @property({ type: String }) padding?: HznCardPadding = 'base';

  /**
   * Sets the html tag used for card
   * @playroomValues {'div' | 'article' | 'details' | 'main' | 'section' | 'aside'}
   */
  @property({ type: String }) as?: HznCardAs = 'div';

  /**
   * Sets the breakpoint for border-radius. If border radius is set, it will only be applied above the specified breakpoint
   * @playroomValues {'small' | 'medium' | 'large' | 'xlarge'}
   */
  @property({ type: String, attribute: 'rounded-above' }) roundedAbove?: HznCardRoundedAbove;

  render(): TemplateResult {
    return html`<${unsafeStatic(this.as && AllowedCardElements.includes(this.as) ? this.as : 'div')}
    class=${classMap({
      'card': true,
      'is-tone-neutral': this.tone === 'neutral',
      'is-tone-brand': this.tone === 'brand',
      'is-tone-brand-accent': this.tone === 'brand-accent',
      'is-border-none': this.borderRadius === 'none',
      'is-border-small': this.borderRadius === 'small',
      'is-border-base': this.borderRadius === 'base',
      'is-border-medium': this.borderRadius === 'medium',
      'is-border-large': this.borderRadius === 'large',
      'is-elevation-none': this.elevation === 'none',
      'is-elevation-flat': this.elevation === 'flat',
      'is-elevation-small': this.elevation === 'small',
      'is-elevation-medium': this.elevation === 'medium',
      'is-elevation-large': this.elevation === 'large',
      'is-elevation-xlarge': this.elevation === 'xlarge',
      'is-padding-none': this.padding === 'none',
      'is-padding-xxsmall': this.padding === 'xxsmall',
      'is-padding-xsmall': this.padding === 'xsmall',
      'is-padding-small': this.padding === 'small',
      'is-padding-base': this.padding === 'base',
      'is-padding-medium': this.padding === 'medium',
      'is-padding-large': this.padding === 'large',
      'is-padding-xlarge': this.padding === 'xlarge',
      'is-padding-xxlarge': this.padding === 'xxlarge',
      'is-padding-xxxlarge': this.padding === 'xxxlarge',
      'is-rounded-above-small': this.roundedAbove === 'small',
      'is-rounded-above-medium': this.roundedAbove === 'medium',
      'is-rounded-above-large': this.roundedAbove === 'large',
      'is-rounded-above-xlarge': this.roundedAbove === 'xlarge'
    })}>
      <slot></slot>
    </${unsafeStatic(this.as && AllowedCardElements.includes(this.as) ? this.as : 'div')}>`;
  }
}
