import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { removeToast, selectToasts } from './ToastSlice';
// import './Toast.scss';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
const AutoHidingToast = ({ toast }) => {
    const dispatch = useAppDispatch();
    const [show, setShow] = useState(true);
    const onClose = () => {
        // hide the toast, allowing it to fade out
        setShow(false);
        // after 500ms remove the toast from the list.
        return new Promise(resolve => setTimeout(resolve, 500)).then(() => dispatch(removeToast(toast.id)));
    };
    return (React.createElement(Toast, { key: toast.id, show: show, autohide: true, delay: 5000, onClose: onClose, className: "mb-2" },
        React.createElement(Toast.Body, { className: "p-0" },
            React.createElement("hzn-card", { elevation: "small", "border-radius": "medium", padding: "none" },
                React.createElement("hzn-alert", { role: 'status', "aria-label": toast.message, show: true, tone: toast.tone, heading: toast.message })))));
};
export const ToastStack = () => {
    const toasts = useAppSelector(selectToasts);
    return (React.createElement(ToastContainer, { position: "top-end", className: "position-fixed mt-5 me-1" }, toasts.map(toast => (React.createElement(AutoHidingToast, { key: toast.id, toast: toast })))));
};
