// class definition
import { LitElement, TemplateResult, html } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { classMap } from '@horizon/base/directives';

import StackStyles from './stack.css.js';
import { HznStackAlignX, HznStackAlignY, HznStackSpace } from '../types.js';
/**
 *
 * @tag hzn-stack
 * @tagname hzn-stack
 * @summary A layout component for vertically spacing child elements
 */
export class HznStack extends LitElement {
  static styles = [StackStyles];

  /**
   * Set the flexbox gap between child elements
   * @playroomValues {'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'}
   */
  @property({ type: String }) space?: HznStackSpace = 'base';

  /**
   * Set the flexbox align-items along the x axis
   * @playroomValues {'stretch' | 'start' | 'end' | 'center'}
   */
  @property({ type: String, attribute: 'align-x' }) alignX?: HznStackAlignX = 'start';

  /**
   * Set the flexbox justify-content along the y axis
   * @playroomValues {'stretch' | 'center' | 'start' | 'end' | 'between' | 'evenly' | 'around'}
   */
  @property({ type: String, attribute: 'align-y' }) alignY?: HznStackAlignY = 'start';

  render(): TemplateResult {
    return html`<slot class=${classMap({
      stack: true,
      'is-gap-none': this.space === 'none',
      'is-gap-xxsmall': this.space === 'xxsmall',
      'is-gap-xsmall': this.space === 'xsmall',
      'is-gap-small': this.space === 'small',
      'is-gap-base': this.space === 'base',
      'is-gap-medium': this.space === 'medium',
      'is-gap-large': this.space === 'large',
      'is-gap-xlarge': this.space === 'xlarge',
      'is-gap-xxlarge': this.space === 'xxlarge',
      'is-gap-xxxlarge': this.space === 'xxxlarge',
      'is-align-x-stretch': this.alignX === 'stretch',
      'is-align-x-start': this.alignX === 'start',
      'is-align-x-center': this.alignX === 'center',
      'is-align-x-end': this.alignX === 'end',
      'is-align-y-stretch': this.alignY === 'stretch',
      'is-align-y-start': this.alignY === 'start',
      'is-align-y-center': this.alignY === 'center',
      'is-align-y-end': this.alignY === 'end',
      'is-align-y-between': this.alignY === 'between',
      'is-align-y-evenly': this.alignY === 'evenly',
      'is-align-y-around': this.alignY === 'around',
    })}></slot>`;
  }
}
