
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-search
 * @tagname hzn-icon-search
 * @summary An individual icon for Horizon
 */
export class HznIconSearch extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M20.5 19.5L16.78 15.78C17.98 14.35 18.71 12.52 18.71 10.51C18.71 5.99 15.03 2.31 10.51 2.31C5.99 2.31 2.31 5.99 2.31 10.51C2.31 15.03 5.99 18.71 10.51 18.71C12.52 18.71 14.35 17.98 15.78 16.78L19.5 20.5C19.64 20.64 19.82 20.71 20 20.71C20.18 20.71 20.36 20.64 20.5 20.5C20.78 20.22 20.78 19.77 20.5 19.5ZM3.73 10.51C3.73 6.77 6.77 3.73 10.51 3.73C14.25 3.73 17.29 6.77 17.29 10.51C17.29 14.25 14.25 17.29 10.51 17.29C6.77 17.29 3.73 14.25 3.73 10.51Z" fill="currentColor" class="stroked"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.7801 15.7801L20.5001 19.5001C20.7801 19.7701 20.7801 20.2201 20.5001 20.5001C20.3601 20.6401 20.1801 20.7101 20.0001 20.7101C19.8201 20.7101 19.6401 20.6401 19.5001 20.5001L15.7801 16.7801C14.3501 17.9801 12.5201 18.7101 10.5101 18.7101C5.99006 18.7101 2.31006 15.0301 2.31006 10.5101C2.31006 5.99006 5.99006 2.31006 10.5101 2.31006C15.0301 2.31006 18.7101 5.99006 18.7101 10.5101C18.7101 12.5201 17.9801 14.3501 16.7801 15.7801ZM10.5101 3.73006C6.77006 3.73006 3.73006 6.77006 3.73006 10.5101C3.73006 14.2501 6.77006 17.2901 10.5101 17.2901C14.2501 17.2901 17.2901 14.2501 17.2901 10.5101C17.2901 6.77006 14.2501 3.73006 10.5101 3.73006ZM15.01 10.51C15.01 12.9953 12.9953 15.01 10.51 15.01C8.02473 15.01 6.01001 12.9953 6.01001 10.51C6.01001 8.02473 8.02473 6.01001 10.51 6.01001C12.9953 6.01001 15.01 8.02473 15.01 10.51Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
