import React, { useState } from 'react';
import { useParams, Outlet } from 'react-router-dom';
export const StoreOutlet = () => {
    const { storeLocationId } = useParams();
    const [validStoreLocationFormat] = useState(!isNaN(parseInt(storeLocationId)));
    // If the store location doesn't have a valid integer format, show an alert.
    // Otherwise, show nested app content.
    return (React.createElement(React.Fragment, null,
        !validStoreLocationFormat && (React.createElement("hzn-alert", { show: true, tone: "critical", heading: "Unrecognized Store ID.", "sub-heading": "Your browser location does not appear to contain a valid Store ID. Please press the Back button and retry your operation. If the problem persists please contact the Solution Center." })),
        validStoreLocationFormat && React.createElement(Outlet, null)));
};
