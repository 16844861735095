import { createColumnHelper } from '@tanstack/react-table';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { AsCurrency, PaymentFilterNames } from '../../utils/Formatting';
import './BasketSearchResults.scss';
import { selectBasketSearchQuery, updateSorting } from './BasketSearchSlice';
import { DataTable } from '../../components/DataTable/DataTable';
import { FlexRow } from '@src/components/Flex';
import '@horizon/icons/individual/hzn-external-link';
const columnHelper = createColumnHelper();
export const paymentStateBadge = (summary) => {
    switch (summary.status) {
        case 'COMPLETED':
            return React.createElement("hzn-badge", { label: PaymentFilterNames.Completed, tone: "positive" });
        case 'ABANDONED':
            return React.createElement("hzn-badge", { label: PaymentFilterNames.Abandoned, tone: "critical" });
        case 'ACTIVE':
            switch (summary.paymentState) {
                case 'NEW':
                    return React.createElement("hzn-badge", { label: PaymentFilterNames.ReadyToCheckout, tone: "info", intense: true });
                case 'PAID':
                case 'PARTIALLY_PAID':
                    return React.createElement("hzn-badge", { label: PaymentFilterNames.InProgress, tone: "info" });
                case 'ERROR':
                    return React.createElement("hzn-badge", { label: PaymentFilterNames.Error, tone: "critical", intense: true });
            }
    }
};
export const highlight = (str, term) => {
    if (!term)
        return str;
    const matchIndex = str.toLowerCase().indexOf(term.toLowerCase());
    if (matchIndex == -1)
        return str;
    return (React.createElement(React.Fragment, null,
        str.slice(0, matchIndex),
        React.createElement("span", { className: "fw-bold highlight" }, str.slice(matchIndex, matchIndex + term.length)),
        str.slice(matchIndex + term.length, str.length)));
};
const columns = (query, launchPosClient) => [
    columnHelper.accessor('createdOnUtc', {
        header: () => (React.createElement("hzn-text", { align: "left", weight: "bold", as: "div" }, "Cart created on")),
        cell: info => (React.createElement("hzn-text", { title: format(parseISO(info.getValue()), 'P p') }, format(parseISO(info.getValue()), 'P'))),
        enableSorting: true,
    }),
    columnHelper.accessor('customerName', {
        header: () => (React.createElement("hzn-text", { align: "left", weight: "bold", as: "div" }, "Customer name")),
        cell: info => (React.createElement(FlexRow, { className: "gap-1 align-items-baseline" },
            React.createElement("hzn-text", null, highlight(info.getValue(), query)),
            launchPosClient && (React.createElement("div", { className: "external-link-container", title: "This cart will open in a new page." },
                React.createElement("hzn-icon-external-link", { size: "small", class: "external-link" }))))),
        enableSorting: true,
    }),
    columnHelper.display({
        id: 'cartType',
        header: () => (React.createElement("hzn-text", { align: "left", weight: "bold", as: "div" }, "Items")),
        cell: () => React.createElement("hzn-text", null, "Appraisal Purchase "),
        enableSorting: false,
    }),
    columnHelper.accessor('decoratorsSummary', {
        header: '',
        cell: info => {
            const decorator = info.getValue().filter(s => s.type === 'VIN');
            const vin = !decorator.length || !decorator[0].values?.length ? "" : decorator[0].values[0];
            return React.createElement("div", { className: "monospace small" }, highlight(vin, query));
        },
        enableSorting: false,
    }),
    columnHelper.accessor('equity', {
        id: 'Total Due',
        header: () => (React.createElement("hzn-text", { align: "right", weight: "bold", as: "div" }, "Total due")),
        cell: info => (React.createElement("hzn-text", { align: "right", as: "div" }, AsCurrency(-1 * info.getValue()))),
        enableSorting: false,
    }),
    columnHelper.accessor(summary => summary, {
        id: 'Cart Status',
        header: () => (React.createElement("hzn-text", { align: "center", weight: "bold", as: "div" }, "Cart status")),
        cell: info => React.createElement("div", { className: "pill d-flex justify-content-center" }, paymentStateBadge(info.getValue())),
        enableSorting: false,
    }),
];
// For POS Client launches (in BusOpsNow, etc) resolve a gateway URL to match the current environment.
// This URL will be opened in a new tab and will initiate the POS client launch.
const resolveGatewayUrl = (basket) => {
    var gatewayEnv = window.location.host.toLowerCase().includes('-qa') ? 'qa' : 'prod';
    return `https://commerce-checkout-web-${gatewayEnv}-east-site.azurewebsites.net/gateway/${basket.sellingLocationId}/${basket.id}`;
};
export const BasketSearchResults = ({ query, loading, results, launchPosClient }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { sort: sorting } = useAppSelector(selectBasketSearchQuery);
    const OnRowClick = (basket) => {
        // If a POS client launch is requested, navigate to the /gateway route to open this basket in the POS app.
        // Otherwise just navigate to the basket details page.
        if (launchPosClient) {
            const gatewayUrl = resolveGatewayUrl(basket);
            console.log('Point of Sale client launch requested', gatewayUrl);
            window.open(gatewayUrl, '_blank').focus();
        }
        else {
            navigate(basket.id);
        }
    };
    return (React.createElement("div", { className: "basket-search" },
        React.createElement(DataTable, { label: "Cart search results", columns: columns(query, launchPosClient), data: results?.results ?? [], loading: loading, onRowClick: OnRowClick, onSortChange: newSort => dispatch(updateSorting(newSort)), sort: sorting })));
};
