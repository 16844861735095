// class definition
import { html, LitElement, nothing, TemplateResult } from '@horizon/base';
import { property, query, queryAll, state } from '@horizon/base/decorators.js';
import { classMap, ifDefined } from '@horizon/base/directives.js';
import { HasSlotController } from '@horizon/common/controllers';
import { eventEmitter } from '@horizon/common/events';
import { FormControlMixin } from '@open-wc/form-control';
import { ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import { submit } from '@open-wc/form-helpers';
import { HznInline } from '@horizon/inline';
import { HznStack } from '@horizon/stack';
import { HznText } from '@horizon/text';

import { HznRadio } from '../radio/radio.js';
import RadioGroupStyles from './radio-group.css.js';

import 'element-internals-polyfill';

/**
 *
 * @tag hzn-radio-group
 * @tagname hzn-radio-group
 * @summary Define a radio button group element in a form
 *
 * @fires {HznRadioGroupClickEvent} click - Emitted every time a radio button in the group is clicked
 * @fires {HznRadioGroupInputEvent} input - Emitted every time a radio button in the group is selected
 * @fires {HznRadioGroupChangeEvent} change - Emitted when the radio group value is changed
 */

export class HznRadioGroup extends FormControlMixin(ScopedElementsMixin(LitElement) as typeof LitElement) {
  /**
   * @private
   */
  #emit = eventEmitter(this);

  /**
   * @private
   */
  get #showGroupHeading() {
    if (this.hideLegend && !this.internalErrorMessage) {
      if (!this.helperText) {
        return true;
      }
      if (this.hideHelperText) {
        return true;
      }
    }
    return false;
  }

  static get scopedElements() {
    return {
      'hzn-inline': HznInline,
      'hzn-stack': HznStack,
      'hzn-text': HznText,
    };
  }

  static styles = [RadioGroupStyles];

  static formControlValidators = [
    {
      key: 'valueMissing',
      message(instance: HznRadioGroup) {
        if (instance.errorMessage) {
          return instance.errorMessage;
        }
        return `${instance.displayName ? instance.displayName : 'This field'} is required`;
      },
      isValid(instance: HznRadioGroup, value: string) {
        if ((instance.hasAttribute('required') || instance.required) && !value) {
          return false;
        }
        return true;
      },
    },
  ];

  // automated requestUpdate() on slotchange event
  private readonly hasSlotController = new HasSlotController(this, '[default]');

  /**
   * @private
   */
  @state() radios: HznRadio[] = [];

  /**
   * @private
   */
  @state() internalErrorMessage!: string;

  /**
   * @private
   */
  @query('input[type="radio"]') validationTarget!: HTMLInputElement;

  /**
   * The collection of internal radio buttons
   */
  @queryAll('input[type="radio"]') innerRadioInputs!: NodeListOf<HTMLInputElement>;

  /**
   * Sets the input name
   */
  @property() name?: string;

  /**
   * Set a display name on the input - used in error message customization
   */
  @property({ type: String, attribute: 'display-name' }) displayName?: string;

  /**
   * Sets the input value
   */
  @property({ type: String }) value = '';

  /**
   * Set whether or not the input is required to have a value
   */
  @property({ type: Boolean }) required?: boolean = false;

  /**
   * Set the input to be disabled
   */
  @property({ type: Boolean }) disabled?: boolean = false;

  /**
   * Set the help text for the input
   */
  @property({ type: String, attribute: 'helper-text' }) helperText?: string = '';

  /**
   * Hides the help text for the radio-group
   */
  @property({ type: Boolean, attribute: 'hide-helper-text' }) hideHelperText?: boolean = false;

  /**
   * Set an error state and message on the input
   */
  @property({ type: String, attribute: 'error-message' }) errorMessage?: string;

  /**
   * Set the fieldset legend text content for the radio group
   */
  @property({ type: String }) legend = '';

  /**
   * Set the legend to be visually hidden (still present for screen readers)
   */
  @property({ type: Boolean, attribute: 'hide-legend' }) hideLegend?: boolean = false;

  /**
   * Set the radio group to display horizontally. Best used for less than 3 options.
   */
  @property({ type: Boolean }) horizontal?: boolean = false;

  /**
   * The input's autofocus attribute
   */
  @property({ type: Boolean }) autofocus = false;

  // set a reactive property so that error messages will show when applicable
  /**
   * @private
   */
  validationMessageCallback(message: string): void {
    this.internalErrorMessage = message;
  }

  /**
   * @private
   */
  resetFormControl(): void {
    this.radios.map((radio) => (radio.checked = false));
    this.value = '';
  }

  /**
   * @private
   */
  protected updated(changed: Map<string, unknown>): void {
    if (changed.has('value')) {
      this.setValue(this.value);
    }
  }

  /**
   * Sets focus on the radio group
   */
  focus(options?: FocusOptions) {
    this.validationTarget.focus(options);
  }

  /**
   * Removes focus from the radio group
   */
  blur() {
    this.validationTarget.blur();
  }

  connectedCallback() {
    super.connectedCallback();
    this.shadowRoot!.addEventListener('slotchange', () => {
      this.radios = Array.from(this.querySelectorAll<HznRadio>(':scope > *')).filter(el => el instanceof HznRadio);
      const checked = this.radios.filter((el) => el.checked === true);
      if (checked.length > 0) {
        if(!this.value) {
          this.value = checked[0].value;
          this.internals.setFormValue(this.value);
        }
      }
    });

    // hide default browser invalid popup
    this.addEventListener('invalid', (event: Event) => event.preventDefault());
  }

  /**
   * @private
   */
  #onKeydown(event: KeyboardEvent) {
    const hasModifier = event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    // Pressing enter when focused on an input should submit the form like a native input
    if (event.code === 'Enter' && !hasModifier) {
      if (this.form) {
        submit(this.form);
      }
    }
  }

  /**
   * @private
   */
  #handleChange(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.radios.forEach((radio, index) => {
      const targetIndex = Array.from(this.innerRadioInputs).findIndex((el) => el === event.target);
      radio.checked = index === targetIndex;
    });

    this.#emit({ type: 'change' });
    this.requestUpdate();
  }

  /**
   * @private
   */
  #handleInput(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.value = (event.target as HTMLInputElement).value;
    this.#emit({ type: 'input' });
  }

  /**
   * @private
   */
  #handleClick(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    const innerInput = (event.target as HTMLElement).previousElementSibling as HTMLInputElement;

    innerInput.click();
  }

  /**
   * @private
   */
  #renderRadioGroup() {
    return html`${this.radios.map((radioElement, index) => {
      const isChecked = () => {
        if(!this.value) {
          return radioElement.checked;
        }
        return radioElement.value === this.value;
      }

      const isAutoFocus = this.autofocus && index === 0;

      return html`
        <label
          class=${classMap({
            'radio-label': true,
            'is-horizontal': Boolean(this.horizontal)
          })}
        >
          <div
            class=${classMap({
              'radio-control': true,
              'is-checked': Boolean(isChecked()),
              'is-disabled': Boolean(this.disabled),
              'has-error': this.internalErrorMessage
            })}
          >
            <input
              class="visually-hidden"
              type="radio"
              name=${ifDefined(this.name)}
              .value="${radioElement.value}"
              .checked=${Boolean(isChecked())}
              .disabled=${Boolean(this.disabled)}
              ?autofocus=${isAutoFocus}
              ?required="${this.required}"
              aria-checked=${isChecked() ? 'true' : 'false'}
              @change=${this.#handleChange}
              @input=${this.#handleInput}
              @keydown="${this.#onKeydown}"
            />
          </div>
          <hzn-stack space="xxsmall">
            <hzn-text class="radio-label-text" tone="${ifDefined(this.disabled ? 'disabled' : undefined)}">
              ${radioElement.textContent || `Radio button ${index + 1}`}
            </hzn-text>
            ${radioElement.helperText
              ? html` <hzn-text
                  class="radio-helper-text ${this.hideHelperText ? ' visually-hidden' : ''}"
                  variant="caption"
                  tone="${this.disabled ? 'disabled' : 'subdued'}"
                >
                  ${radioElement.helperText}
                </hzn-text>`
              : nothing}
          </hzn-stack>
        </label>`;
      })}`;
  }

  render(): TemplateResult {

    return html`
      <fieldset aria-describedby="group-helper-text">
        <hzn-stack space="medium">
          <hzn-stack
            space="xsmall"
            class=${classMap({
              'radio-group-heading':true,
              'visually-hidden': this.#showGroupHeading
            })}
          >
            <legend
              class=${classMap({
                'radio-group-legend':true,
                'visually-hidden': Boolean(this.hideLegend)
              })}
            >
              <hzn-text weight="bold">${this.legend || this.name}</hzn-text>
            </legend>
            ${this.helperText
              ? html`<hzn-text
                  class="radio-group-helper-text ${this.hideHelperText ? ' visually-hidden' : ''}"
                  id="group-helper-text"
                  variant="caption"
                  tone="${this.disabled ? 'disabled' : 'subdued'}"
                >
                  ${this.helperText}
                </hzn-text>`
              : nothing}
            ${this.internalErrorMessage
              ? html`<hzn-text class="radio-group-error-message" variant="caption" tone="critical">
                  ${this.internalErrorMessage}
                </hzn-text>`
              : nothing}
          </hzn-stack>

          ${!this.horizontal
            ? html`<hzn-stack class="radio-group"> ${this.#renderRadioGroup()} </hzn-stack>`
            : html`<hzn-inline space="large" class="radio-group" align-y="start"> ${this.#renderRadioGroup()} </hzn-inline>`}
        </hzn-stack>
        <slot hidden></slot>
      </fieldset>
    `;
  }
}
