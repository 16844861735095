import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { DrawerStatus, OpenDrawerStatus } from './electron/CashDrawerApi';
const cashDrawer = () => {
    if (!window.CashDrawer)
        throw 'Cash Drawer hardware interface not found.';
    return window.CashDrawer;
};
const cashDrawerTag = ['HardwareCashDrawer'];
const cashDrawerApi = createApi({
    reducerPath: 'cashDrawer',
    baseQuery: fakeBaseQuery(),
    endpoints: () => ({}),
})
    .enhanceEndpoints({
    addTagTypes: cashDrawerTag,
})
    .injectEndpoints({
    endpoints: builder => ({
        hardwareCashDrawerGetStatus: builder.query({
            queryFn: async () => {
                try {
                    const status = await cashDrawer().getCashDrawerStatusAsync();
                    return { data: status };
                }
                catch (ex) {
                    return { error: { data: DrawerStatus.Error, status: 0, statusText: ex } };
                }
            },
            providesTags: cashDrawerTag,
        }),
        hardwareCashDrawerOpen: builder.mutation({
            queryFn: async () => {
                try {
                    const status = await cashDrawer().openCashDrawerAsync();
                    return { data: status };
                }
                catch (ex) {
                    return { error: { data: OpenDrawerStatus.Error, status: 0, statusText: ex } };
                }
            },
            invalidatesTags: cashDrawerTag,
        }),
    }),
});
export { cashDrawerApi as CashDrawerApi };
export { DrawerStatus, OpenDrawerStatus }; // re-export common types. 
export const { useHardwareCashDrawerGetStatusQuery, useHardwareCashDrawerOpenMutation } = cashDrawerApi;
