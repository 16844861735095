import React, { useRef } from 'react';
import { reset } from './BasketSearchSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useEventListener } from '../../hooks/useEventListener';
import { NoResults } from '../../assets/NoResults';
import { HorizonTextNav } from '../../components/horizon/HorizonTextNav';
export const NoResultsAlert = ({ clearable = false }) => {
    const dispatch = useAppDispatch();
    const ref = useRef(undefined);
    useEventListener(ref, 'click', () => dispatch(reset()));
    return (React.createElement("div", { className: "d-flex flex-row align-items-center justify-content-center gap-3" },
        React.createElement("div", null,
            React.createElement(NoResults, null)),
        React.createElement("div", { className: "d-flex flex-column gap-3" },
            React.createElement("div", null,
                React.createElement("hzn-text", { variant: "callout" }, "No matches found.")),
            clearable && (React.createElement("div", null,
                React.createElement(HorizonTextNav, { size: "large", onClick: () => dispatch(reset()) }, "Clear search criteria."))))));
};
