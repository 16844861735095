import { FlexColumn } from '@src/components/Flex';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import React from 'react';
import { Modal } from 'react-bootstrap';
/**
 *  ScanCheck prompts the user to scan the check and handles the hardware interaction.
 *  TODO: Check scanning integration doesn't exist yet. For now just proceed to the next step.
 */
export const ScanCheck = (props) => {
    const { payment } = useAppSelector(selectPayment);
    const description = payment.paymentType === 'CERTIFIED_FUNDS' ? 'certified funds ' : '';
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal.Header, null,
            React.createElement("hzn-heading", { size: "xsmall" }, "Processing payment...")),
        React.createElement(Modal.Body, null,
            React.createElement(FlexColumn, null,
                React.createElement("hzn-text", null,
                    "Scan the ",
                    description,
                    "check before placing it in the slot of the cash register drawer."),
                props.children)),
        React.createElement(Modal.Footer, null,
            React.createElement(FlexColumn, { className: "w-100" },
                React.createElement(HorizonButton, { variant: "primary", onClick: props.onContinue }, "Scan Check"),
                React.createElement(HorizonButton, { variant: "tertiary", onClick: props.onCancel }, "Cancel")))));
};
