export const getVehicleName = (basket) => {
    const lineItems = basket.lineItems?.filter(item => item.lineItemType === 'APPRAISAL_ITEM');
    return lineItems.length > 0 ? lineItems[0].name : 'NOT FOUND';
};
export const getPartyId = (basket) => basket.lineItems[0].participants.find(p => p.roles.includes('SELLER')).id;
const ActivePaymentStatuses = ['AUTHORIZED', 'NEW', 'PAID', 'PENDING', 'PROCESSING', 'SUBMITTED'];
const getPaymentsOfDirectionAndType = (basket, direction, statuses) => (basket.payments ?? []).filter(p => p.direction == direction && statuses.includes(p.status));
const totalPayments = (payments) => payments.reduce((acc, p) => acc + (p.amount ?? 0), 0);
export const getActivePayments = (basket) => getPaymentsOfDirectionAndType(basket, 'INBOUND', ActivePaymentStatuses);
export const getUnprocessedPayments = (basket) => getPaymentsOfDirectionAndType(basket, 'INBOUND', ['NEW']);
export const getProcessedPaymentsTotal = (basket) => totalPayments(getPaymentsOfDirectionAndType(basket, 'INBOUND', ['PAID']));
export const getTotalEquity = (basket) => basket.lineItems.reduce((acc, curr) => acc + (curr.equity ?? 0), 0);
export const getNegativeEquityBalance = (basket) => {
    // if the customer equity is positive, there is no balance.
    const totalEquity = getTotalEquity(basket);
    if (totalEquity >= 0)
        return 0;
    // total up all the inbound unprocessed payments (cash, debit, etc.)
    const sumOfUnprocessedPayments = totalPayments(getActivePayments(basket));
    // the customer equity will be negative, e.g. -5000.
    // the unprocessed payment sum will be positive, e.g. 2000
    // the balance should be positive. math.abs(-5000 + 2000) => 3000
    return Math.abs(getTotalEquity(basket) + sumOfUnprocessedPayments);
};
