import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavMenu } from './NavMenu';
import { FlexColumn } from './Flex';
export const Layout = () => {
    const profile = JSON.parse(sessionStorage.getItem('com.unboundid.kmxpos.profile'));
    return (React.createElement(FlexColumn, { className: "gap-5 h-100" },
        React.createElement(NavMenu, { userdata: profile }),
        React.createElement("hzn-stack", { "align-x": "center" },
            React.createElement("div", { className: "p-4 w-90" },
                React.createElement(Outlet, null)))));
};
