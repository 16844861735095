// class definition
import { LitElement } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';

import { HznIconSize, HznIconVariant } from '../types.js';
import iconStyles from './icon.css.js';
export class HznIconBase extends LitElement {
  static styles = [iconStyles];

  firstUpdated() {
    this.setAttribute('inert', '');
  }

  /**
   * The size of the icon
   * @playroomValues {'small' | 'base' | 'large'}
   */
  @property({ type: String, reflect: true }) size?: HznIconSize;

  /**
   * The stylistic variant of the icon glyph - stroked or filled
   * @playroomValues {'stroked' | 'filled'}
   */
  @property({ type: String }) variant?: HznIconVariant = 'stroked';
}
