import { HznCurrencyInput } from './currency-input.js';
import type { Validator } from '@open-wc/form-control';

export const currencyRequiredValidator: Validator = {
  attribute: 'required',
  key: 'valueMissing',
  message(instance: HznCurrencyInput) {
    if (instance.errorMessages && instance.errorMessages.valueMissing) {
      if (instance.errorMessages.valueMissing instanceof Function) {
        return instance.errorMessages.valueMissing(instance);
      }
      return instance.errorMessages.valueMissing;
    }
    return `${instance.displayName ? instance.displayName : 'This field'} is required`;
  },
  isValid(instance: HznCurrencyInput, value: string) {
    let valid = true;
    if (
      (instance.hasAttribute('required') || instance.required) &&
      (!value || value === instance.defaultInitialValue)
    ) {
      valid = false;
    }
    return valid;
  },
};

export const currencyMaxValidator: Validator = {
  attribute: 'max',
  key: 'rangeOverflow',
  message(instance: HznCurrencyInput): string {
    if (instance.errorMessages && instance.errorMessages.rangeOverflow) {
      if (instance.errorMessages.rangeOverflow instanceof Function) {
        return instance.errorMessages.rangeOverflow(instance);
      }
      return instance.errorMessages.rangeOverflow;
    }

    return `${instance.displayName ? `${instance.displayName} must be` : 'Enter a dollar amount'
      } less than or equal to ${instance.max}`;
  },
  isValid(instance: HznCurrencyInput): boolean {
    /** If max isn't set, this is valid */
    if (!instance.max) {
      return true;
    }

    if (instance.value) {
      const formattedValue = instance.value.replace(/[$,]/g, '');
      if (!!formattedValue && parseFloat(String(instance.max)) < parseFloat(formattedValue)) {
        return false;
      }
    }

    return true;
  },
};

export const currencyMinValidator: Validator = {
  attribute: 'min',
  key: 'rangeUnderflow',
  message(instance: HznCurrencyInput): string {
    if (instance.errorMessages && instance.errorMessages.rangeUnderflow) {
      if (instance.errorMessages.rangeUnderflow instanceof Function) {
        return instance.errorMessages.rangeUnderflow(instance);
      }
      return instance.errorMessages.rangeUnderflow;
    }

    return `${instance.displayName ? `${instance.displayName} must be` : 'Enter a dollar amount'
      } greater than or equal to ${instance.min}`;
  },
  isValid(instance: HznCurrencyInput): boolean {
    /** If min isn't set, this is valid */
    if (!instance.min) {
      return true;
    }

    if (instance.value) {
      const formattedValue = instance.value.replace(/[$,]/g, '');
      if (!!formattedValue && parseFloat(String(instance.min)) > parseFloat(formattedValue)) {
        return false;
      }
    }

    return true;
  },
};

export const currencyPatternValidator: Validator = {
  attribute: 'pattern',
  key: 'patternMismatch',
  message(instance: HznCurrencyInput): string {
    if (instance.errorMessages && instance.errorMessages.patternMismatch) {
      if (instance.errorMessages.patternMismatch instanceof Function) {
        return instance.errorMessages.patternMismatch(instance);
      }

      return instance.errorMessages.patternMismatch;
    }

    return 'Enter a valid dollar amount';
  },
  isValid(instance: HznCurrencyInput, value: string): boolean {
    if (instance.validationTarget && value !== instance.defaultInitialValue) {
      // reverse the browser validity
      return !(instance.validationTarget as HTMLInputElement).validity.patternMismatch;
    }
    return true;
  },
};

