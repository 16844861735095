
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-arrow-down
 * @tagname hzn-icon-arrow-down
 * @summary An individual icon for Horizon
 */
export class HznIconArrowDown extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M18.16 13.84C17.88 13.56 17.43 13.56 17.16 13.84L12.72 18.28V4.00004C12.72 3.61004 12.4 3.29004 12.01 3.29004C11.62 3.29004 11.3 3.61004 11.3 4.00004V18.29L6.85995 13.85C6.57995 13.57 6.12995 13.57 5.85995 13.85C5.58995 14.13 5.57995 14.58 5.85995 14.85L11.52 20.51C11.58 20.58 11.66 20.63 11.75 20.66C11.84 20.7 11.93 20.72 12.02 20.72C12.11 20.72 12.21 20.7 12.29 20.66C12.38 20.62 12.45 20.57 12.52 20.51L18.18 14.85C18.46 14.57 18.46 14.12 18.18 13.85L18.16 13.84Z" fill="currentColor" class="stroked"></path><path d="M18.16 13.84C17.88 13.56 17.43 13.56 17.16 13.84L12.72 18.28V4.00004C12.72 3.61004 12.4 3.29004 12.01 3.29004C11.62 3.29004 11.3 3.61004 11.3 4.00004V18.29L6.85995 13.85C6.57995 13.57 6.12995 13.57 5.85995 13.85C5.58995 14.13 5.57995 14.58 5.85995 14.85L11.52 20.51C11.58 20.58 11.66 20.63 11.75 20.66C11.84 20.7 11.93 20.72 12.02 20.72C12.11 20.72 12.21 20.7 12.29 20.66C12.38 20.62 12.45 20.57 12.52 20.51L18.18 14.85C18.46 14.57 18.46 14.12 18.18 13.85L18.16 13.84Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
