import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { CardFlowEvent, CardReaderConnectionStatus, } from './electron/CardFlowApi';
const cardFlow = () => {
    if (!window.CardFlow)
        throw 'Card Flow hardware interface not found.';
    return window.CardFlow;
};
const cardFlowApi = createApi({
    reducerPath: 'cardFlow',
    baseQuery: fakeBaseQuery(),
    endpoints: builder => ({
        /**
         * Check whether the electron app currently detects a connected and configured card reader.
         * If true, the card reader should be ready to process debit card payments.
         */
        hardwareConnectionStatus: builder.query({
            queryFn: async () => {
                try {
                    const isConnected = await cardFlow().getConnectionStatus();
                    return { data: isConnected };
                }
                catch (ex) {
                    return { error: { data: CardReaderConnectionStatus.Error, status: 0, statusText: ex } };
                }
            },
        }),
        /**
         * Initiate a debit card read. The electron app handles all user interaction.
         */
        hardwareSwipeCard: builder.mutation({
            queryFn: async (queryArg) => {
                try {
                    const result = await cardFlow().requestCardSwipe(queryArg.customerName, queryArg.amountInUSD, queryArg.requireSignature);
                    return { data: result };
                }
                catch (ex) {
                    return { error: { data: undefined, status: 0, statusText: ex } };
                }
            },
        }),
    }),
});
export { cardFlowApi as CardFlowApi };
export { CardFlowEvent }; // re-export common types.
export const { useHardwareConnectionStatusQuery, useHardwareSwipeCardMutation } = cardFlowApi;
