// Note: The contents of this file must exactly match the cashDrawerApi.ts file in
// the Electron app. We don't anticipate these APIs changing frequently, so we're
// opting with this approach rather than creating an NPM package that both the
// Desktop Electron app and this site references.
// Source
// https://github.com/CarMax-Internal/commerce-point-of-sale-desktop/blob/main/src/CarMax.PointOfSale.DesktopApp/src/common/cashDrawerApi.ts
export var DrawerStatus;
(function (DrawerStatus) {
    DrawerStatus["Error"] = "Error";
    DrawerStatus["Offline"] = "Offline";
    DrawerStatus["Closed"] = "Closed";
    DrawerStatus["Open"] = "Open";
})(DrawerStatus || (DrawerStatus = {}));
export var OpenDrawerStatus;
(function (OpenDrawerStatus) {
    OpenDrawerStatus["Error"] = "Error";
    OpenDrawerStatus["Offline"] = "Offline";
    OpenDrawerStatus["Opened"] = "Opened";
    OpenDrawerStatus["AlreadyOpened"] = "AlreadyOpened";
})(OpenDrawerStatus || (OpenDrawerStatus = {}));
