
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-warning
 * @tagname hzn-icon-warning
 * @summary An individual icon for Horizon
 */
export class HznIconWarning extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.36 2.64002L22.32 16.66H22.31C22.8 17.51 22.79 18.52 22.3 19.36C21.81 20.21 20.94 20.71 19.96 20.71H4.02999C3.05999 20.71 2.17999 20.2 1.68999 19.36C1.19999 18.52 1.19999 17.51 1.67999 16.66L9.63999 2.64002C10.13 1.78002 11.01 1.27002 12 1.27002C12.99 1.27002 13.87 1.78002 14.36 2.64002ZM19.97 19.29C20.43 19.29 20.85 19.05 21.08 18.65H21.09C21.32 18.25 21.32 17.76 21.09 17.36L13.12 3.34002C12.89 2.93002 12.47 2.69002 12 2.69002C11.53 2.69002 11.11 2.93002 10.88 3.34002L2.90999 17.36C2.66999 17.77 2.67999 18.25 2.90999 18.65C3.14999 19.05 3.56999 19.29 4.02999 19.29H19.97ZM10.95 7.39998L11.29 13.5C11.34 14.38 12.66 14.38 12.71 13.5L13.05 7.39998V7.27998C12.95 5.89998 10.89 6.00998 10.94 7.39998H10.95ZM12 17.61C12.5523 17.61 13 17.1623 13 16.61C13 16.0577 12.5523 15.61 12 15.61C11.4477 15.61 11 16.0577 11 16.61C11 17.1623 11.4477 17.61 12 17.61Z" fill="currentColor" class="stroked"></path><path d="M22.32 16.66L14.36 2.64002C13.87 1.78002 12.99 1.27002 12 1.27002C11.01 1.27002 10.13 1.78002 9.63999 2.64002L1.67999 16.66C1.19999 17.51 1.19999 18.52 1.68999 19.36C2.17999 20.2 3.05999 20.71 4.02999 20.71H19.96C20.94 20.71 21.81 20.21 22.3 19.36C22.79 18.51 22.8 17.51 22.31 16.66H22.32ZM12 17.61C11.45 17.61 11 17.16 11 16.61C11 16.06 11.45 15.61 12 15.61C12.55 15.61 13 16.06 13 16.61C13 17.16 12.55 17.61 12 17.61ZM13.05 7.40002L12.71 13.5C12.66 14.38 11.34 14.38 11.29 13.5L10.95 7.40002C10.9 6.02002 12.95 5.90002 13.06 7.28002C13.06 7.32002 13.06 7.36002 13.06 7.40002H13.05Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
