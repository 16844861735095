import '@horizon/icons/individual/hzn-trash';
import '@horizon/icons/individual/hzn-pencil-underline';
import { Card } from '@src/components/Card';
import { PaymentReadCard } from '@src/pages/BasketCheckout/Payment/PaymentReadCard/PaymentReadCard';
import React, { useState } from 'react';
import { useDeletePaymentMutation } from '@src/api/CheckoutApi';
import { Suspense } from '@src/components/Suspense';
import { PaymentAddCard } from './PaymentAddCard/PaymentAddCard';
import { FlexRow } from '@src/components/Flex';
import { PaymentTypeName } from '@src/utils/Formatting';
export const PaymentCard = ({ basketId, partyId, payment, balance }) => {
    const [isEditing, setIsEditing] = useState(!payment);
    const [deletePayment, deletePaymentStatus] = useDeletePaymentMutation();
    const onDeletePayment = () => deletePayment({ basketId, paymentId: payment.id })
        .unwrap()
        .catch(err => console.log('Delete payment error', err));
    const title = payment ? PaymentTypeName(payment.paymentType) : 'New Payment Method';
    return (React.createElement(Card, null,
        React.createElement("div", { style: { minHeight: '3rem' } },
            React.createElement(FlexRow, { className: "pb-3 align-items-center" },
                React.createElement("hzn-text", { weight: "bold" }, title),
                React.createElement("div", { className: "ms-auto", style: { minHeight: '3rem' } },
                    React.createElement(Suspense, { suspended: deletePaymentStatus.isLoading || deletePaymentStatus.isSuccess }, payment?.status == 'NEW' && !isEditing && (React.createElement(React.Fragment, null,
                        React.createElement("hzn-icon-button", { tone: "inverse", label: `Edit ${title}`, onClick: () => setIsEditing(true) },
                            React.createElement("hzn-icon-pencil-underline", null)),
                        React.createElement("hzn-icon-button", { tone: "critical", label: `Delete ${title}`, onClick: onDeletePayment },
                            React.createElement("hzn-icon-trash", null)))))))),
        isEditing && (React.createElement(PaymentAddCard, { basketId: basketId, partyId: partyId, payment: payment, balance: balance, onClose: () => setIsEditing(false) })),
        !isEditing && React.createElement(PaymentReadCard, { payment: payment, basketIsBalanced: !balance })));
};
