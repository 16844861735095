// Note: The contents of this file must exactly match the cardFlowApi.ts file in
// the Electron app. We don't anticipate these APIs changing frequently, so we're
// opting with this approach rather than creating an NPM package that both the
// Desktop Electron app and this site references.
// Source:
// https://github.com/CarMax-Internal/commerce-point-of-sale-desktop/blob/main/src/CarMax.PointOfSale.DesktopApp/src/common/cardFlowApi.ts
export var CardReaderConnectionStatus;
(function (CardReaderConnectionStatus) {
    CardReaderConnectionStatus["Error"] = "Error";
    CardReaderConnectionStatus["Disconnected"] = "Disconnected";
    CardReaderConnectionStatus["Connected"] = "Connected";
})(CardReaderConnectionStatus || (CardReaderConnectionStatus = {}));
export var CardReaderInputStatus;
(function (CardReaderInputStatus) {
    CardReaderInputStatus["Cancelled"] = "Cancelled";
    CardReaderInputStatus["Error"] = "Error";
    CardReaderInputStatus["Manual"] = "Manual";
    CardReaderInputStatus["Retrying"] = "Retrying";
    CardReaderInputStatus["Success"] = "Success";
})(CardReaderInputStatus || (CardReaderInputStatus = {}));
export var CardFlowEvent;
(function (CardFlowEvent) {
    CardFlowEvent["TransactionRejected"] = "transactionRejected";
    CardFlowEvent["TransactionConfirmed"] = "transactionConfirmed";
    CardFlowEvent["CardEntryCancelled"] = "cardEntryCancelled";
    CardFlowEvent["CardEntryErrored"] = "cardEntryErrored";
    CardFlowEvent["CardEntryManual"] = "cardEntryManual";
    CardFlowEvent["CardEntryRetrying"] = "cardEntryRetrying";
    CardFlowEvent["CardEntryMaxRetry"] = "cardEntryMaxRetry";
    CardFlowEvent["CardEntrySuccess"] = "cardEntrySuccess";
    CardFlowEvent["PINEntryFailed"] = "pinEntryFailed";
    CardFlowEvent["PINEntrySuccess"] = "pinEntrySuccess";
    CardFlowEvent["SignaturePending"] = "signaturePending";
})(CardFlowEvent || (CardFlowEvent = {}));
