import { FlexRow } from '@src/components/Flex';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';
export const DebitPaymentDescription = () => {
    const { payment } = useAppSelector(selectPayment);
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexRow, { className: 'justify-content-between pt-3 ps-2 pe-2' },
            React.createElement("hzn-text", { tone: "subdued" }, "Payment method"),
            React.createElement("hzn-text", null, "Debit Card")),
        React.createElement("hzn-divider", null),
        React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
            React.createElement("hzn-text", { tone: "subdued" }, "Amount"),
            React.createElement("hzn-text", null, AsCurrency(payment.amount)))));
};
