import { LitElement, TemplateResult, html } from '@horizon/base';
import { property, query } from '@horizon/base/decorators.js';
import { classMap, ifDefined } from '@horizon/base/directives.js';
import { nothing } from '@horizon/base/html.js';

import { ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';

import { HznIconExternalLink } from '@horizon/icons/individual';
import TextLinkStyles from './text-link.css.js';
import { HznTextLinkAlign, HznTextLinkOn, HznTextLinkSize, HznTextLinkTone, HznTextLinkWeight, HznTextLinkLineHeight } from '../types.js';

/**
 *
 * @tag hzn-text-link
 * @tagname hzn-text-link
 * @summary A link component for adding text-links within text tags or by themselves
 *
 * @fires {HznTextLinkClickEvent} click - Emitted when the text-link is clicked
 */

export class HznTextLink extends (ScopedElementsMixin(LitElement) as typeof LitElement) {
  static styles = [TextLinkStyles];

  static get scopedElements() {
    return { 'hzn-icon-external-link': HznIconExternalLink };
  }

  // INTERNAL ACCESS PROPS
  /**
   * The internal native anchor element
   */
  @query('a') innerAnchor!: HTMLAnchorElement;

  /**
   * Sets the href of the contained anchor tag
   */
  @property({ type: String }) href?: string = '';

  /**
   * Sets the rel of the contained anchor tag
   */
  @property({ type: String }) rel?: string;

  /**
   * Sets the target of the contained anchor tag
   */
  @property({ type: String }) target?: string;

  /**
   * Tells the browser to download the linked file as this filename. Only used when `href` is set.
   */
  @property({ type: String }) download?: string;

  /**
   * Displays an external link icon when text-link directs you away from carmax website.
   */
  @property({ type: Boolean }) external?: boolean;

  /**
   * Sets the tag to inline-block or block - for use when contained in text tags
   */
  @property({ type: Boolean, reflect: true }) block?: boolean = false;

  /**
   * Turns off underlining for text content within contained anchor tag
   */
  @property({ type: Boolean, attribute: 'no-underline' }) noUnderline?: boolean = false;

  /**
   * Sets the tone/color of text within anchor tag
   * @playroomValues {'interactive' | 'subdued' | 'inverse' | 'brand'}
   */
  @property({ type: String }) tone?: HznTextLinkTone = 'interactive';

  /**
   * Sets the font size of text within anchor tag
   * @playroomValues {'small' | 'base' | 'large' | 'caption' | 'callout'}
   */
  @property({ type: String }) size?: HznTextLinkSize = 'base';

  /**
   * Sets the font weight of text within anchor tag
   * @playroomValues {'base' | 'bold'}
   */
  @property({ type: String }) weight?: HznTextLinkWeight = 'base';

  /**
   * Sets the justification of text along the x-axis. Only used when `block` is set.
   * @playroomValues {'start' | 'end' | 'left' | 'center' | 'right'}
   */
  @property({ type: String }) align: HznTextLinkAlign = 'start';

  /**
   * Sets the background color that this link is appearing on top of in order to change its color accordingly
   * @playroomValues {'brand' | 'brand-accent'}
   */
  @property({ type: String }) on?: HznTextLinkOn;

  /**
   * Passes an aria-label property to the anchor tag
  */
  @property({ type: String, attribute: 'hzn-aria-label' }) ariaLabel!: string;

  /**
   * Passes a title property to the anchor tag
  */
  @property({ type: String, attribute: 'hzn-title' }) title!: string;

  /**
   * Sets the line height of the anchor tag
   * @playroomValues {'none' | 'xsmall' | 'small' | 'base' | 'large' | 'xlarge'}
   */
  @property({ type: String, attribute: 'line-height' }) lineHeight?: HznTextLinkLineHeight = 'base';

  /*
   * When defined, sets font-size to not be fluid (css clamp) and not change size fluidly oacross responsive breakpoints
   */
  @property({ type: Boolean }) static: boolean = false;

  /**
   * Simulates a click on the button
   */
  click() {
    if (this.innerAnchor) {
      this.innerAnchor.click();
    }
  }

  /**
   * Focus the innerAnchor
   */
  focus(options?: FocusOptions) {
    this.innerAnchor.focus(options);
  }

  /**
   * Remove focus from the innerAnchor
   */
  blur() {
    this.innerAnchor.blur();
  }

  /**
   * @private
   */
  #iconSize() {
    if (!this.block) {
      return 'base';
    } else {
      if (this.size === 'callout' || this.size === 'large') {
        return 'large';
      } else if (this.size === 'caption') {
        return 'small';
      } else {
        return 'base';
      }
    }
  }

  render(): TemplateResult {

    // DO NOT ADD WHITE SPACE OR NEW LINES IN THIS RENDER TEMPLATE
    // hzn-text-links can be `display: inline` so the whitespace in the render template influences the visual render.
    // therefore, there cannot be any whitespace in the render template
    return html`<a
      class="${classMap({
      'text-link': true,
      'is-align-center': Boolean(this.align === 'center'),
      'is-align-end': Boolean(this.align === 'end'),
      'is-align-left': Boolean(this.align === 'left'),
      'is-align-right': Boolean(this.align === 'right'),
      'is-align-start': Boolean(this.align === 'start'),
      'is-block': Boolean(this.block),
      'is-external': Boolean(this.external),
      'is-static': Boolean(this.static),
      'is-line-height-base': Boolean(this.block && this.lineHeight === 'base'),
      'is-line-height-large': Boolean(this.block && this.lineHeight === 'large'),
      'is-line-height-none': Boolean(this.block && this.lineHeight === 'none'),
      'is-line-height-small': Boolean(this.block && this.lineHeight === 'small'),
      'is-line-height-xlarge': Boolean(this.block && this.lineHeight === 'xlarge'),
      'is-line-height-xsmall': Boolean(this.block && this.lineHeight === 'xsmall'),
      'is-no-underline': Boolean(this.noUnderline),
      'is-on-brand-accent': Boolean(this.on === 'brand-accent'),
      'is-on-brand': Boolean(this.on === 'brand'),
      'is-size-base': Boolean(this.block && this.size === 'base'),
      'is-size-callout': Boolean(this.block && this.size === 'callout'),
      'is-size-caption': Boolean(this.block && this.size === 'caption'),
      'is-size-large': Boolean(this.block && this.size === 'large'),
      'is-size-small': Boolean(this.block && this.size === 'small'),
      'is-tone-brand': Boolean(this.tone === 'brand'),
      'is-tone-interactive': Boolean(this.tone === 'interactive'),
      'is-tone-inverse': Boolean(this.tone === 'inverse'),
      'is-tone-subdued': Boolean(this.tone === 'subdued'),
      'is-weight-base': Boolean(this.weight === 'base'),
      'is-weight-bold': Boolean(this.weight === 'bold'),
    })}"
    href="${ifDefined(this.href)}"
    rel=${ifDefined(this.rel ? `${this.rel}` : ifDefined(this.target ? 'noreferrer noopener' : undefined))}
    target=${ifDefined(this.target)}
    download=${ifDefined(this.download)}
    title=${ifDefined(this.title)}
    aria-label=${ifDefined(this.ariaLabel)}
    ><slot></slot>${this.external
      ? html`<hzn-icon-external-link size="${this.#iconSize()}"></hzn-icon-external-link>`
      : nothing}${this.target ? html`<span class="visually-hidden">. opens in a new window.</span>` : nothing}</a>`;
  }
}
