import '@horizon/icons/individual/hzn-plus';
import { Card } from '@src/components/Card';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePageTitle } from '@src/hooks/usePageTitle';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { PaymentAddCard } from './PaymentAddCard/PaymentAddCard';
import { PaymentCard } from './PaymentCard';
import { getActivePayments, getNegativeEquityBalance, getPartyId } from '@src/utils/BasketFns';
import { ProcessCashModal } from '../ProcessPayment';
import { ProcessDebitModal } from '../ProcessPayment/Debit/ProcessDebitModal';
import { ProcessCheckModal } from '../ProcessPayment/Check/ProcessCheckModal';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { ProcessCertifiedFundsModal } from '../ProcessPayment/CertifiedFunds/ProcessCertifiedFundsModal';
export const NegativeEquity = ({ basket }) => {
    usePageTitle('Payment');
    const { basketId } = useParams();
    const [addingPayment, setAddingPayment] = useState(false);
    const { payment } = useAppSelector(selectPayment);
    const partyId = useMemo(() => getPartyId(basket), [basket]);
    const payments = useMemo(() => getActivePayments(basket), [basket]);
    const balance = useMemo(() => getNegativeEquityBalance(basket), [basket]);
    return (React.createElement("hzn-stack", { space: "medium" },
        payments.map(payment => (React.createElement(PaymentCard, { key: `${payment.id}`, basketId: basketId, partyId: partyId, payment: payment, balance: balance }))),
        addingPayment && (React.createElement(Card, null,
            React.createElement("hzn-text", { weight: "bold", class: "pb-4" }, "Add Payment Method"),
            React.createElement(PaymentAddCard, { basketId: basketId, partyId: partyId, balance: balance, onClose: () => setAddingPayment(false) }))),
        React.createElement(HorizonButton, { variant: "secondary", disabled: addingPayment || !balance, onClick: () => {
                setAddingPayment(true);
            } },
            "Add Payment Method",
            React.createElement("hzn-icon-plus", { slot: "trailing-icon", size: "large" })),
        payment && (React.createElement(React.Fragment, null,
            React.createElement(ProcessCashModal, { show: payment.paymentType === 'CASH' }),
            React.createElement(ProcessDebitModal, { show: payment.paymentType === 'DEBIT' }),
            React.createElement(ProcessCheckModal, { show: payment.paymentType === 'CHECK' }),
            React.createElement(ProcessCertifiedFundsModal, { show: payment.paymentType === 'CERTIFIED_FUNDS' })))));
};
