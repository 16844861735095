import { AppInsightsErrorBoundary, AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { reactPlugin } from './common/appinsights';
import { GenericNotFound } from './components/GenericNotFound';
import { Layout } from './components/Layout';
import { ToastStack } from './components/toast/ToastStack';
import { BasketSearchPage } from './pages/BasketSearch/BasketSearchPage';
import { store } from './store/store';
import Gateway from './components/Gateway';
import { StoreOutlet } from './components/StoreOutlet';
import { BasketCheckout } from './pages/BasketCheckout/BasketCheckout';
export const AppRoutes = ({ claims }) => (React.createElement(AppInsightsErrorBoundary, { onError: () => React.createElement(React.Fragment, null), appInsights: reactPlugin },
    React.createElement(AppInsightsContext.Provider, { value: reactPlugin },
        React.createElement(Provider, { store: store },
            React.createElement(BrowserRouter, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "checkout", element: React.createElement(Layout, null) },
                        React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: claims['kmx.locationnumber'][0], replace: true }) }),
                        React.createElement(Route, { path: ":storeLocationId", element: React.createElement(StoreOutlet, null) },
                            React.createElement(Route, { index: true, element: React.createElement(BasketSearchPage, null) }),
                            // TODO: abolish this completely once we can use Order Tender Validation endpoint AND/OR 
                            // stepper state to determine what stage we're in.
                            // THIS IS A HACK!!
                            React.createElement(Route, { path: ":basketId" },
                                React.createElement(Route, { index: true, element: React.createElement(BasketCheckout, { step: 'PAYMENT' }) }),
                                React.createElement(Route, { path: "tenderOrder", element: React.createElement(BasketCheckout, { step: 'TENDER_ORDER' }) }))),
                        React.createElement(Route, { path: "*", element: React.createElement(GenericNotFound, null) })),
                    React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/checkout", replace: true }) }))),
            React.createElement(ToastStack, null)))));
export const GatewayRoutes = () => (React.createElement(AppInsightsErrorBoundary, { onError: () => React.createElement(React.Fragment, null), appInsights: reactPlugin },
    React.createElement(AppInsightsContext.Provider, { value: reactPlugin },
        React.createElement(BrowserRouter, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "gateway", element: React.createElement(Outlet, null) },
                    React.createElement(Route, { path: "*", element: React.createElement(Gateway, null) })))))));
