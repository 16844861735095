
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-arrow-up
 * @tagname hzn-icon-arrow-up
 * @summary An individual icon for Horizon
 */
export class HznIconArrowUp extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M5.83999 10.16C6.11999 10.44 6.56999 10.44 6.83999 10.16L11.28 5.72003V20.01C11.28 20.4 11.6 20.72 11.99 20.72C12.38 20.72 12.7 20.4 12.7 20.01V5.71003L17.14 10.15C17.42 10.43 17.87 10.43 18.14 10.15C18.41 9.87003 18.42 9.42003 18.14 9.15003L12.48 3.49003C12.42 3.42003 12.34 3.37003 12.25 3.34003C12.16 3.30003 12.07 3.28003 11.98 3.28003C11.89 3.28003 11.79 3.30003 11.71 3.34003C11.62 3.38003 11.55 3.43003 11.48 3.49003L5.81999 9.15003C5.53999 9.43003 5.53999 9.88003 5.81999 10.15L5.83999 10.16Z" fill="currentColor" class="stroked"></path><path d="M5.83999 10.16C6.11999 10.44 6.56999 10.44 6.83999 10.16L11.28 5.72003V20.01C11.28 20.4 11.6 20.72 11.99 20.72C12.38 20.72 12.7 20.4 12.7 20.01V5.71003L17.14 10.15C17.42 10.43 17.87 10.43 18.14 10.15C18.41 9.87003 18.42 9.42003 18.14 9.15003L12.48 3.49003C12.42 3.42003 12.34 3.37003 12.25 3.34003C12.16 3.30003 12.07 3.28003 11.98 3.28003C11.89 3.28003 11.79 3.30003 11.71 3.34003C11.62 3.38003 11.55 3.43003 11.48 3.49003L5.81999 9.15003C5.53999 9.43003 5.53999 9.88003 5.81999 10.15L5.83999 10.16Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
