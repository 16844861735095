// class definition
import { html, LitElement, nothing, PropertyValues, TemplateResult } from '@horizon/base';
import { property, query, state } from '@horizon/base/decorators';
import { classMap, ifDefined, live } from '@horizon/base/directives';
import { HasSlotController } from '@horizon/common/controllers';
import { eventEmitter } from '@horizon/common/events';
import { requiredValidator } from '@horizon/common/mixins';
import { FormControlMixin, Validator } from '@open-wc/form-control';
import { submit } from '@open-wc/form-helpers';
import { ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import 'element-internals-polyfill';

import { HznDivider } from '@horizon/divider';
import { HznIconChevronDown, HznIconClose } from '@horizon/icons/individual';
import { HznIconButton } from '@horizon/icon-button';
import { HznInline } from '@horizon/inline';
import { HznStack } from '@horizon/stack';
import { HznText } from '@horizon/text';

import SelectStyles from './select.css.js';

type HznSelectChildElements = Array<HTMLOptionElement | HTMLOptGroupElement | HTMLHRElement>;

/**
 *
 * @tag hzn-select
 * @tagname hzn-select
 * @summary Define a select element for choosing a single option in a form
 *
 * @fires {HznSelectInputEvent} input - Emitted every time a an option is navigated to
 * @fires {HznSelectChangeEvent} change - Emitted when the select value is changed
 * @fires {HznSelectClearEvent} clear - Emitted when the select is clearable and the clear button is clicked
 */

export class HznSelect extends FormControlMixin(ScopedElementsMixin(LitElement) as typeof LitElement) {
  /**
   * @private
   */
  #emit = eventEmitter(this);

  /**
   * @private
   * the internal value property
   */
  _value = '';

  static styles = [SelectStyles];

  static get scopedElements() {
    return {
      'hzn-stack': HznStack,
      'hzn-text': HznText,
      'hzn-inline': HznInline,
      'hzn-icon-button': HznIconButton,
      'hzn-icon-chevron-down': HznIconChevronDown,
      'hzn-icon-close': HznIconClose,
      'hzn-divider': HznDivider,
    };
  }

  static get formControlValidators(): Validator[] {
    return [
      requiredValidator,
      {
        message(instance: HznSelect) {
          if (instance.validation && instance.validation instanceof Function) {
            return instance.validation(instance).message;
          }
          return '';
        },

        isValid(instance: HznSelect) {
          if (instance.validation && instance.validation instanceof Function) {
            return instance.validation(instance).valid;
          }
          return true;
        },
      },
    ];
  }

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'options');

  // INTERNAL ACCESS PROPS
  /**
   * The internal native select element
   */
  @query('select') innerSelect!: HTMLSelectElement;

  /**
   * The internal native button element that clears the select
   */
  get innerClearButton() {
    return this.shadowRoot?.querySelector<HznIconButton>('.select-clear-button')?.innerButton || null;
  }

  /**
   * @private
   */
  options: (HTMLOptionElement | HTMLOptGroupElement | HTMLHRElement)[] = [];

  /**
   * @private
   */
  @state() internalErrorMessage!: string;

  /**
   * @private
   * The native select inside the host element
   */
  @query('select') validationTarget!: HTMLSelectElement;

  /**
   * Whether or not the select is compact size
   */
  @property({ type: Boolean }) compact?: boolean = false;

  /**
   * Whether or not the select's label is hidden
   */
  @property({ type: Boolean, attribute: 'hide-label' }) hideLabel?: boolean = false;

  /**
   * Adds a clear button when the select is populated
   */
  @property({ type: Boolean }) clearable?: boolean = false;

  /**
   * The select's placeholder text
   */
  @property() placeholder?: string;

  /**
   * Set a display name on the input - used in error message customization
   */
  @property({ type: String, attribute: 'display-name' }) displayName?: string;

  /**
   * Overwrite standard scenario error messages with a custom one
   */
  @property({ attribute: false }) errorMessages?: { valueMissing: string | ((instance: HznSelect) => string) };

  /**
   * Set an error state and message on the input
   */
  @property({ type: String, attribute: false }) validation?: (instance: HznSelect, value?: string) => { valid: boolean; message: string };

  /**
   * Sets the input name
   */
  @property() name?: string;

  /**
   * Sets the input value
   */
  @property({ type: String }) value = '';

  /**
   * Set whether or not the input is required to have a value
   */
  @property({ type: Boolean }) required?: boolean = false;

  /**
   * Set the input to be disabled
   */
  @property({ type: Boolean, reflect: true }) disabled?: boolean = false;

  /**
   * Set the help text for the input
   */
  @property({ type: String, attribute: 'helper-text' }) helperText?: string = '';

  /**
   * Hides the help text for the select
   */
  @property({ type: Boolean, attribute: 'hide-helper-text' }) hideHelperText = false;

  /**
   * The select's autocomplete attribute
   */
  @property() autocomplete!: string;

  /**
   * The select's autofocus attribute
   */
  @property({ type: Boolean }) autofocus = false;

  /**
   * Programmatically clear the select
   */
  clear() {
    if (this.innerClearButton) {
      this.innerClearButton.click();
    }
  }

  // Specify that select should always update, even if the value is empty
  /**
   * @private
   */
  shouldUpdate() {
    return true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('invalid', this.#onInvalid);
  }

  /**
   * @private
   */
  #placeholder(): HTMLOptionElement {
    const hasPlaceholderValue = typeof this.placeholder === 'string' && this.placeholder !== '' && this.placeholder !== 'true';
    const placeholderOption = document.createElement('option');
    placeholderOption.value = '';
    placeholderOption.disabled = true;
    placeholderOption.selected = true;
    placeholderOption.textContent = hasPlaceholderValue ? this.placeholder as string : 'Select one of the following options';

    return placeholderOption;
  }

  /**
   * @private
   */
  #handleDefaultSlotChange(): void {
    // set options object from querying light dom child options
    const childElementsQuery = this.querySelectorAll<HTMLOptionElement | HTMLOptGroupElement | HTMLHRElement>(':scope > :is(option, optgroup, hr)');

    // add option slot property to all light dom children
    Array.from(childElementsQuery).map(el => { el.slot = 'options'; return el; });
  }

  /**
   * @private
   */
  #handleOptionsSlotChange(): void {
    // set options object from querying light dom child options
    const childElementsQuery = this.querySelectorAll<HTMLOptionElement | HTMLOptGroupElement | HTMLHRElement>(':scope > :is(option, optgroup, hr)');


    // add option slot property to all light dom children regardless of what else happens
    // const childElements = Array.from(childElementsQuery).map(el => { el.slot = 'options'; return el; });
    const childElements = Array.from(childElementsQuery);


    // if placeholder is defined, even as empty string
    // add a placeholder option before the others
    if(this.placeholder !== undefined) {
      childElements.unshift(this.#placeholder());
    }

    // filter out everything but options
    const childOptions = childElements.filter(el => el instanceof HTMLOptionElement) as HTMLOptionElement[];

    // find selected option if any
    // excludes the added placeholder option if it is present
    const selectedSlottedOption = childOptions.find((el, index) => {
      if(this.placeholder !== undefined) {
        // if placeholder is present, skip the first option when checked for selected
        return index !== 0 && el instanceof HTMLOptionElement && el.selected === true;
      }
      return el instanceof HTMLOptionElement && el.selected === true;
    });

    // for setting to this.options
    const clonedElements = childElements.map((el, index) => this.placeholder !== undefined && index === 0 ? el : el.cloneNode(true)) as HznSelectChildElements;

    // CASE SELECTED SLOTTED OPTION
    // selected slotted option WINS over a provided hzn-select value
    // so the value of hzn-select is ignored and reset to the value
    // of the selected slotted option
    if(selectedSlottedOption) {
      // there is a selected option
      // selected option wins over a provided default value
      // so just set options and
      // set the value from the first options value and return
      this.options = clonedElements;
      this.value = selectedSlottedOption.value;
      return;
    }

    // CASE: NO SLOTTED SELECTED OPTION AND NO PROVIDED VALUE
    // if placeholder is present, then DONT set the value to any of the slotted options
    // if placeholder is NOT present, set the value to the first options value
    if(!this.value) {
      // clone all the child elements
      this.options = clonedElements;

      const firstOption = childOptions[0];
      if(this.placeholder === undefined) {
        // the firstOption WONT be the placeholder option because placeholder is undefined
        this.value = firstOption ? firstOption.value : '';
      }

      return;
    }

    // CASE: INCOMING VALUE
    // there IS a value on hzn-select already set, so we just set the first option
    // that has a matching value to be selected
    const optionMatchingValue = (clonedElements.find((el) => {
      return el instanceof HTMLOptionElement && el.value === this.value;
    }) as HTMLOptionElement);

    // if there is one, set selected to true on it
    if(optionMatchingValue) {
      optionMatchingValue.selected = true;
    }

    // then set the options to the whole list including placeholder
    this.options = clonedElements;

    // if we get this far, we need to update, because we haven't set the value
    // but we've changed the options list
    this.requestUpdate();
  }

  /**
   * @private
   */
  resetFormControl(): void {
    this.value = '';
  }

  /**
   * @private
   */
  #onInvalid(event: Event): void {
    event.preventDefault();
    this.validationTarget?.focus();
  }

  /**
   * @private
   */
  #onKeydown(event: KeyboardEvent) {
    const hasModifier = event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    // Pressing enter when focused on an input should submit the form like a native input
    if (event.code === 'Enter' && !hasModifier) {
      if (this.form) {
        submit(this.form);
      }
    }
  }

  /**
   * @private
   */
  get showErrors() {
    return this.showError && !this.disabled;
  }

  /**
   * @private
   */
  validationMessageCallback(message: string): void {
    this.internalErrorMessage = message;
  }

  /**
   * @private
   */
  protected updated(changed: PropertyValues<this>): void {
    if(changed.size === 1 && changed.has('internalErrorMessage')) {
      // dont set the value again if the only entry in the changed map
      // is the internal error message, or the error message will get wiped
      // out by the validationMessageBackback race
      return;
    } else {
      this.setValue(this.value);
    }
  }

  /** Sets focus on the input. */
  focus(options?: FocusOptions) {
    this.validationTarget.focus(options);
  }

  /** Removes focus from the input. */
  blur() {
    this.validationTarget.blur();
  }

  /**
   * @private
   */
  #handleInput(event: Event) {
    this.value = (event.target as HTMLSelectElement).value;
  }

  /**
   * @private
   */
  #handleChange(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.value = (event.target as HTMLSelectElement).value;
    this.#emit({ type: 'change' });
  }

  /**
   * @private
   */
  #handleClear() {
    this.value = '';
    this.focus();
    this.#emit({ type: 'clear' });
  }

  /**
   * @private
   */
  get #showClearable() {
    // only show the clear button if clearable, with a value
    // and not disabled
    return this.clearable && this.value && !this.disabled;
  }


  render(): TemplateResult {

    return html`<hzn-stack space="xsmall">
        <label
          for="select"
          class=${classMap({
           'visually-hidden': Boolean(this.hideLabel)
          })}
        >
          ${this.hasSlotController.test('[default]')
            ? html`<hzn-text variant="text"><slot @slotchange=${this.#handleDefaultSlotChange}></slot></hzn-text>`
            : html`<hzn-text variant="text">${this.displayName || this.name || 'Select Input'}</hzn-text>`}
        </label>
        <div class=${classMap({
            'is-compact': Boolean(this.compact),
            'is-disabled': Boolean(this.disabled),
            'has-error': this.showErrors,
            'select-input-container': true,
            'is-clearable-with-value': Boolean(this.#showClearable)
          })}
        >
          <select
            title=""
            id="select"
            class=${classMap({
              'has-placeholder': this.placeholder !== undefined && !this.value,
            })}
            name=${ifDefined(this.name)}
            ?disabled=${this.disabled}
            ?required=${this.required}
            .value=${live(this.value)}
            autocomplete=${ifDefined(this.autocomplete)}
            ?autofocus=${this.autofocus}
            aria-describedby="helper-text"
            aria-invalid="${this.showErrors ? 'true' : 'false'}"
            @input="${this.#handleInput}"
            @change="${this.#handleChange}"
            @keydown="${this.#onKeydown}"
          >
            ${this.options}
          </select>
          <div class="select-icon-container">
            ${this.#showClearable
              ? html`
                  <hzn-icon-button
                    class="select-clear-button is-icon-button"
                    input-button
                    label="Clear input value"
                    @click="${this.#handleClear}"
                    ?disabled="${this.disabled}"
                    tabindex="-1"
                  >
                    <hzn-icon-close role="presentation"></hzn-icon-close>
                  </hzn-icon-button>
                  <hzn-divider tone="subdued" vertical class="select-icon-divider"></hzn-divider>`
                : nothing}
            <span class="select-caret-icon" role="presentation">
              <hzn-icon-chevron-down></hzn-icon-chevron-down>
            </span>
          </div>
        </div>
        ${this.helperText
        ? html`<hzn-text
              class=${classMap({
                'visually-hidden': Boolean(this.hideHelperText)
              })}
              id="helper-text"
              variant="caption"
              tone="subdued"
            >
              ${this.helperText}
            </hzn-text>`
        : nothing}
        ${this.showErrors
        ? html`<hzn-text role="alert" id="error-message" class="error-message" variant="caption" tone="critical"
              >${this.internalErrorMessage}</hzn-text
            >`
        : nothing}
      </hzn-stack>
      <slot @slotchange=${this.#handleOptionsSlotChange} name="options" hidden></slot>`;
  }
}
