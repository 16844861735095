import { FlexRow } from '@src/components/Flex';
import { CardReaderBadge } from '@src/components/Hardware/CardReaderBadge';
import { CashDrawerBadge } from '@src/components/Hardware/CashDrawerBadge';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
const Header = ({ basketDetails }) => {
    const { storeLocationId } = useParams();
    const navigate = useNavigate();
    return (React.createElement(FlexRow, { className: "w-100 align-items-center gap-2" },
        React.createElement("hzn-text", { weight: "bold", as: "div" },
            React.createElement("hzn-text-link", { weight: "base", onClick: e => {
                    e.preventDefault();
                    navigate(`/checkout/${storeLocationId}`);
                } }, "All Carts"),
            ' ',
            "/ ",
            basketDetails.basketOwnerName,
            "'s Checkout"),
        React.createElement("div", { className: "ms-auto" },
            React.createElement(CashDrawerBadge, null)),
        React.createElement("div", null,
            React.createElement(CardReaderBadge, null))));
};
export default Header;
