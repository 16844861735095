// class definition
import { LitElement, TemplateResult } from '@horizon/base';
import { unsafeStatic, html } from '@horizon/base/static-html.js';
import { property } from '@horizon/base/decorators.js';
import { classMap } from '@horizon/base/directives.js';

import TextStyles from './text.css.js';
import {
  BlockElements,
  HznTextAlign,
  HznTextAs,
  HznTextLineHeight,
  HznTextOn,
  HznTextSize,
  HznTextTone,
  HznTextVariant,
  HznTextWeight,
  InlineElements
} from '../types.js';

/**
 *
 * @tag hzn-text
 * @tagname hzn-text
 * @summary A text component for adding and styling text
 */
export class HznText extends LitElement {
  static styles = [TextStyles];

  /**
   * @Private
   */
  get #isBlockStyle() {
    return [...BlockElements].includes(this.as as typeof BlockElements[number]) && !this.inline;
  }

  /**
   * Sets whether the hzn-text host element should be `display: inline`. Defaults to `false`
   */
  @property({ type: Boolean, reflect: true }) inline?: boolean = false;

  /**
   * Sets the variant/style of the text to be displayed
   * @playroomValues {'text' | 'caption' | 'callout' | 'eyebrow'}
   */
  @property({ type: String }) variant?: HznTextVariant = 'text';

  /**
   * Sets the font size of text within element
   * @playroomValues {'small' | 'base' | 'large'}
   */
  @property({ type: String }) size?: HznTextSize = 'base';

  /**
   * Sets the color of text within element
   * @playroomValues {'neutral' | 'body' | 'brand' | 'subdued' | 'critical' | 'caution' | 'positive' | 'info' | 'flagged' | 'interactive' | 'disabled' | 'inverse'}
   */
  @property({ type: String }) tone?: HznTextTone = 'body';

  /**
   * Sets the font weight of text within element
   * @playroomValues {'base' | 'bold'}
   */
  @property({ type: String }) weight?: HznTextWeight = 'base';

  /**
   * Sets the text-align of text along the x-axis
   * @playroomValues {'start' | 'end' | 'left' | 'center' | 'right'}
   */
  @property({ type: String }) align?: HznTextAlign = 'start';

  /**
   * Sets the tag of the shadow element
   * @playroomValues {'span' | 'div' | 'sub' | 'sup' | 'p' | 'li'}
   */
  @property({ type: String }) as?: HznTextAs = 'p';

  /**
   * Sets the line height of text within element
   * @playroomValues {'none' | 'xsmall' | 'small' | 'base' | 'large' | 'xlarge'}
   */
  @property({ type: String, attribute: 'line-height' }) lineHeight?: HznTextLineHeight = 'base';

  /**
   * Truncates the text (This is...)
   */
  @property({ type: Boolean }) truncate?: boolean = false;

  /**
   * Sets the background color that this text is appearing on top of in order to change its color accordingly
   * @playroomValues {'brand' | 'brand-accent'}
   */
  @property({ type: String }) on?: HznTextOn;

  /*
   * When defined, sets font-size to not be fluid (css clamp) and not change size fluidly oacross responsive breakpoints
   */
  @property({ type: Boolean }) static: boolean = false;

  render(): TemplateResult {
    const classes = {
      'text': true,
      'capsize': this.#isBlockStyle,
      'is-static': Boolean(this.static),
      'is-align-center': Boolean(this.align === 'center'),
      'is-align-end': Boolean(this.align === 'end'),
      'is-align-left': Boolean(this.align === 'left'),
      'is-align-right': Boolean(this.align === 'right'),
      'is-align-start': Boolean(this.align === 'start'),
      'is-inline': Boolean(this.inline),
      'is-line-height-base': Boolean(!this.inline && this.lineHeight === 'base'),
      'is-line-height-large': Boolean(!this.inline && this.lineHeight === 'large'),
      'is-line-height-none': Boolean(!this.inline && this.lineHeight === 'none'),
      'is-line-height-small': Boolean(!this.inline && this.lineHeight === 'small'),
      'is-line-height-xlarge': Boolean(!this.inline && this.lineHeight === 'xlarge'),
      'is-line-height-xsmall': Boolean(!this.inline && this.lineHeight === 'xsmall'),
      'is-on-brand-accent': Boolean(this.on === 'brand-accent'),
      'is-on-brand': Boolean(this.on === 'brand'),
      'is-size-base': Boolean(this.size === 'base'),
      'is-size-large': Boolean(this.size === 'large'),
      'is-size-small': Boolean(this.size === 'small'),
      'is-tone-body': Boolean(this.tone === 'body'),
      'is-tone-neutral': Boolean(this.tone === 'neutral'),
      'is-tone-brand': Boolean(this.tone === 'brand'),
      'is-tone-caution': Boolean(this.tone === 'caution'),
      'is-tone-critical': Boolean(this.tone === 'critical'),
      'is-tone-disabled': Boolean(this.tone === 'disabled'),
      'is-tone-flagged': Boolean(this.tone === 'flagged'),
      'is-tone-info': Boolean(this.tone === 'info'),
      'is-tone-interactive': Boolean(this.tone === 'interactive'),
      'is-tone-inverse': Boolean(this.tone === 'inverse'),
      'is-tone-positive': Boolean(this.tone === 'positive'),
      'is-tone-subdued': Boolean(this.tone === 'subdued'),
      'is-truncated': Boolean(this.truncate),
      'is-variant-callout': Boolean(this.variant === 'callout'),
      'is-variant-caption': Boolean(this.variant === 'caption'),
      'is-variant-eyebrow': Boolean(this.variant === 'eyebrow'),
      'is-variant-text': Boolean(this.variant === 'text'),
      'is-weight-base': Boolean(this.weight === 'base'),
      'is-weight-bold': Boolean(this.weight === 'bold'),
    };

    const isValidAs = this.as ? [...InlineElements, ...BlockElements].includes(this.as) : false;

    // DONT FORMAT to add new lines. This component can be display:inline and the whitespace in it will be included.
    return html`<${unsafeStatic(this.as && isValidAs ? this.as : 'p')} class="${classMap(classes)}"><slot></slot></${unsafeStatic(this.as && isValidAs ? this.as : 'p')}>`;
  }
}
