import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { HorizonButton } from './horizon/HorizonButton';
/**
 * A generic modal with title, message, and buttons to retry / cancel.
 */
export const GenericModal = (props) => {
    return (React.createElement(Modal, { show: props.showModal, centered: true, "aria-label": props.title },
        React.createElement(Modal.Header, { className: "horizon-modal" },
            React.createElement("hzn-text", { size: "large", tone: props.tone }, props.title)),
        React.createElement(Modal.Body, { className: "horizon-modal" },
            React.createElement("div", { className: "d-flex flex-column gap-4 p-2" },
                React.createElement("hzn-text", { size: "base", tone: "body" }, props.message),
                React.createElement("hzn-stack", { space: "medium" },
                    (props.alertMessage || props.alertHeading) && (React.createElement("hzn-alert", { show: true, role: "status", "aria-label": props.alertMessage, tone: props.tone, heading: props.alertHeading, "sub-heading": props.alertMessage })),
                    props.footerText &&
                        React.createElement("hzn-text", { size: "small", tone: "subdued" }, props.footerText)))),
        React.createElement(Modal.Footer, { className: "horizon-modal" },
            props.actionButtonTitle && props.action && (React.createElement(HorizonButton, { compact: true, variant: "cta", className: "w-100", onClick: props.action }, props.actionButtonTitle)),
            props.showCancel && React.createElement(HorizonButton, { compact: true, variant: "secondary", className: "w-100", onClick: props.cancel }, "Cancel"))));
};
