import { DrawerStatus, OpenDrawerStatus, useHardwareCashDrawerGetStatusQuery, useHardwareCashDrawerOpenMutation, } from '@src/api/CashDrawerApi';
import { FlexColumn } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { CashPaymentDescription } from './CashPaymentDescription';
/**
 * TenderCashPayment orchestrates and observes cash drawer activity when the BOA needs to enter cash and make change.
 * If the cash drawer is not functioning properly BOAs will be prompted to use a different terminal or open the drawer with a key.
 */
export const TenderCashPayment = (props) => {
    const { payment, paymentDetails } = useAppSelector(selectPayment);
    const [openCashDrawer, openCashDrawerResult] = useHardwareCashDrawerOpenMutation();
    const cashDrawerStatus = useHardwareCashDrawerGetStatusQuery('', {
        pollingInterval: 500,
        skip: !openCashDrawerResult.isSuccess,
    });
    useEffect(() => {
        // drawer was opened and subsequently closed
        if (!cashDrawerStatus.isFetching &&
            cashDrawerStatus.isSuccess &&
            cashDrawerStatus.data === DrawerStatus.Closed) {
            props.OnContinue();
        }
        else {
            // no op
        }
    }, [cashDrawerStatus]);
    const cashPaymentDetails = paymentDetails;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal.Header, null,
            React.createElement("hzn-heading", { size: "xsmall" }, "Processing payment...")),
        React.createElement(Modal.Body, null,
            React.createElement(FlexColumn, null,
                React.createElement("hzn-text", null, "The cash drawer will open when you tender this payment."),
                React.createElement(CashPaymentDescription, null),
                React.createElement(HorizonAlert, { show: openCashDrawerResult.isSuccess && [OpenDrawerStatus.Opened, OpenDrawerStatus.AlreadyOpened].includes(openCashDrawerResult.data), heading: "Close the cash drawer to finish processing payment", tone: "info" }),
                React.createElement(HorizonAlert, { show: openCashDrawerResult.isError || (openCashDrawerResult.isSuccess && [OpenDrawerStatus.Error, OpenDrawerStatus.Offline].includes(openCashDrawerResult.data)), heading: "Cash drawer not responding", tone: "caution", "sub-heading": "Try tendering the payment again. If the issue is not resolved, try processing this payment\r\n                            again from a different computer or partner with an MOD to manually open the drawer using the\r\n                            cash register key.", actionText: "I opened the drawer with the cash register key.", onAction: () => props.OnContinue() }))),
        (!openCashDrawerResult.isSuccess || [OpenDrawerStatus.Error, OpenDrawerStatus.Offline].includes(openCashDrawerResult.data)) && (React.createElement(Modal.Footer, null,
            React.createElement(FlexColumn, { className: "w-100" },
                React.createElement(HorizonButton, { variant: "primary", loading: openCashDrawerResult.isLoading, onClick: () => openCashDrawer('') }, "Tender Payment"),
                React.createElement(HorizonButton, { variant: "tertiary", disabled: openCashDrawerResult.isLoading, onClick: () => props.onCancel() }, "Cancel"))))));
};
