import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    toasts: []
};
export const ToastSlice = createSlice({
    name: 'toasts',
    initialState,
    reducers: {
        addToast: (state, action) => {
            state.toasts.push({ id: crypto.randomUUID(), ...action.payload });
        },
        removeToast: (state, action) => {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
        }
    },
});
export const { addToast, removeToast } = ToastSlice.actions;
export const selectToasts = (state) => state.toast.toasts;
export default ToastSlice.reducer;
