import React from 'react';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { reset, selectPayment, setPaymentDetails, setProcessStep } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ConfirmCashAmount } from './ConfirmCashAmount';
import { TenderCashPayment } from './TenderCashPayment';
import { ProcessPayment } from '../Common/ProcessPayment';
import { CashPaymentDescription } from './CashPaymentDescription';
/**
 * ProcessCashModal is a modal wizard for processing cash payments.
 * This component provides the modal shell, presents the modal content for
 *   each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessCashModal = ({ show }) => {
    const dispatch = useAppDispatch();
    const { step } = useAppSelector(selectPayment);
    return (React.createElement(Modal, { centered: true, show: show },
        show && step === 'Start' && (
        // Confirm the amount of cash recieved by the customer
        React.createElement(ConfirmCashAmount, { OnContinue: amt => {
                dispatch(setPaymentDetails({ cashCollected: amt }));
                dispatch(setProcessStep('Tender'));
            }, onCancel: () => dispatch(reset()) })),
        show && step === 'Tender' && (
        // Pop the drawer to deposit the cash and make change
        React.createElement(TenderCashPayment, { OnContinue: () => dispatch(setProcessStep('Process')), onCancel: () => dispatch(reset()) })),
        show && step === 'Process' && (
        // Submit the payment for processing
        React.createElement(ProcessPayment, { onComplete: () => dispatch(reset()), onCancel: () => dispatch(reset()) },
            React.createElement(CashPaymentDescription, null)))));
};
