import React from 'react';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { reset, selectPayment, setPaymentDetails, setProcessStep } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ReceiveCardData } from './RecieveCardData';
import { ProcessPayment } from '../Common/ProcessPayment';
import { DebitPaymentDescription } from './DebitPaymentDescription';
/**
 * ProcessDebitModal is a modal wizard for processing debit card payments.
 * This component provides the modal shell, presents the modal content for
 *   each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessDebitModal = ({ show }) => {
    const dispatch = useAppDispatch();
    const { step } = useAppSelector(selectPayment);
    return (React.createElement(Modal, { centered: true, show: show },
        show && step === 'Start' && (
        // Initiate a card swipe and handle the resulting swipe data or error
        React.createElement(ReceiveCardData, { onReceived: data => {
                dispatch(setPaymentDetails(data));
                dispatch(setProcessStep('Process'));
            }, onCancel: () => dispatch(reset()) })),
        show && step === 'Process' && (
        // Submit the payment for processing
        React.createElement(ProcessPayment, { onComplete: () => dispatch(reset()), onCancel: () => dispatch(reset()) },
            React.createElement(DebitPaymentDescription, null)))));
};
