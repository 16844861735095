import React from 'react';
import { logger } from './common/logger';
import { OAuthErrorModal } from './components/OAuthErrorModal';
import OAuthCallback from './containers/OAuthCallback';
import { canAccessApp } from './helpers/AuthzCheck';
import { FlexRow } from './components/Flex';
import { authorizationUrl } from './helpers/OAuthHelpers';
import { AppRoutes, GatewayRoutes } from './Routes';
import { guid } from './helpers/GenerationHelpers';
export const Host = () => {
    const [error, setError] = React.useState();
    const modalAction = () => {
        setError(undefined);
        if (error?.action) {
            error.action();
        }
    };
    // If there's an error, show it.
    if (error)
        return React.createElement(OAuthErrorModal, { error: error, showModal: true });
    // If this is an Electron launch request, process it.
    if (window.location.pathname.toLowerCase().startsWith('/gateway')) {
        console.log("Redirecting to Electron launcher gateway.");
        return React.createElement(GatewayRoutes, null);
    }
    // Resolve OAuth query parameters, if any. If this is an OAuth redirect, process it.
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('code'))
        return React.createElement(OAuthCallback, { onErrorChange: setError });
    // Resolve existing user claims, if any.
    const claims = JSON.parse(sessionStorage.getItem('com.unboundid.kmxpos.profile'));
    // If the user does not have claims in session storage, redirect them to SSO login.
    if (!claims) {
        console.log('Redirecting to SSO Login.');
        const nonce = guid();
        sessionStorage.setItem('com.unboundid.kmxpos.nonce', nonce);
        window.location.replace(authorizationUrl(nonce).toString());
        return (React.createElement(FlexRow, { className: "sso-loader pt-5 justify-content-center" },
            React.createElement("hzn-loading", { size: "medium" })));
    }
    // If the user does have claims but they don't role-based access to Checkout, show a message.
    if (!canAccessApp(claims)) {
        console.log("You have claims but aren't authorized to access this application.");
        return React.createElement("p", null, "You do not have access to this application");
    }
    // The user has claims and can access the app.
    console.log('You have claims and are authorized to access the application.');
    logger.setUserContext(claims);
    return React.createElement(AppRoutes, { claims: claims });
};
