import { HznBool } from '@src/utils/Horizon';
import React, { useRef } from 'react';
import { useEventListener } from '@src/hooks/useEventListener';
const Noop = () => { };
export const HorizonAlert = (props) => {
    const ref = useRef(undefined);
    //@ts-ignore dismiss is a synthetic event emitted by hzn-alert
    useEventListener(ref, 'dismiss', props.onDismiss ?? Noop);
    //@ts-ignore action is a synthetic event emitted by hzn-alert
    useEventListener(ref, 'action', props.onAction ?? Noop);
    return (React.createElement("hzn-alert", { ref: ref, role: "status", "aria-label": props.heading, show: HznBool(props.show), tone: props.tone, heading: props.heading, "sub-heading": props['sub-heading'], "action-text": props.actionText, elevated: HznBool(props.elevated ?? false), dismissible: HznBool(!!props.onDismiss) }));
};
