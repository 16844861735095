import React from 'react';
/**
 *
 * @param ref A reference to an HTMLElement
 * @param event The event to which you want to subscribe (e.g. 'click', 'change').
 * @param callback The callback function to invoke when the subscribed event fires. The first argument `target` will be
 * will be the event target cast to type of your HTMLElement `T`. The second argument is the original event metadata.
 * @param description If provided, this hook will console log when the event is subscribed and unsubscribed.
 */
export const useEventListener = (ref, event, callback, description) => {
    React.useEffect(() => {
        // onEvent will receive the event object, cast its target as a T, and pass both objects to our callback.  
        const onEvent = (ev) => callback(ev.target, ev);
        if (description) {
            console.log('add event listener', event, description, new Date().toISOString());
        }
        ref.current?.addEventListener(event, onEvent);
        return () => {
            if (description) {
                console.log('remove event listener', event, description, new Date().toISOString());
            }
            ref.current?.removeEventListener(event, onEvent);
        };
    }, [ref, callback]);
};
