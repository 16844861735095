// class definition
import { html, LitElement, nothing, TemplateResult } from '@horizon/base';
import { property, state } from '@horizon/base/decorators';
import { classMap, ifDefined, live } from '@horizon/base/directives';
import { HasSlotController } from '@horizon/common/controllers';
import { TextInputMixin } from '@horizon/common/mixins';
import { ScopedElementsMap, ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import { submit } from '@open-wc/form-helpers';
import { eventEmitter } from '@horizon/common/events';
import { HznDivider } from '@horizon/divider';
import { HznIconClose, HznIconHide, HznIconShow } from '@horizon/icons/individual';
import { HznIconButton } from '@horizon/icon-button';
import { HznInline } from '@horizon/inline';
import { HznStack } from '@horizon/stack';
import { HznText } from '@horizon/text';

import InputStyles from './input.css.js';

import {
  HznInputAutoCapitalize,
  HznInputEnterKeyHint,
  HznInputInputMode,
  HznInputSpellcheck,
  HznInputType
} from '../types.js';


export const renderIconSlot = Symbol('_renderIconSlot');
export const hasNoSlottedIcon = Symbol('_hasNoSlottedIcon');
export const handleClear = Symbol('_handleClear');
export const bindEvents = Symbol('_bindEvents');

/**
 *
 * @tag hzn-input
 * @tagname hzn-input
 * @summary A text input component that mirrors a native input
 *
 * @fires {HznInputInputEvent} input - Emitted every time a character is typed into the input
 * @fires {HznInputChangeEvent} change - Emitted when the input is changed
 * @fires {HznInputClearEvent} clear - Emitted when the input is clearable and the clear button is clicked
 */
export class HznInput extends TextInputMixin(ScopedElementsMixin(LitElement) as typeof LitElement) {
  /**
   * @private
   */
  #emit = eventEmitter(this);

  /**
   * @private
   */
  #sensitive = false;

  static styles = [InputStyles];

  static get scopedElements(): ScopedElementsMap {
    return {
      'hzn-stack': HznStack,
      'hzn-text': HznText,
      'hzn-inline': HznInline,
      'hzn-icon-button': HznIconButton,
      'hzn-icon-close': HznIconClose,
      'hzn-icon-show': HznIconShow,
      'hzn-icon-hide': HznIconHide,
      'hzn-divider': HznDivider,
    };
  }

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'trailing-icon');

  @state() private sensitiveHidden = false;

  // INTERNAL ACCESS PROPERTIES
  /**
   * The internal native button element that clears the input
   */
  get innerClearButton() {
    return this.shadowRoot?.querySelector<HznIconButton>('.input-clear-button')?.innerButton || null;
  }

  /**
   * Set the native input type
   * @playroomValues {'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'date'}
   */
  @property({ type: String }) type?: HznInputType = 'text';

  /**
   * Whether or not the input is compact size
   */
  @property({ type: Boolean }) compact?: boolean = false;

  /**
   * Whether or not the input label is visually hidden
   */
  @property({ type: Boolean, attribute: 'hide-label' }) hideLabel?: boolean = false;

  /**
   * Adds a clear button when the input is populated
   */
  @property({ type: Boolean }) clearable?: boolean = false;

  /**
   * Adds a clickable show/hide icon button that hides or reveals the input contents
   */
  @property({ type: Boolean})
  get sensitive(): boolean {
    return this.#sensitive;
  }
  set sensitive(newValue: boolean) {
    this.#sensitive = newValue;
    this.sensitiveHidden = newValue;
  }

  /**
   * The input's placeholder text
   */
  @property() placeholder?: string;

  /**
   * The input's autocapitalize attribute
   * @playroomValues {'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters'}
   */
  @property() autocapitalize!: HznInputAutoCapitalize;

  /**
   * The input's autocorrect attribute
   */
  @property() autocorrect!: string;

  /**
   * The input's autocomplete attribute
   */
  @property() autocomplete!: string;

  /**
   * The input's autofocus attribute
   */
  @property({ type: Boolean }) autofocus = false;

  /**
   * The input's enterkeyhint attribute. This can be used to customize the label or icon of the Enter key on virtual
   * keyboards.
   * @playroomValues {'enter' | 'done' | 'go' | 'next' | 'previous' | 'search' | 'send'}
   */
  @property() enterkeyhint!: HznInputEnterKeyHint;

  /**
   * Enables spell checking on the input
   * @playroomValues {'true' | 'false'}
   */
  @property({ type: String, attribute: 'spellcheck' }) spellCheck?: HznInputSpellcheck;

  /**
   * The input's inputmode attribute
   * @playroomValues {'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url'}
   */
  @property() inputmode?: HznInputInputMode;

  /**
   * Programmatically clear the input
   */
  clear() {
    if (this.innerClearButton) {
      this.innerClearButton.click();
    }
  }

  connectedCallback() {
    super.connectedCallback();
    // automatically hide the input value if sensitive is set by default

    if (this.type === 'password') {
      this.sensitive = true;
    }

    if (this.sensitive) {
      this.sensitiveHidden = true;
    }

    // hide default browser invalid popup
    this.addEventListener('invalid', (event) => event.preventDefault());
  }

  firstUpdated() {
    // bind events with a function so that masked input can bind events after adding the masking library
    this[bindEvents]();
  }

  [bindEvents]() {
    this.validationTarget.addEventListener('input', this.#handleInput.bind(this))
    this.validationTarget.addEventListener('change', this.#handleChange.bind(this))
    this.validationTarget.addEventListener('keydown', this.#onKeydown.bind(this))
  }

  /**
   * @private
   */
  resetFormControl(): void {
    this.value = '';
  }

  /**
   * @private
   */
  #onKeydown(event: KeyboardEvent) {
    const hasModifier = event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    // Pressing enter when focused on an input should submit the form like a native input
    // use event.key here to detect both 'Enter' and 'NumpadEnter' which both have key === 'Enter'
    if (event.key === 'Enter' && !hasModifier) {
      if (this.form) {
        submit(this.form);
      }
    }
  }

  /**
   * @private
   */
  #handleInput(event: Event) {
    // don't stop propagation on the native event, because
    // the inputs that extend from hzn-input may be listening and need it

    // also don't emit a custom event, because the native input event is
    // composed and will escape the shadow DOM
    this.value = (event.target as HTMLInputElement).value;
  }

  /**
   * @private
   */
  #handleChange(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.#emit({ type: 'change' });
  }

  /**
   * @private
   */
  [handleClear]() {
    this.value = '';
    this.focus();
    this.#emit({ type: 'clear' });
  }

  /**
   * @private
   */
  get #showClearable() {
    // only show the clear button if clearable, has a value,
    // and is not disabled nor readonly
    return this.clearable && this.value && !this.disabled && !this.readonly;
  }

  /**
   * @private
   */
  #toggleSensitiveData() {
    this.sensitiveHidden = !this.sensitiveHidden;
  }

  /**
   * @private
   */
  #handleSensitiveType() {
    if (this.type === 'password') {
      return this.sensitive && this.sensitiveHidden ? this.type : 'text';
    }
    return this.sensitive && this.sensitiveHidden ? 'password' : this.type;
  }

  /**
   * @private
   */
  [hasNoSlottedIcon]() {
    return this.sensitive;
  }

  /**
   * @private
   */
  [renderIconSlot]() {
    if (this.sensitive) {
      // if sensitive, never render an icon slot
      return html`
        <hzn-icon-button
          class="is-icon-button input-sensitive-toggle"
          input-button
          tone="interactive"
          label="${this.sensitiveHidden ? 'Show' : 'Hide'} sensitive data"
          @click="${this.#toggleSensitiveData}"
          ?disabled="${this.disabled || this.readonly}"
          tabindex="-1"
        >
          ${this.sensitiveHidden
          ? html`<hzn-icon-show title="Show sensitive data" role="presentation"></hzn-icon-show>`
          : html`<hzn-icon-hide title="Hide sensitive data" role="presentation"></hzn-icon-hide>`}
        </hzn-icon-button>`;
    }

    return this.hasSlotController.test('trailing-icon')
      ? html` <span class="input-trailing-icon" role="presentation">
          <slot name="trailing-icon"></slot>
        </span>`
      : nothing;
  }

  /**
   * @private
   */
  #renderIconContainer() {
    if (this.#showClearable || this[hasNoSlottedIcon]() || this.hasSlotController.test('trailing-icon')) {
      return html`<div class="input-icon-container">
        ${this.#showClearable
          ? html`
              <hzn-icon-button
                class="is-icon-button input-clear-button"
                input-button
                label="Clear input value"
                @click="${this[handleClear]}"
                ?disabled="${this.disabled || this.readonly}"
                tabindex="-1"
              >
                <hzn-icon-close class="clear-icon" role="presentation"></hzn-icon-close>
              </hzn-icon-button>
              ${this[hasNoSlottedIcon]() || this.hasSlotController.test('trailing-icon')
              ? html`<hzn-divider tone="subdued" vertical class="input-divider"></hzn-divider>`
              : nothing}
            `
          : nothing}
        ${this[renderIconSlot]()}
      </div>`;
    }

    return '';
  }

  render(): TemplateResult {
    return html`<hzn-stack space="xsmall">
      <label for="input" class="${this.hideLabel ? 'visually-hidden' : ''}">
        <hzn-text>
          <slot>${this.displayName || this.name || 'Text Input'}</slot>
        </hzn-text>
        ${this.sensitive
        ? html`<span class="visually-hidden"
              >Sensitive data; data in this field can be visually hidden or shown with the toggle button</span
            >`
        : nothing}
      </label>
      <hzn-inline class="${classMap({
          'input-container': true,
          'is-clearable-has-value': Boolean(this.#showClearable),
          'is-compact': Boolean(this.compact),
          'is-disabled': Boolean(this.disabled),
          'is-error': this.showErrors,
          'is-readonly': Boolean(this.readonly),
          'is-sensitive': Boolean(this.sensitive),
        })}" space="xxsmall">
        <input
          title=""
          id="input"
          type=${ifDefined(this.#handleSensitiveType())}
          name=${ifDefined(this.name)}
          ?disabled=${this.disabled}
          ?readonly=${this.readonly}
          ?required=${this.required}
          placeholder=${ifDefined(this.placeholder)}
          minlength=${ifDefined(this.minlength)}
          maxlength=${ifDefined(this.maxlength)}
          min=${ifDefined(this.min)}
          max=${ifDefined(this.max)}
          step=${ifDefined(this.step)}
          .value=${live(this.value)}
          autocapitalize=${ifDefined(this.autocapitalize)}
          autocomplete=${ifDefined(this.autocomplete)}
          autocorrect=${ifDefined(this.autocorrect)}
          ?autofocus=${this.autofocus}
          spellcheck=${ifDefined(this.spellcheck ? this.spellCheck : undefined)}
          pattern=${ifDefined(this.pattern)}
          enterkeyhint=${ifDefined(this.enterkeyhint)}
          inputmode=${ifDefined(this.inputmode)}
          aria-describedby="${ifDefined(this.helperText ? 'helper-text' : undefined)}"
          aria-invalid="${this.showErrors ? 'true' : 'false'}"

        />
        ${this.#renderIconContainer()}
      </hzn-inline>
      ${this.helperText
        ? html` <hzn-text
            class="${this.hideHelperText ? 'visually-hidden' : ''}"
            id="helper-text"
            variant="caption"
            tone="subdued"
            >${this.helperText}</hzn-text
          >`
        : nothing}
      ${this.showErrors
        ? html`<hzn-text role="alert" id="error-message" class="error-message" variant="caption" tone="critical">
            ${this.internalErrorMessage}
          </hzn-text>`
        : nothing}
    </hzn-stack>`;
  }
}
