import classNames from 'classnames';
import React from "react";
import './StepTitleHeader.scss';
// "dumb" component that displays a title header on the left and a status icon on the right
// can be used for both Payment and Order Tender screens
export const StepTitleHeader = (props) => {
    return (React.createElement("div", { className: "stepTitleHeader d-flex flex-row gap-4 justify-content-between pb-4" },
        React.createElement("div", null,
            React.createElement("hzn-heading", { as: "h2", size: "small" }, props.title)),
        React.createElement("div", { className: classNames('status', props.status) })));
};
