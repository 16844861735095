
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-info-circle
 * @tagname hzn-icon-info-circle
 * @summary An individual icon for Horizon
 */
export class HznIconInfoCircle extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.28998 12C1.28998 6.08998 6.08998 1.28998 12 1.28998C17.91 1.28998 22.71 6.08998 22.71 12C22.71 17.91 17.91 22.71 12 22.71C6.08998 22.71 1.28998 17.91 1.28998 12ZM2.70998 12C2.70998 17.12 6.87998 21.29 12 21.29C17.12 21.29 21.29 17.12 21.29 12C21.29 6.87998 17.12 2.70998 12 2.70998C6.87998 2.70998 2.70998 6.87998 2.70998 12ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9ZM14 15.29H12.71V11C12.71 10.61 12.39 10.29 12 10.29H9.99998C9.60998 10.29 9.28998 10.61 9.28998 11C9.28998 11.39 9.60998 11.71 9.99998 11.71H11.29V15.29H9.99998C9.60998 15.29 9.28998 15.61 9.28998 16C9.28998 16.39 9.60998 16.71 9.99998 16.71H14C14.39 16.71 14.71 16.39 14.71 16C14.71 15.61 14.39 15.29 14 15.29Z" fill="currentColor" class="stroked"></path><path d="M12 1.28998C6.08998 1.28998 1.28998 6.08998 1.28998 12C1.28998 17.91 6.08998 22.71 12 22.71C17.91 22.71 22.71 17.91 22.71 12C22.71 6.08998 17.91 1.28998 12 1.28998ZM12 6.99998C12.55 6.99998 13 7.44998 13 7.99998C13 8.54998 12.55 8.99998 12 8.99998C11.45 8.99998 11 8.54998 11 7.99998C11 7.44998 11.45 6.99998 12 6.99998ZM14 16.71H9.99998C9.60998 16.71 9.28998 16.39 9.28998 16C9.28998 15.61 9.60998 15.29 9.99998 15.29H11.29V11.71H9.99998C9.60998 11.71 9.28998 11.39 9.28998 11C9.28998 10.61 9.60998 10.29 9.99998 10.29H12C12.39 10.29 12.71 10.61 12.71 11V15.29H14C14.39 15.29 14.71 15.61 14.71 16C14.71 16.39 14.39 16.71 14 16.71Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
