import React, { useCallback, useRef } from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import { updatePaymentState } from './BasketSearchSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { selectBasketSearchQuery } from './BasketSearchSlice';
import { useAppSelector } from '../../hooks/useAppSelector';
import { PaymentFilterNames } from '@src/utils/Formatting';
export const BasketPaymentStateFilter = () => {
    const dispatch = useAppDispatch();
    const { paymentState } = useAppSelector(selectBasketSearchQuery);
    const ref = useRef(undefined);
    const onUpdatePaymentState = useCallback((select) => {
        const selectedPaymentState = select?.value === '' ? undefined : select?.value;
        dispatch(updatePaymentState(selectedPaymentState));
    }, [dispatch]);
    //@ts-ignore non-standard 'search' event is part of Horizon spec
    useEventListener(ref, 'change', onUpdatePaymentState);
    //@ts-ignore non-standard 'clear' event is part of Horizon spec
    useEventListener(ref, 'clear', onUpdatePaymentState);
    return (React.createElement("hzn-select", { ref: ref, placeholder: "All active carts", clearable: true, value: paymentState ?? '' },
        React.createElement("span", { className: "visually-hidden" }, "Cart status"),
        React.createElement("option", { value: PaymentFilterNames.ReadyToCheckout }, PaymentFilterNames.ReadyToCheckout),
        React.createElement("option", { value: PaymentFilterNames.InProgress }, PaymentFilterNames.InProgress),
        React.createElement("option", { value: PaymentFilterNames.Completed }, PaymentFilterNames.Completed),
        React.createElement("option", { value: PaymentFilterNames.Error }, PaymentFilterNames.Error)));
};
