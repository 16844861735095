import { eventEmitter } from '@horizon/common/events';
import { HznIconButton } from '@horizon/icon-button';
import { HznIconChevronLeft, HznIconChevronRight } from '@horizon/icons/individual';
import { HznSelect } from '@horizon/select';
import { HznText } from '@horizon/text';
import { LitElement, html, nothing, TemplateResult, PropertyValues } from '@horizon/base';
import { property, state } from '@horizon/base/decorators.js';
import { ScopedElementsMap, ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import PaginationStyles from './pagination.css.js';
import { keyed } from '@horizon/base/directives';

/**
 *
 * @tag hzn-pagination
 * @tagname hzn-pagination
 * @summary Horizon Pagination component
 *
 * @fires {HznPaginationPageChangeEvent} next - Preventable. Emitted when the 'next page' button is activated
 * @fires {HznPaginationPageChangeEvent} previous - Preventable. Emitted when the 'previous page' button is activated
 * @fires {HznPaginationPageChangeEvent} page-change - Preventable. Emitted when the 'page selection' select input value is changed
 * @fires {HznPaginationOptionsChangeEvent} items-per-page-change - Emitted when the 'number of items per page' select input value is changed
 *
 * */

export class HznPagination extends (ScopedElementsMixin(LitElement) as typeof LitElement) {
  /**
   * @private
   */
  #emit = eventEmitter(this);
  #defaultItemsPerPageOptions: number[] = [25, 50, 100];
  #initialRender = false;

  static styles = [PaginationStyles];

  static get scopedElements(): ScopedElementsMap {
    return {
      'hzn-icon-button': HznIconButton,
      'hzn-select': HznSelect,
      'hzn-text': HznText,
      'hzn-icon-chevron-left': HznIconChevronLeft,
      'hzn-icon-chevron-right': HznIconChevronRight
    };
  }

  /**
   * @private
   */
  @state() itemsPerPage = this.#defaultItemsPerPageOptions[0];

  /**
   * Sets the total items
   */
  @property({ type: Number, attribute: 'total-items' }) totalItems = 1;

  /**
   *
   * Sets the current page
   */
  @property({ type: Number, attribute: 'current-page' }) currentPage = 1;

  /**
   * Set the items name(s)
   */
  @property({ type: String, attribute: 'item-name' }) itemName = 'item items';

  /**
   * Set the page name
   */
  @property({ type: String, attribute: 'page-name' }) pageName = 'page';

  /**
   * Hides the item-range for the pagination
   */
  @property({ type: Boolean, attribute: 'hide-item-range' }) hideItemRange = false;

  /**
   * Sets the options that appear in the items-per-page select
   */
  @property({ type: Array, attribute: 'items-per-page-options' }) itemsPerPageOptions?: number[];

  /**
   * Sets the initially selected option on the items-per-page select
   */
  @property({ type: Number, attribute: 'initial-items-per-page' }) initialItemsPerPage?: number;


  /**
   * Define Inner Next hzn-icon-button
   */
  get innerNextButton(): HznIconButton['innerButton'] | null {
    const innerNextButton = this.renderRoot.querySelector<HznIconButton>('.next-button')
    return innerNextButton ? innerNextButton.innerButton : null;
  }

  /**
   * Define Inner Previous hzn-icon-button
   */
  get innerPrevButton(): HznIconButton['innerButton'] | null {
    const innerPrevButton = this.renderRoot.querySelector<HznIconButton>('.prev-button')
    return innerPrevButton ? innerPrevButton.innerButton : null;
  }

  /**
   * Define Inner Page Select hzn-select
   */
  get innerPageSelect(): HznSelect['innerSelect'] | null {
    const pageSelect = this.shadowRoot?.querySelector<HznSelect>('hzn-select.page');
    return pageSelect ? pageSelect.innerSelect : null;
  }

  /**
   * Define Inner Items-per-page Select hzn-select
   */
  get innerItemsPerPageSelect(): HznSelect['innerSelect'] | null {
    const ItemsSelect = this.shadowRoot?.querySelector<HznSelect>('hzn-select.items-per-page');
    return ItemsSelect ? ItemsSelect.innerSelect : null;
  }

  /**
   * @private
   * The array of possible items-per-page-options, either provided or default
   */
  get #itemsPerPageArray() {
    return this.itemsPerPageOptions || this.#defaultItemsPerPageOptions;
  }

  /**
   * @private
   * Return Array of 'item name(s)' — singular or plural. (dependant on passed itemName Sting value)
   */
  get #itemNames() {
    if (this.itemName.includes(' ')) {
      return this.itemName.split(' ');
    } else {
      return [this.itemName, `${this.itemName}s`]
    }
  }

  /**
   * @private
   * The number of pages that exist depending on the totalItems and current itemsPerPage
   */
  get totalPages() {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  /**
   * @private
   * A string representing the range of currently paginated items given the currentPage and itemsPerPage
   */
  get #itemRange(): string {
    // if currentPage is 1, set _from value to 1.
    // if currentPage is greater than 1, set _from value to some non-zero multiple of the items-per-page value
    const _from = this.currentPage === 1 ? 1 : ((this.currentPage - 1) * this.itemsPerPage) + 1;
    let _to: number;
    if (this.currentPage === 1 && this.totalPages >= this.itemsPerPage) {
      _to = this.itemsPerPage;
    } else {
      if (this.currentPage === this.totalPages) {
        _to = (this.currentPage * this.itemsPerPage) + (this.totalItems - (this.currentPage * this.itemsPerPage));
      } else {
        _to = (this.currentPage * this.itemsPerPage);
      }
    }

    if (_from === _to) {
      return `${this.#itemNames[0]} ${_to} of ${this.totalItems}`
    }

    return `${this.#itemNames[1]} ${_from}–${_to} of ${this.totalItems}`;
  }

  willUpdate(changed: PropertyValues<this>) {
    if(changed.has('totalItems')) {
      // if changing total items causes less pages than there were
      // we need to check that the currentPage isn't bigger than the new totalPages
      // if it is, set it to the maximum it can be, which is totalPages
      if(this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    }

    // if we havent completed first render yet
    if(!this.#initialRender) {
      // so if theres an initial per page value, try to use that
      if(this.initialItemsPerPage && this.#itemsPerPageArray.includes(this.initialItemsPerPage)) {
        // if there's an initialItemsPerPage set, then we need to validate that its actually one of the available options
        // if so, use it, if not, default to the first option in the options array
        this.itemsPerPage = this.#itemsPerPageArray[this.#itemsPerPageArray.indexOf(this.initialItemsPerPage)];
      } else {
        this.itemsPerPage = this.#itemsPerPageArray[0];
      }
    }

    // if we're dynamically setting itemsPerPageOptions after the first render
    // we need to reset the itemsPerPage
    if(changed.has('itemsPerPageOptions')) {
      if(!this.#itemsPerPageArray.includes(this.itemsPerPage)) {
        this.itemsPerPage = this.#itemsPerPageArray[0];
      }
    }
  }

  firstUpdated() {
    // set flag for willUpdate checks for itemsPerPage
    this.#initialRender = true;
  }

  /**
  * @private
  */
  #handleNextClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.#emit({
      type: 'next',
      eventOptions: {
        detail: {
          newCurrentPage: this.currentPage + 1
        }
      },
      callback: () => {
        this.currentPage = this.currentPage + 1;
      }
    });
  }

  /**
  * @private
  */
  #handlePreviousClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.#emit({
      type: 'previous',
      eventOptions: {
        detail: {
          newCurrentPage: this.currentPage - 1
        }
      },
      callback: () => {
        this.currentPage = this.currentPage - 1;
      }
    });
  }

  /**
  * @private
  */
  #handlePageChange(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const newCurrentPage: number = parseInt((event.target as HznSelect).value);

    this.#emit({
      type: 'page-change',
      eventOptions: {
        detail: {
          newCurrentPage,
        }
      },
      callback: () => {
        this.currentPage = newCurrentPage;
      }
    });
  }

  /**
  * @private
  */
  #handleItemsPerPageChange(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const newItemsPerPage = parseInt((event.target as HznSelect).value);

    let newTotalPages: number;

    if (this.totalItems > newItemsPerPage) {
      newTotalPages = Math.ceil(this.totalItems / newItemsPerPage);
    } else {
      newTotalPages = 1;
    }

    let newCurrentPage: number = this.currentPage;

    if (this.currentPage > newTotalPages) {
      newCurrentPage = newTotalPages;
    }

    this.#emit({
      type: 'items-per-page-change',
      eventOptions: {
        detail: {
          newItemsPerPage,
          newTotalPages,
          newCurrentPage,
        }
      },
      callback: () => {
        this.itemsPerPage = newItemsPerPage;
        this.currentPage = newCurrentPage;
      }
    });
  }


  render(): TemplateResult {
    return html`
      <nav class="pagination" aria-label="pagination">

        <div class="controls">
          <hzn-icon-button @click=${this.#handlePreviousClick} class="prev-button" tone="interactive" square outlined compact hzn-aria-label="previous" ?disabled="${this.currentPage === 1}">
            <hzn-icon-chevron-left></hzn-icon-chevron-left>
          </hzn-icon-button>

          <div class="selects">
            <hzn-select
              class="page"
              compact
              hide-label
              name="page-change-select"
              value="${`${this.currentPage}`}"
              @change=${this.#handlePageChange}
            >
              jump to
              ${new Array(this.totalPages).fill(1).map((page: number, index: number, srcArray: number[]) => {
                return html`<option value="${index + 1}">${this.pageName} ${index + 1} of ${srcArray.length}</option>`
              })}
            </hzn-select>
            <hzn-select
              class="items-per-page"
              compact
              hide-label
              name="items-per-page-change-select"
              value="${`${this.itemsPerPage}`}"
              @change=${this.#handleItemsPerPageChange}
            >
              number of ${this.#itemNames[1]}
              ${this.#itemsPerPageArray.map(pageSize => {
                return keyed(this.#itemsPerPageArray, html`<option value="${pageSize}">${pageSize} ${this.#itemNames[1]} per ${this.pageName}</option>`);
              })}
            </hzn-select>
          </div>

          <hzn-icon-button
            class="next-button"
            tone="interactive"
            square
            outlined
            compact
            hzn-aria-label="forward"
            ?disabled="${this.currentPage === this.totalPages}"
            @click=${this.#handleNextClick}
          >
            <hzn-icon-chevron-right></hzn-icon-chevron-right>
          </hzn-icon-button>
        </div>

        ${!this.hideItemRange ? html`<hzn-text align="center" class="item-range">${this.#itemRange}</hzn-text>` : nothing}

      </nav>
      `;
  }
}

