// class definition
import { LitElement, TemplateResult, html } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';

import { HznColumnsAlignX, HznColumnsAlignY, HznColumnsCollapseBelow, HznColumnsSpace } from '../types.js';
import ColumnsStyles from './columns.css.js';
import { classMap } from '@horizon/base/directives';
/**
 *
 * @tag hzn-columns
 * @tagname hzn-columns
 * @summary A layout component for creating explicit columns of content
 */
export class HznColumns extends LitElement {
  static styles = [ColumnsStyles];

  /**
   * Set the gap between each column
   * @playroomValues {'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge'}
   */
  @property({ type: String }) space?: HznColumnsSpace = 'base';

  /**
   * Set the responsive breakpoint below which all columns will become a single column
   * @playroomValues {'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'}
   */
  @property({ type: String, attribute: 'collapse-below' }) collapseBelow?: HznColumnsCollapseBelow = 'small';

  /**
   * Set the vertical alignment of the column elements inside the columns container
   * @playroomValues {'stretch' | 'start' | 'end' | 'center'}
   */
  @property({ type: String, attribute: 'align-y' }) alignY?: HznColumnsAlignY =
    'start';

  /**
   * Set the horizontal alignment of the column elements inside the columns container
   * @playroomValues {'start' | 'end' | 'center' | 'space-between' | 'space-evenly' | 'space-around'}
   */
  @property({ type: String, attribute: 'align-x' }) alignX?: HznColumnsAlignX = 'start';

  /**
   * Reverse the column order
   */
  @property({ type: Boolean }) reverse?: boolean = false;

  render(): TemplateResult {
    return html`<div class=${classMap({
      columns: true,
      'is-reversed': Boolean(this.reverse),
      'is-space-none': this.space === 'none',
      'is-space-xxsmall': this.space === 'xxsmall',
      'is-space-xsmall': this.space === 'xsmall',
      'is-space-small': this.space === 'small',
      'is-space-base': this.space === 'base',
      'is-space-medium': this.space === 'medium',
      'is-space-large': this.space === 'large',
      'is-space-xlarge': this.space === 'xlarge',
      'is-space-xxlarge': this.space === 'xxlarge',
      'is-space-xxxlarge': this.space === 'xxxlarge',
      'is-align-y-stretch': this.alignY === 'stretch',
      'is-align-y-start': this.alignY === 'start',
      'is-align-y-center': this.alignY === 'center',
      'is-align-y-end': this.alignY === 'end',
      'is-align-x-start': this.alignX === 'start',
      'is-align-x-center': this.alignX === 'center',
      'is-align-x-end': this.alignX === 'end',
      'is-align-x-space-between': this.alignX === 'space-between',
      'is-align-x-space-evenly': this.alignX === 'space-evenly',
      'is-align-x-space-around': this.alignX === 'space-around',
      'is-collapse-below-small': this.collapseBelow === 'small',
      'is-collapse-below-medium': this.collapseBelow === 'medium',
      'is-collapse-below-large': this.collapseBelow === 'large',
      'is-collapse-below-xlarge': this.collapseBelow === 'xlarge',
      'is-collapse-below-xxlarge': this.collapseBelow === 'xxlarge',
    })}>
      <slot></slot>
    </div>`;
  }
}


