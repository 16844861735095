import { FlexColumn } from '@src/components/Flex';
import { CurrencyInput } from '@src/components/horizon/CurrencyInput';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import Input from '@src/components/horizon/Input';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import React, { useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CertifiedFundsPaymentDescription } from './CertifiedFundsPaymentDescription';
/**
 * ConfirmCashAmount asks the BOA to enter the amount of cash provided by the customer for a cash payment.
 * This will be used to calculate any necessary change.
 */
export const ConfirmCertifiedFundsAmount = (props) => {
    const { payment } = useAppSelector(selectPayment);
    const currencyRef = useRef();
    const checkNumberRef = useRef();
    const [checkAmount, setCheckAmount] = useState(undefined);
    const [checkNumber, setCheckNumber] = useState(undefined);
    const onConfirm = () => {
        if (!currencyRef.current.validate() || !checkNumberRef.current.validate())
            return;
        props.onContinue({ checkAmount, checkNumber });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal.Header, null,
            React.createElement("hzn-heading", { size: "xsmall" }, "Confirm amount to be processed.")),
        React.createElement(Modal.Body, null,
            React.createElement(FlexColumn, null,
                React.createElement("hzn-text", null, "Confirm the amount of the certified funds check."),
                React.createElement(CertifiedFundsPaymentDescription, null),
                React.createElement("hzn-divider", null),
                React.createElement(CurrencyInput, { ref: currencyRef, className: "ps-2 pe-2", label: "Amount of certified funds check", required: true, min: payment.amount, onValueChanged: setCheckAmount, errorMessages: {
                        valueMissing: 'A valid dollar amount is required',
                        rangeUnderflow: `Cannot be less than the amount to be processed.`,
                    } }),
                React.createElement("hzn-divider", null),
                React.createElement(Input, { ref: checkNumberRef, className: "ps-2 pe-2", label: "Check number", required: true, onValueChanged: setCheckNumber, errorMessages: {
                        valueMissing: 'A check number is required',
                    } }))),
        React.createElement(Modal.Footer, null,
            React.createElement(FlexColumn, { className: "w-100" },
                React.createElement(HorizonButton, { variant: "primary", onClick: onConfirm }, "Confirm Amount"),
                React.createElement(HorizonButton, { variant: "tertiary", onClick: props.onCancel }, "Cancel")))));
};
