import React, { useEffect } from 'react';
import '@horizon/icons/individual/hzn-warning';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { DrawerStatus, useHardwareCashDrawerGetStatusQuery } from '@src/api/CashDrawerApi';
import { useGetWorkstationByNameAndLocationQuery } from '@src/api/CheckoutApi';
import { useSystemInteractionGetComputerNameQuery } from '@src/api/SystemInteractionApi';
import { selectCashDrawerStatus, setCashDrawerStatus } from '@src/store/HardwareSlice';
import '@horizon/icons/individual/hzn-danger';
import '@horizon/icons/individual/hzn-warning';
const readyStatuses = [DrawerStatus.Open, DrawerStatus.Closed];
export const CashDrawerBadge = () => {
    const dispatch = useAppDispatch();
    const cashDrawerStatus = useAppSelector(selectCashDrawerStatus);
    const { storeLocationId } = useParams();
    const cashDrawer = useHardwareCashDrawerGetStatusQuery('', { pollingInterval: 1000 });
    const workstationName = useSystemInteractionGetComputerNameQuery({});
    const workstation = useGetWorkstationByNameAndLocationQuery({ name: workstationName.data, storeLocationId }, { refetchOnMountOrArgChange: true, skip: !storeLocationId || !workstationName.isSuccess });
    useEffect(() => {
        const assigned = workstationName.isError || workstation.isError ? false : workstation.isSuccess ? workstation.data.isDrawerAssigned : undefined;
        const ready = cashDrawer.isError ? false : cashDrawer.isSuccess ? readyStatuses.includes(cashDrawer.data) : undefined;
        dispatch(setCashDrawerStatus({ assigned, ready }));
    }, [workstationName, workstation, cashDrawer]);
    if (cashDrawerStatus.assigned && cashDrawerStatus.ready) {
        return (React.createElement("hzn-badge", { label: "Cash drawer", tone: "positive", intense: true, title: "The cash drawer is ready for payment processing." }));
    }
    else if (cashDrawerStatus.assigned === false) {
        return (React.createElement("hzn-badge", { label: "Cash drawer not assigned", tone: "critical", intense: true, title: "Please assign a cash drawer to this workstation in eCMS." },
            React.createElement("hzn-icon-danger", { slot: "leading-icon" })));
    }
    else if (cashDrawerStatus.ready === false) {
        return (React.createElement("hzn-badge", { label: "Cash drawer not responding", tone: "caution", intense: true, title: "You will need to open the cash drawer manually using a key." },
            React.createElement("hzn-icon-warning", { slot: "leading-icon" })));
    }
    else {
        return (React.createElement("hzn-badge", { label: "Cash drawer", intense: true, title: "Waiting for cash drawer status information..." }));
    }
};
