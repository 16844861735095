// class definition
import { LitElement, TemplateResult, html } from '@horizon/base';
import { HasSlotController } from '@horizon/common/controllers';
import { property } from '@horizon/base/decorators';
import { classMap } from '@horizon/base/directives';
import { nothing } from '@horizon/base/html.js';

import BadgeStyles from './badge.css.js';
import { HznBadgeTone } from '../types.js';

/**
 *
 * @tag hzn-badge
 * @tagname hzn-badge
 * @summary badge that can pass required information based on tone and intensity.
 */

export class HznBadge extends LitElement {
  static styles = [BadgeStyles];

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'trailing-icon');

  /**
   * Sets the label for badge
   */
  @property({ type: String }) label = 'Badge';

  /**
   * Sets the background color and text for badge
   * @playroomValues {'critical' | 'caution' | 'info' | 'neutral' | 'positive' | 'flagged'}
   */
  @property({ type: String }) tone?: HznBadgeTone = 'neutral';

  /**
   * When true, increases intensity of background color and text for badge
   */
  @property({ type: Boolean }) intense?: boolean = false;

  render(): TemplateResult {
    return html`<div
      class=${classMap({
          'badge': true,
          'is-intense': Boolean(this.intense),
          'is-tone-critical': this.tone === 'critical',
          'is-tone-caution': this.tone === 'caution',
          'is-tone-neutral': this.tone === 'neutral',
          'is-tone-info': this.tone === 'info',
          'is-tone-positive': this.tone === 'positive',
          'is-tone-flagged': this.tone === 'flagged'
        })}
      role="status"
    >
      ${this.hasSlotController.test('leading-icon') ? html`<slot class="badge-leading-icon" name="leading-icon"></slot>` : nothing}
      <span class="badge-label">${this.label || `${this.tone} badge`}</span>
    </div>`;
  }
}
