import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
export const CardRow = ({ id, label, value, className = '', children }) => {
    const labelId = useMemo(() => id ?? uuidv4(), [id]);
    return ((label && value) ?
        React.createElement("div", { className: `d-flex flex-row justify-content-between align-items-center gap-4 ${className}` },
            React.createElement("label", { id: labelId },
                typeof label === 'string' && React.createElement("hzn-text", null, label),
                typeof label !== 'string' && label),
            React.createElement("div", { "aria-labelledby": labelId },
                typeof value === 'string' && React.createElement("hzn-text", null, value),
                typeof value !== 'string' && value))
        :
            React.createElement("div", { className: `d-flex flex-row justify-content-between align-items-center gap-4 ${className}` }, children));
};
export const Card = ({ title, padTitle = 'p-3', padBody = 'p-3', titleHeader = 'h2', titleSize = 'small', border = true, cardStyle = {}, bodyClassName = '', children, }) => {
    const body = (React.createElement("div", null,
        title && (React.createElement(React.Fragment, null,
            React.createElement("hzn-heading", { class: padTitle, as: titleHeader, size: titleSize }, title))),
        React.createElement("div", { className: `${padBody} ${bodyClassName}` }, children)));
    return border ? (React.createElement("hzn-card", { style: cardStyle, elevation: "flat", "border-radius": "medium", padding: "none" }, body)) : (body);
};
