
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-location
 * @tagname hzn-icon-location
 * @summary An individual icon for Horizon
 */
export class HznIconLocation extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.28998 11C2.28998 5.64998 6.64998 1.28998 12 1.28998C17.35 1.28998 21.71 5.40998 21.71 11C21.71 16.5688 15.5569 20.5695 13.5032 21.9048L13.48 21.92C13.03 22.21 12.51 22.36 12 22.36C11.49 22.36 10.97 22.21 10.52 21.92C8.47998 20.6 2.28998 16.35 2.28998 11ZM11.2608 20.711L11.29 20.73C11.72 21.01 12.28 21.01 12.71 20.73C14.58 19.52 20.29 15.82 20.29 11C20.29 6.42998 16.57 2.70998 12 2.70998C7.42998 2.70998 3.70998 6.18998 3.70998 11C3.70998 15.7853 9.36144 19.4719 11.2608 20.711ZM8.28998 11C8.28998 8.94998 9.94998 7.28998 12 7.28998C14.05 7.28998 15.71 8.94998 15.71 11C15.71 13.05 14.05 14.71 12 14.71C9.94998 14.71 8.28998 13.05 8.28998 11ZM9.70998 11C9.70998 12.26 10.74 13.29 12 13.29C13.26 13.29 14.29 12.26 14.29 11C14.29 9.73998 13.26 8.70998 12 8.70998C10.74 8.70998 9.70998 9.73998 9.70998 11Z" fill="currentColor" class="stroked"></path><path d="M12 1.28998C6.64998 1.28998 2.28998 5.64998 2.28998 11C2.28998 16.35 8.47998 20.61 10.52 21.92C10.97 22.21 11.49 22.36 12 22.36C12.51 22.36 13.03 22.21 13.48 21.92C15.51 20.6 21.71 16.59 21.71 11C21.71 5.40998 17.35 1.28998 12 1.28998ZM12 14C10.34 14 8.99998 12.66 8.99998 11C8.99998 9.33998 10.34 7.99998 12 7.99998C13.66 7.99998 15 9.33998 15 11C15 12.66 13.66 14 12 14Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
