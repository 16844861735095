
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-chevron-right
 * @tagname hzn-icon-chevron-right
 * @summary An individual icon for Horizon
 */
export class HznIconChevronRight extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M9.81999 19.07C9.63999 19.07 9.45999 19 9.31999 18.86C9.03999 18.58 9.03999 18.13 9.31999 17.86L15.18 12L9.31999 6.14001C9.03999 5.86001 9.03999 5.41001 9.31999 5.14001C9.59999 4.87001 10.05 4.86001 10.32 5.14001L16.68 11.5C16.96 11.78 16.96 12.23 16.68 12.5L10.32 18.86C10.18 19 9.99999 19.07 9.81999 19.07Z" fill="currentColor" class="stroked"></path><path d="M9.81999 19.07C9.63999 19.07 9.45999 19 9.31999 18.86C9.03999 18.58 9.03999 18.13 9.31999 17.86L15.18 12L9.31999 6.14001C9.03999 5.86001 9.03999 5.41001 9.31999 5.14001C9.59999 4.87001 10.05 4.86001 10.32 5.14001L16.68 11.5C16.96 11.78 16.96 12.23 16.68 12.5L10.32 18.86C10.18 19 9.99999 19.07 9.81999 19.07Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
