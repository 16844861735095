/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlInterface, Constructor, FormControlMixin } from '@open-wc/form-control';
import { LitElement } from '@horizon/base';
import { property, state, query } from '@horizon/base/decorators.js';
import { defaultValidators } from './validators.js';
import 'element-internals-polyfill';

export interface HorizonTextInputInterface {
  internalErrorMessage: string;
  validationMessage: string;
  errorMessages?: Partial<Record<keyof ValidityState, string | ((instance: any) => string)>>;
  displayName?: string;
  helperText?: string;
  hideHelperText?: boolean;
  value: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  minlength?: number;
  maxlength?: number;
  // date-like inputs can have a min that is a date-like string object
  min?: number | string;
  // date-like inputs can have a max that is a date-like string object
  max?: number | string;
  step?: number;
  pattern?: string;
  validationTarget: HTMLElement;
  innerInput: HTMLElement;
  get showErrors(): boolean;
  validation?: (instance?: any) => { valid: boolean; message: string };
  focus: (options?: FocusOptions) => void;
  blur: () => void;
}
export interface HorizonTextInputHTMLInterface {
  value?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  minlength?: number;
  maxlength?: number;
  min?: number | string;
  max?: number | string;
  step?: number;
  pattern?: string;
  'display-name'?: string;
  'helper-text'?: string;
  'hide-helper-text'?: boolean;
}

export type HorizonTextInput<BaseFormControl> = Constructor<HorizonTextInputInterface> &
  Constructor<FormControlInterface> &
  Constructor<BaseFormControl>;
export type HorizonTextInputClass<BaseFormControl> = Constructor<HorizonTextInputInterface> &
  Constructor<FormControlInterface> &
  BaseFormControl;

export const VALIDITY_PROPS = [
  'type',
  'value',
  'required',
  'min',
  'max',
  'minlength',
  'maxlength',
  'step',
  'pattern'
];

export function TextInputMixin<BaseFormControl extends Constructor<LitElement>>(
  SuperClass: BaseFormControl
): HorizonTextInputClass<BaseFormControl> {
  class HorizonTextInputMixin extends FormControlMixin(SuperClass) {

    /**
     * @private
     */
    #initialValiditySet = false;

    /**
     * @private
     * Sets an initial default value for the purpose of checking whether or not to run validations
     * Mostly will default to '', but in currency inputs could be reset to '$' so that validations don't get run when
     * for optional inputs when the value === '$'
     */
    defaultInitialValue = '';

    /**
     * @private
     */
    @state() internalErrorMessage!: string;

    /**
     * @private
     * The native input inside the host element
     */
    @query('input') validationTarget!: HTMLInputElement;

    // INTERNAL ACCESS PROPERTIES
    /**
     * The internal native input element
     */
    @query('input') innerInput!: HTMLInputElement;

    static get formControlValidators() {
      return [
        ...defaultValidators
      ];
    }

    /**
     * Set a display name on the input - used in error message customization
     */
    @property({ type: String, attribute: 'display-name' }) displayName?: string;

    /**
     * Overwrite standard scenario error messages with a custom one
     */
    @property({ attribute: false }) errorMessages?: Partial<Record<keyof ValidityState, string | ((instance: any) => string)>>;

    /**
     * Set an error state and message on the input
     */
    @property({ attribute: false }) validation?: (instance?: any, value?: string) => { valid: boolean, message: string };

    /**
     * Sets the input name
     */
    @property() name?: string;

    /**
     * Sets the input value
     */
    @property({ type: String }) value = '';

    /**
     * Set whether or not the input is required to have a value
     */
    @property({ type: Boolean, reflect: true }) required?: boolean = false;

    /**
     * Set the input to be disabled
     */
    @property({ type: Boolean, reflect: true }) disabled?: boolean = false;

    /**
     * Set the input to be read only
     */
    @property({ type: Boolean, reflect: true }) readonly?: boolean = false;

    /**
     * Set the help text for the input
     */
    @property({ type: String, attribute: 'helper-text' }) helperText?: string = '';

    /**
     * Hides the help text for the input
     */
    @property({ type: Boolean, attribute: 'hide-helper-text' }) hideHelperText?: boolean = false;

    /**
     * The minimum length of input that will be considered valid
     */
    @property({ type: Number }) minlength?: number;

    /**
     * The maximum length of input that will be considered valid
     */
    @property({ type: Number }) maxlength?: number;

    /**
     * The input's minimum value
     */
    @property() min?: number | string;

    /**
     * The input's maximum value
     */
    @property() max?: number | string;

    /**
     * The input's step attribute
     */
    @property({ type: Number }) step?: number;

    /**
     * A pattern to validate input against
     */
    @property() pattern?: string;

    constructor() {
      super();
      this.addEventListener('invalid', this.#onInvalid);
    }

    disconnectedCallback(): void {
      super.disconnectedCallback();
      this.removeEventListener('invalid', this.#onInvalid);
    }

    /**
     * @private
     */
    #onInvalid = (event: Event): void => {
      event.preventDefault();
      this.validationTarget?.focus();
    };

    /**
     * @private
     */
    get showErrors() {
      // only show the clear button if clearable, with a value
      // and not disabled or readonly
      return this.showError && !this.disabled && !this.readonly;
    }

    /**
     * @private
     */
    validationMessageCallback(message: string): void {
      this.internalErrorMessage = message;
    }

    /**
     * @private
     */
    protected updated(changed: Map<string, string>): void {
      // only run this validity set one time after the initial component render
      if (VALIDITY_PROPS.some((prop) => changed.has(prop))) {
        this.setValue(this.value);
      }
    }

    /** Sets focus on the input. */
    focus(options?: FocusOptions) {
      this.validationTarget.focus(options);
    }

    /** Removes focus from the input. */
    blur() {
      this.validationTarget.blur();
    }
  }

  return HorizonTextInputMixin as HorizonTextInputClass<BaseFormControl>;
}
