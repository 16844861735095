/**
 * Determines the HTTP status code from an `OAPError` object.
 * @param {OAPError} error - The error object from which the status code should be derived.
 * @returns {number} The HTTP status code number.
 */
export function getStatusCode(error) {
    let statusCode = error.status_code;
    if (statusCode == undefined) {
        statusCode = 400;
    }
    return statusCode;
}
/**
 * Synthesizes the most relevant error details from an `OAPError` object.
 * @param {OAPError} error - The error object from which the error details should be derived.
 * @returns {string} The error details string.
 */
export function synthesizeErrorDetails(error) {
    let extraErrorDetail = error.message;
    if (extraErrorDetail == undefined || extraErrorDetail.length == 0) {
        if (error.error) {
            let shortErrorType = error.error.replace("_", " ");
            shortErrorType = toTitleCase(shortErrorType);
            extraErrorDetail = shortErrorType;
        }
        if (error.error_code) {
            let shortErrorCode = error.error.replace("_", " ");
            shortErrorCode = toTitleCase(shortErrorCode);
            if (extraErrorDetail.length > 0) {
                extraErrorDetail.concat(` (${shortErrorCode})`);
            }
            else {
                extraErrorDetail = shortErrorCode;
            }
        }
        if (extraErrorDetail.length > 0) {
            extraErrorDetail.concat(`: ${error.error_description}`);
        }
        else {
            extraErrorDetail = error.error_description;
        }
    }
    return extraErrorDetail;
}
function toTitleCase(value) {
    return value.replace(/\w\S*/g, function (substring) {
        return substring.charAt(0).toUpperCase() + substring.substr(1).toLowerCase();
    });
}
