import * as signalR from '@microsoft/signalr';
import { useGetBasketWorkflowProgressionQuery, } from '@src/api/CheckoutApi';
import { OAUTH_PROXY } from '@src/config';
import React, { useState } from 'react';
const convertToSteps = (workflowStepsForBasket) => {
    const steps = [];
    if (!workflowStepsForBasket)
        return steps;
    workflowStepsForBasket.forEach(step => {
        let state;
        switch (step.status) {
            case 'NOT_STARTED':
                state = 'NotStarted';
                break;
            case 'IN_PROGRESS':
                state = 'InProgress';
                break;
            case 'COMPLETE':
                state = 'Complete';
                break;
            // case 'SKIPPED':
            // break;
            // case 'PARTIALLY_COMPLETE':
            // break;
        }
        steps.push({ name: step.stepName, state: state, active: step.isEnabled });
    });
    return steps;
};
const CheckoutStepper = ({ basketId }) => {
    const [steps, setSteps] = useState([]);
    const [signalRConnection, setSignalRConnection] = useState(undefined);
    const workflowProgression = useGetBasketWorkflowProgressionQuery({
        basketId,
    });
    const connectToSignalR = () => {
        const connection = new signalR.HubConnectionBuilder()
            // TODO: Pull from app config probably?
            .withUrl(`${OAUTH_PROXY.apiProxyUri}/basket-workflows`, {
            withCredentials: false,
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('com.unboundid.kmxpos.access_token').replace('Bearer', '').trim()}`,
                'id_token': sessionStorage.getItem('com.unboundid.kmxpos.id_token'),
                'refresh_token': sessionStorage.getItem('com.unboundid.kmxpos.refresh_token'),
            },
        })
            .build();
        setSignalRConnection(connection);
        // Create a function that the hub can call to broadcast messages.
        connection.on('onBasketWorkflowUpdated', (workflowProgressionForBasket) => {
            console.log('Received SignalR workflow update');
            setSteps(convertToSteps(workflowProgressionForBasket?.steps));
        });
        connection.onclose((error) => {
            if (error && error.message) {
                console.error(`SignalR connection closed with the following error: ${error.message}`);
            }
            else {
                console.log('Successfully closed connection to SignalR');
            }
        });
        connection
            .start()
            .then(() => {
            console.log('Connected to SignalR workflow hub');
            connection
                .send('RegisterAsync', basketId)
                .then(() => {
                console.warn('Registered for workflow basket updates with SignalR');
            })
                .catch(error => {
                console.error(error.message);
            });
        })
            .catch(error => {
            console.error(`SignalR connection failed to start with the following error: ${error.message}`);
        });
    };
    if (workflowProgression.isSuccess && workflowProgression.data?.steps != null && !signalRConnection) {
        setSteps(convertToSteps(workflowProgression.data.steps));
        connectToSignalR();
    }
    return (React.createElement(React.Fragment, null)
    // <Suspense suspended={workflowProgression.isLoading}>
    //     <Stepper label="Checkout Workflow" steps={steps} />
    // </Suspense>
    );
};
export default CheckoutStepper;
