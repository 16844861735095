
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-plus
 * @tagname hzn-icon-plus
 * @summary An individual icon for Horizon
 */
export class HznIconPlus extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M21 11.29H12.71V2.99998C12.71 2.60998 12.39 2.28998 12 2.28998C11.61 2.28998 11.29 2.60998 11.29 2.99998V11.29H2.99998C2.60998 11.29 2.28998 11.61 2.28998 12C2.28998 12.39 2.60998 12.71 2.99998 12.71H11.29V21C11.29 21.39 11.61 21.71 12 21.71C12.39 21.71 12.71 21.39 12.71 21V12.71H21C21.39 12.71 21.71 12.39 21.71 12C21.71 11.61 21.39 11.29 21 11.29Z" fill="currentColor" class="stroked"></path><path d="M21 11.29H12.71V2.99998C12.71 2.60998 12.39 2.28998 12 2.28998C11.61 2.28998 11.29 2.60998 11.29 2.99998V11.29H2.99998C2.60998 11.29 2.28998 11.61 2.28998 12C2.28998 12.39 2.60998 12.71 2.99998 12.71H11.29V21C11.29 21.39 11.61 21.71 12 21.71C12.39 21.71 12.71 21.39 12.71 21V12.71H21C21.39 12.71 21.71 12.39 21.71 12C21.71 11.61 21.39 11.29 21 11.29Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
