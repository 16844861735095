// class definition
import { LitElement, TemplateResult } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { classMap } from '@horizon/base/directives';
import { unsafeStatic, html } from '@horizon/base/static-html.js';

import dividerStyles from './divider.css.js';

import { HznDividerOn, HznDividerTone } from '../types.js';

/**
 *
 * @tag hzn-divider
 * @tagname hzn-divider
 * @summary A 1px tall horizontal divider with no padding or margins
 */

export class HznDivider extends LitElement {
  static styles = [dividerStyles];

  /**
   * Sets the tone for the horizontal divider
   * @playroomValues {'neutral' | 'brand' | 'brand-accent' | 'subdued'}
   */
  @property({ type: String }) tone: HznDividerTone = 'subdued';

  /**
   * Rotates the divider 90 degress to make a vertical divider
   */
  @property({ type: Boolean, reflect: true }) vertical?: boolean;

  /**
   * Sets the background color that this divider is appearing on top of in order to change its color accordingly
   * @playroomValues {'brand' | 'brand-accent'}
   */
  @property({ type: String }) on?: HznDividerOn;

  render(): TemplateResult {
    return html`<${unsafeStatic(this.vertical ? 'div' : 'hr')}
      class=${classMap({
        divider: true,
        'is-tone-neutral': this.tone === 'neutral',
        'is-tone-brand': this.tone === 'brand',
        'is-tone-brand-accent': this.tone === 'brand-accent',
        'is-tone-subdued': this.tone === 'subdued',
        'is-vertical': Boolean(this.vertical),
        'is-on-brand': this.on === 'brand',
        'is-on-brand-accent': this.on === 'brand-accent'
      })}
    ></${unsafeStatic(
      this.vertical ? 'div' : 'hr'
    )}>`;
  }
}
