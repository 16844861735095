import { property } from '@horizon/base/decorators';
import {
  currencyRequiredValidator,
  currencyPatternValidator,
  currencyMinValidator,
  currencyMaxValidator
} from './currency-validators.js';
import {
  minLengthValidator,
  maxLengthValidator,
  typeMismatchValidator,
  customValidator
} from '@horizon/common/mixins';
import type { Validator } from '@open-wc/form-control';
import { HznMaskedInput, maskOptions } from '@horizon/masked-input';

export const CURRENCY_PATTERN = '[$]\\d{1,3}(?:,?\\d{3})*(.\\d{2})?';

/**
 *
 * @tag hzn-currency-input
 * @tagname hzn-currency-input
 * @summary A dedicated input for displaying numeric values formatted as US dollar currency
 *
 * @fires {HznCurrencyInputInputEvent} change - Emitted when the input is changed
 * @fires {HznCurrencyInputChangeEvent} change - Emitted when the input is changed
 * @fires {HznCurrencyInputClearEvent} clear - Emitted when the input is clearable and the clear button is clicked
 */

export class HznCurrencyInput extends HznMaskedInput {

  #initialPadDecimals?: boolean;
  #initialNoDecimals?: boolean;

  /**
   * @private
   * Set the default initial value to '$' so that currency input validation only happens if the value is different from '$'
   */
  defaultInitialValue = '$';

  static get formControlValidators(): Validator[] {
    return [
      currencyRequiredValidator,
      typeMismatchValidator,
      currencyMinValidator,
      currencyMaxValidator,
      minLengthValidator,
      maxLengthValidator,
      currencyPatternValidator,
      customValidator
    ];
  }

  /**
   * Set whether or not decimals are allowed to be typed. When changed dynamically to true, existing decimals in the input's value will be removed
   */
  @property({ type: Boolean, attribute: 'no-decimals' }) noDecimals?: boolean = false;

  /**
   * Set whether or not to automatically pad decimals on blur
   */
  @property({ type: Boolean, attribute: 'pad-decimals' }) padDecimals?: boolean = false;


  constructor() {
    super();
    // hardcoded currency input defaults
    this.type = 'text';
    this.sensitive = false;
    this.pattern = CURRENCY_PATTERN;

    // set overwrite here instead of in the maskOptions
    // because the property setting overwrites maskOptions
    // settings when present
    this.overwrite = 'false';
  }

  firstUpdated() {
    this.#initialPadDecimals = this.padDecimals;
    this.#initialNoDecimals = this.noDecimals;

    this[maskOptions] = {
      mask: '$num',
      blocks: {
        num: {
          mask: Number,
          scale: this.noDecimals ? 0 : 2,
          padFractionalZeros: this.padDecimals && !this.noDecimals,
          thousandsSeparator: ',',
          radix: '.',
          mapToRadix: ['.']
        }
      }
    };
    super.firstUpdated();
  }

  willUpdate() {

    if(!this.value.includes('$')) {
      this.value = `$${this.value}`;
    }

    if(this.#initialPadDecimals !== undefined) {
      this.padDecimals = this.#initialPadDecimals;
    }
    if(this.#initialNoDecimals !== undefined) {
      this.noDecimals = this.#initialNoDecimals;
    }

    // hardcoded overrides of hzn-input options
    // these will always be set right before any update
    this.type = 'text';
    this.sensitive = false;
    this.overwrite = 'false';
    this.pattern = CURRENCY_PATTERN;
  }
}
