
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-person
 * @tagname hzn-icon-person
 * @summary An individual icon for Horizon
 */
export class HznIconPerson extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29004 7.00004C7.29004 9.60004 9.40004 11.71 12 11.71C14.6 11.71 16.71 9.60004 16.71 7.00004C16.71 4.40004 14.6 2.29004 12 2.29004C9.40004 2.29004 7.29004 4.40004 7.29004 7.00004ZM8.71004 7.00004C8.71004 5.19004 10.19 3.71004 12 3.71004C13.81 3.71004 15.29 5.19004 15.29 7.00004C15.29 8.81004 13.81 10.29 12 10.29C10.19 10.29 8.71004 8.81004 8.71004 7.00004ZM7.00004 21.71H17C18.49 21.71 19.71 20.49 19.71 19V18C19.71 15.4 17.6 13.29 15 13.29H9.00004C6.40004 13.29 4.29004 15.4 4.29004 18V19C4.29004 20.49 5.51004 21.71 7.00004 21.71ZM5.71004 18C5.71004 16.19 7.19004 14.71 9.00004 14.71H15C16.81 14.71 18.29 16.19 18.29 18V19C18.29 19.71 17.71 20.29 17 20.29H7.00004C6.29004 20.29 5.71004 19.71 5.71004 19V18Z" fill="currentColor" class="stroked"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29004 7.00004C7.29004 9.60004 9.40004 11.71 12 11.71C14.6 11.71 16.71 9.60004 16.71 7.00004C16.71 4.40004 14.6 2.29004 12 2.29004C9.40004 2.29004 7.29004 4.40004 7.29004 7.00004ZM7.00004 21.71H17C18.49 21.71 19.71 20.49 19.71 19V18C19.71 15.4 17.6 13.29 15 13.29H9.00004C6.40004 13.29 4.29004 15.4 4.29004 18V19C4.29004 20.49 5.51004 21.71 7.00004 21.71Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
