
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-check
 * @tagname hzn-icon-check
 * @summary An individual icon for Horizon
 */
export class HznIconCheck extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M9.79001 18.71C9.58001 18.71 9.38001 18.62 9.24001 18.45L4.95001 13.31C4.70001 13.01 4.74001 12.56 5.04001 12.31C5.34001 12.06 5.79001 12.1 6.04001 12.4L9.71001 16.8L16.9 5.61001C17.11 5.28001 17.55 5.18001 17.88 5.40001C18.21 5.61001 18.31 6.05001 18.09 6.38001L10.38 18.38C10.26 18.57 10.05 18.69 9.83001 18.7C9.82001 18.7 9.80001 18.7 9.79001 18.7V18.71Z" fill="currentColor" class="stroked"></path><path d="M9.79001 18.71C9.58001 18.71 9.38001 18.62 9.24001 18.45L4.95001 13.31C4.70001 13.01 4.74001 12.56 5.04001 12.31C5.34001 12.06 5.79001 12.1 6.04001 12.4L9.71001 16.8L16.9 5.61001C17.11 5.28001 17.55 5.18001 17.88 5.40001C18.21 5.61001 18.31 6.05001 18.09 6.38001L10.38 18.38C10.26 18.57 10.05 18.69 9.83001 18.7C9.82001 18.7 9.80001 18.7 9.79001 18.7V18.71Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
