import React, { useMemo } from 'react';
import { flexRender, getCoreRowModel, useReactTable, } from '@tanstack/react-table';
import { Placeholder, Table } from 'react-bootstrap';
import classNames from 'classnames';
import './DataTable.scss';
import '@horizon/icons/individual/hzn-arrow-up';
import '@horizon/icons/individual/hzn-arrow-down';
const headerSort = (header) => (React.createElement("hzn-text", null, {
    asc: React.createElement("hzn-icon-arrow-up", { size: 'small' }),
    desc: React.createElement("hzn-icon-arrow-down", { size: 'small' }),
}[header.column.getIsSorted()] ?? null));
export const DataTable = (props) => {
    const dataMemo = React.useMemo(() => (props.loading && props.data.length == 0 ? Array(7).fill({}) : props.data), [props.loading, props.data]);
    const columnsMemo = useMemo(() => props.loading
        ? props.columns.map(column => ({
            ...column,
            cell: () => (React.createElement(Placeholder, { animation: "wave" },
                React.createElement(Placeholder, { className: "d-block", bg: 'secondary' }))),
        }))
        : props.columns, [props.loading, props.columns]);
    const onSortingChange = (updater) => {
        const newSort = updater instanceof Function ? updater(props.sort) : updater;
        // Column sort will cycle between asc -> desc -> "default" (empty). Short circuit this behavior to just toggle between asc/desc.
        const resolvedSort = newSort.length == 0 ? [{ ...props.sort[0], desc: false }] : newSort;
        props.onSortChange(resolvedSort);
    };
    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        getCoreRowModel: getCoreRowModel(),
        manualSorting: !!props.onSortChange ? true : undefined,
        state: props.sort ? { sorting: props.sort } : undefined,
        onSortingChange: props.onSortChange ? onSortingChange : undefined,
    });
    return (React.createElement("hzn-card", { "border-radius": "medium", elevation: "flat" },
        React.createElement(Table, { className: "data-table", hover: true, "aria-label": props.label },
            React.createElement("thead", null, table.getHeaderGroups().map(headerGroup => (React.createElement("tr", { className: "summary-header", key: headerGroup.id }, headerGroup.headers.map(header => (React.createElement("th", { key: header.id, className: "summary-header-cell", onClick: header.column.getToggleSortingHandler() },
                React.createElement("div", { className: header.column.getCanSort() ? 'sortable d-flex gap-2 align-items-baseline' : '' },
                    flexRender(header.column.columnDef.header, header.getContext()),
                    props.onSortChange && headerSort(header))))))))),
            React.createElement("tbody", null, table.getRowModel().rows.map(row => (React.createElement("tr", { className: classNames('summary-row', !!props.onRowClick ? 'has-click-action' : ''), key: row.id, onClick: () => !!props.onRowClick && props.onRowClick(row.original) }, row.getVisibleCells().map(cell => (React.createElement("td", { className: "summary-row-cell", key: cell.id }, flexRender(cell.column.columnDef.cell, cell.getContext())))))))))));
};
