import React, { useCallback, useRef } from 'react';
import { useEventListener } from '../../hooks/useEventListener';
import { updateTerm } from './BasketSearchSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { selectBasketSearchQuery } from './BasketSearchSlice';
import { useAppSelector } from '../../hooks/useAppSelector';
export const BasketSearchTerm = () => {
    const dispatch = useAppDispatch();
    const { term } = useAppSelector(selectBasketSearchQuery);
    const ref = useRef(undefined);
    const onUpdateTerm = useCallback((input) => {
        dispatch(updateTerm(input.value ?? undefined));
    }, [dispatch]);
    //@ts-ignore non-standard 'search' event is part of Horizon spec
    useEventListener(ref, 'search', onUpdateTerm);
    //@ts-ignore non-standard 'clear' event is part of Horizon spec
    useEventListener(ref, 'clear', onUpdateTerm);
    useEventListener(ref, 'blur', onUpdateTerm);
    useEventListener(ref, 'keypress', (target, event) => {
        if (event.key === "Enter") {
            onUpdateTerm(target);
        }
    });
    return (React.createElement("hzn-search-input", { ref: ref, value: term, "interactive-icon": true, clearable: true, placeholder: "Search carts by Customer Name or VIN" },
        React.createElement("span", { className: "visually-hidden" }, "Search term")));
};
