import { useEventListener } from '../../hooks/useEventListener';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
const Input = forwardRef(({ label, className, onValueChanged, errorMessages, ...attrs }, fwdRef) => {
    const inputRef = useRef();
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.errorMessages = errorMessages;
        }
    }, [inputRef, errorMessages]);
    const onChange = useCallback((target) => onValueChanged(target.value === '' ? undefined : target.value), [onValueChanged]);
    useEventListener(inputRef, 'input', onChange);
    useEventListener(inputRef, 'change', onChange);
    // hook for external validation invocation
    useImperativeHandle(fwdRef, () => ({
        validate: () => {
            if (!inputRef.current)
                return false;
            inputRef.current.checkValidity();
            return inputRef.current.validity.valid;
        },
    }));
    return (React.createElement("hzn-input", { ref: inputRef, class: className, name: label, "display-name": label, ...attrs }, label));
});
export default Input;
