import React, { useEffect } from 'react';
import { BasketSearchTerm } from './BasketSearchTerm';
import { selectBasketSearchQuery, updatePageNumber, selectBasketSearchRequest, updatePageSize, updateSellingLocation, } from './BasketSearchSlice';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { NoResultsAlert } from './NoResultsAlert';
import { BasketSearchResults } from './BasketSearchResults';
import { Pager } from '../../components/pager/Pager';
import { BasketPaymentStateFilter } from './BasketPaymentStateFilter';
// import { logger } from '../../common/logger';
import { useParams } from 'react-router-dom';
import { useGetBasketSummariesQuery } from '@src/api/CheckoutApi';
export const BasketSearchPage = ({ launchPosClient = false, storeLocationIdFromProps = undefined }) => {
    usePageTitle('Cart Search');
    const { storeLocationId } = useParams();
    const dispatch = useAppDispatch();
    const searchQuery = useAppSelector(selectBasketSearchQuery);
    const searchRequest = useAppSelector(selectBasketSearchRequest);
    const searchResult = useGetBasketSummariesQuery(searchRequest, {
        refetchOnMountOrArgChange: true,
        skip: !searchRequest?.sellingLocationId
    });
    useEffect(() => {
        dispatch(updateSellingLocation(storeLocationIdFromProps ?? storeLocationId));
    }, [storeLocationIdFromProps, storeLocationId]);
    return (React.createElement("div", { className: "d-flex flex-column gap-4" },
        React.createElement("div", { className: "d-flex flex-row gap-4 align-items-center" },
            React.createElement("hzn-heading", { as: "h2", size: "small", class: "w-25" }, "Checkout"),
            React.createElement("div", { className: "ms-auto w-50" },
                React.createElement(BasketSearchTerm, null)),
            React.createElement("div", { className: "w-25" },
                React.createElement(BasketPaymentStateFilter, null))),
        React.createElement("div", { className: "d-flex flex-column gap-4" },
            searchResult.isError && (React.createElement("hzn-alert", { tone: "critical", heading: "Cart search error", "sub-heading": JSON.stringify(searchResult.error) })),
            !searchResult.isFetching && searchResult.isSuccess && searchResult.data && searchResult.data.totalCount === 0 && (React.createElement("div", { className: "pt-5" },
                React.createElement(NoResultsAlert, { clearable: !!searchQuery.term || !!searchQuery.paymentState }))),
            (searchResult.isFetching || (searchResult.isSuccess && searchResult.data && searchResult.data.totalCount !== 0)) && (React.createElement(BasketSearchResults, { query: searchQuery.term, loading: searchResult.isFetching, results: searchResult?.data, launchPosClient: launchPosClient })),
            searchResult.isSuccess && searchResult.data && searchResult.data.totalCount !== 0 && (React.createElement(Pager, { pageNumber: searchQuery.page.pageNumber, totalCount: searchResult.data.totalCount, onPageChange: page => dispatch(updatePageNumber(page)), onItemsPerPageChange: itemsPerPage => dispatch(updatePageSize(itemsPerPage)) })))));
};
