
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-chevron-left
 * @tagname hzn-icon-chevron-left
 * @summary An individual icon for Horizon
 */
export class HznIconChevronLeft extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M14.18 4.92999C14.36 4.92999 14.54 4.99999 14.68 5.13999C14.96 5.41999 14.96 5.86999 14.68 6.13999L8.81998 12L14.68 17.86C14.96 18.14 14.96 18.59 14.68 18.86C14.4 19.13 13.95 19.14 13.68 18.86L7.31999 12.5C7.03999 12.22 7.03999 11.77 7.31999 11.5L13.68 5.13999C13.82 4.99999 14 4.92999 14.18 4.92999Z" fill="currentColor" class="stroked"></path><path d="M14.18 4.92999C14.36 4.92999 14.54 4.99999 14.68 5.13999C14.96 5.41999 14.96 5.86999 14.68 6.13999L8.81998 12L14.68 17.86C14.96 18.14 14.96 18.59 14.68 18.86C14.4 19.13 13.95 19.14 13.68 18.86L7.31999 12.5C7.03999 12.22 7.03999 11.77 7.31999 11.5L13.68 5.13999C13.82 4.99999 14 4.92999 14.18 4.92999Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
