import { LitElement, svg, TemplateResult } from '@horizon/base';
import { unsafeStatic, html } from '@horizon/base/static-html.js';
import { property, query } from '@horizon/base/decorators.js';
import { classMap, ifDefined, live } from '@horizon/base/directives.js';
import { nothing } from '@horizon/base/html.js';
import 'element-internals-polyfill';
import { submit } from '@open-wc/form-helpers';

import ButtonStyles from './button.css.js';

import {
  HznButtonTone,
  HznButtonType,
  HznButtonVariant
} from '../types.js';

const loadingSpinner = svg`<svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
</svg>`;

/**
 *
 * @tag hzn-button
 * @tagname hzn-button
 * @summary A component for making a buttons or button-styled links
 *
 * @fires {HznButtonClickEvent} click - Emitted when the button is clicked
 */

export class HznButton extends LitElement {

  /**
   * @private
   */
  #internals!: ElementInternals;

  static formAssociated = true;

  static styles = [ButtonStyles];

  static shadowRootOptions:ShadowRootInit = {...LitElement.shadowRootOptions, mode: 'open', delegatesFocus: true};

  /**
   * The internal native button or anchor element
   */
  @query('.button') innerButton!: HTMLButtonElement | HTMLAnchorElement;

  /**
   * Sets the variant of button/anchor
   * @playroomValues {'cta' | 'primary' | 'secondary' | 'tertiary'}
   */
  @property({ type: String }) variant: HznButtonVariant = 'cta';

  /**
   * Sets the tone for the selected variant
   * @playroomValues {'interactive' | 'critical' | 'inverse'}
   */
  @property({ type: String }) tone: HznButtonTone = 'interactive';

  /**
   * When true, reduces height of button/anchor
   */
  @property({ type: Boolean }) compact?: boolean = false;

  /**
   * Disables the button/anchor
   */
  @property({ type: Boolean, reflect: true }) disabled?: boolean = false;

  /**
   * When true, shows loading animation for button/anchor
   */
  @property({ type: Boolean }) loading?: boolean = false;

  /**
   * Passes a download prop to the button/anchor
   */
  @property({ type: String }) download?: string;

  /**
   * Passes a href prop to the anchor, changes tag to anchor
   */
  @property({ type: String }) href?: string;

  /**
   * Passes a target prop to the anchor
   */
  @property({ type: String }) target?: string;

  /**
   * Passes a rel prop to the anchor
   */
  @property({ type: String }) rel?: string;

  /**
   * Changes the width from content to container size by changing display from inline-block to block
   */
  @property({ type: Boolean, reflect: true, attribute: 'full-width' }) fullWidth?: boolean = false;

  /**
   * Sets the name used for the click event from button
   */
  @property({ type: String }) name?: string;

  /**
   * Passes a type property to the button tag
   * @playroomValues {'button' | 'submit' | 'reset'}
   */
  @property({ type: String }) type?: HznButtonType;

  /**
   * Passes an aria-label property to the button tag
   */
  @property({ type: String, attribute: 'hzn-aria-label' }) ariaLabel!: string;

  /**
   * Passes a title property to the button tag
   */
  @property({ type: String, attribute: 'hzn-title' }) title!: string;

  /**
   * Passes a value property to the button tag
   */
  @property({ type: String }) value?: string;

  connectedCallback() {
    super.connectedCallback();
    // strictly speaking internals should be set in constructor
    // but React has a coniption sometimes if you do things in constructor
    // so we set internals in connectedCallback, but
    // add a check so that we only attach if we haven't yet
    if (!this.#internals) {
      this.#internals = this.attachInternals();
    }
  }

  /**
   * Simulates a click on the button
   */
  click() {
    if (this.innerButton) {
      this.innerButton.click();
    }
  }

  /**
   * Focus the innerButton
   */
  focus(options?: FocusOptions) {
    this.innerButton.focus(options);
  }

  /**
   * Remove focus from the innerButton
   */
  blur() {
    this.innerButton.blur();
  }

  /**
   * @private
   */
  #handleClick() {

    if (!this.loading && !this.disabled) {
      // if there is no parent form element, just return
      if (!this.#internals.form) {
        return;
      }

      // there is a parent form, so check for the type
      if (this.type === 'submit' || !this.type) {
        // submit the parent form
        submit(this.#internals.form);
      } else if (this.type === 'reset') {
        // clear the parent form
        this.#internals.form.reset();
      }
    }
  }

  /**
   * @private
   */
  get isLoading() {
    return this.loading && !this.disabled;
  }

  render(): TemplateResult {
    return html`<${unsafeStatic(this.href ? 'a' : 'button')}
        class=${classMap({
          button: true,
          'is-compact': Boolean(this.compact),
          'is-loading': Boolean(this.loading && !this.disabled),
          'is-disabled': Boolean(this.disabled),
          'is-full-width': Boolean(this.fullWidth),
          'is-variant-cta': this.variant === 'cta',
          'is-variant-primary': this.variant === 'primary',
          'is-variant-secondary': this.variant === 'secondary',
          'is-variant-tertiary': this.variant === 'tertiary',
          'is-tone-interactive': this.tone === 'interactive',
          'is-tone-critical': this.tone === 'critical',
          'is-tone-inverse': this.tone === 'inverse',
        })}
        @click=${ifDefined(this.href ? undefined : this.#handleClick)}
        type=${ifDefined(this.type)}
        href=${ifDefined(this.href)}
        title=${ifDefined(this.title)}
        aria-label=${ifDefined(this.ariaLabel)}
        download=${ifDefined(this.href ? this.download : undefined)}
        target=${ifDefined(this.href ? this.target : undefined)}
        rel=${ifDefined(this.rel ? `${this.rel}` : ifDefined(this.target ? 'noreferrer noopener' : undefined))}
        ?disabled=${live(this.disabled || this.loading)}
        role="${ifDefined(!this.href ? 'button' : undefined)}"
        name="${ifDefined(this.name)}"
        value="${ifDefined(this.value)}"
      >
      ${!this.isLoading ? html`<slot name="leading-icon"></slot>` : nothing}
      <span class="button-label">
        <slot>Button</slot>
      </span>
      ${!this.isLoading ? html`<slot name="trailing-icon"></slot>` : nothing}
      ${this.isLoading ? html`<span class="button-loading-svg">${loadingSpinner}</span>` : nothing}
    </${unsafeStatic(this.href ? 'a' : 'button')}>`;
  }
}
