/**
 * NOTE: Do not remove this file!
 * It is referenced by openapi-config.ts and is used the 'generate:client' script in package.json to generate CheckoutApi.ts.
 * This is the API client used by react-toolkit / rtk-query.
 * See also: https://redux-toolkit.js.org/rtk-query/usage/code-generation#openapi
 */
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { handleTokenResponseData } from '@src/helpers/OAuthHelpers';
import { OAUTH_PROXY, PROXY_NAMES } from '../config';
import { logger } from '@src/common/logger';
// this custom fetch function will route our API requests through the API proxy.
const fetchFn = async (input, _init) => {
    // RequestInfo can be of type 'string' or 'Request'.
    // Handle the 'string' case with a fast return.
    if (typeof input === 'string') {
        console.error('[FetchFn] Unexpected input value. String input is unhandled.');
        throw new Error("The 'input' request should never be a string.");
    }
    const request = input;
    const reqUrl = new URL(request.url);
    const reqPath = reqUrl.pathname + reqUrl.search;
    let headers = request.headers;
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    headers.set('Accept-Encoding', 'deflate, gzip');
    const body = request.body ? await request.text() : null;
    let fetchUrl = ''; // resolved in switch statement below.
    switch (sessionStorage.getItem('com.unboundid.kmxpos.proxy')) {
        case PROXY_NAMES.mock: {
            fetchUrl = 'http://localhost:3553' + reqPath;
            break;
        }
        case PROXY_NAMES.pathfinders_oauthproxy: {
            fetchUrl = OAUTH_PROXY.apiProxyUri + reqPath;
            headers.set('Authorization', `Bearer ${sessionStorage.getItem('com.unboundid.kmxpos.access_token').replace('Bearer', '').trim()}`);
            headers.set('id_token', sessionStorage.getItem('com.unboundid.kmxpos.id_token'));
            headers.set('refresh_token', sessionStorage.getItem('com.unboundid.kmxpos.refresh_token'));
            break;
        }
        case PROXY_NAMES.bon_apim: {
            fetchUrl = sessionStorage.getItem('com.unboundid.kmxpos.proxyUrl') + reqPath;
            headers.set('Authorization', `Bearer ${sessionStorage.getItem('busOpsNowBearerToken').replace('Bearer', '').trim()}`);
            break;
        }
        default: {
            throw new Error('Unrecognized proxy');
        }
    }
    try {
        return await fetch(fetchUrl, { method: request.method, headers, body });
    }
    catch (ex) {
        logger.logException(ex);
        throw ex;
    }
};
// Parse the JSON response data (if any) and inspect it for a refreshed auth token.
// Return the response data without the refresh token info.
const responseHandler = async (response) => {
    // parse the response data as JSON
    try {
        const body = response.headers.get('content-type')?.startsWith('application/json')
            ? await response.json()
            : undefined;
        // look for updated tokens
        if (response.headers.has('OAP_access_token'))
            handleTokenResponseData({
                access_token: response.headers.get('OAP_access_token'),
                id_token: response.headers.get('OAP_id_token'),
                refresh_token: response.headers.get('OAP_refresh_token'),
            });
        return body;
    }
    catch (ex) {
        logger.logException(ex);
        throw ex;
    }
};
// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
    baseQuery: retry(fetchBaseQuery({
        fetchFn,
        responseHandler,
    }), { maxRetries: 2 }),
    endpoints: () => ({}),
});
