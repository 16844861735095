import '@horizon/icons/individual/hzn-plus';
import { Card } from '@src/components/Card';
import { StepTitleHeader } from '@src/pages/BasketCheckout/Payment/StepTitleHeader/StepTitleHeader';
import React, { useMemo } from 'react';
import { usePageTitle } from '@src/hooks/usePageTitle';
import { NegativeEquity } from './NegativeEquity';
import { PositiveEquity } from './PositiveEquity';
import { HorizonButton } from '@src/components/horizon/HorizonButton';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, FlexRow } from '@src/components/Flex';
import { getTotalEquity } from '@src/utils/BasketFns';
const resolveStatus = (equity, processedPaymentsAmount) => equity >= 0 || processedPaymentsAmount === Math.abs(equity) // basket is positive equity, or all negative equity has been paid.
    ? 'Completed'
    : processedPaymentsAmount > 0 // some negative equity has been paid.
        ? 'PartiallyComplete'
        : 'InProgress'; // no negative equity has been paid.
export const Payment = ({ basket }) => {
    usePageTitle('Payment');
    const navigate = useNavigate();
    const totalEquity = useMemo(() => getTotalEquity(basket), [basket]);
    const status = resolveStatus(totalEquity, basket.customerProcessedPaymentAmount);
    return (React.createElement(FlexColumn, { className: "gap-4" },
        React.createElement(Card, { padBody: "p-4" },
            React.createElement(StepTitleHeader, { title: 'Payment', status: status }),
            totalEquity < 0 && React.createElement(NegativeEquity, { basket: basket }),
            totalEquity >= 0 && React.createElement(PositiveEquity, { basket: basket })),
        React.createElement(FlexRow, { className: "justify-content-end" },
            React.createElement(HorizonButton, { variant: "cta", disabled: status !== 'Completed', onClick: () => navigate('tenderOrder') }, "Continue"))));
};
