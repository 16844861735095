
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-show
 * @tagname hzn-icon-show
 * @summary An individual icon for Horizon
 */
export class HznIconShow extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.79004C16.69 3.79004 20.11 6.86004 21.75 9.73004C22.55 11.13 22.55 12.87 21.75 14.27C20.12 17.14 16.69 20.21 12 20.21C7.31002 20.21 3.89002 17.14 2.25002 14.27C1.45002 12.87 1.45002 11.13 2.25002 9.73004C3.88002 6.86004 7.31002 3.79004 12 3.79004ZM12 18.79C16.09 18.79 19.08 16.09 20.52 13.57C21.07 12.6 21.07 11.4 20.52 10.43C19.08 7.91004 16.09 5.21004 12 5.21004C7.91002 5.21004 4.92002 7.91004 3.48002 10.43C2.93002 11.4 2.93002 12.6 3.48002 13.57C4.92002 16.09 7.91002 18.79 12 18.79ZM8.29004 12C8.29004 9.95004 9.95004 8.29004 12 8.29004C14.05 8.29004 15.71 9.95004 15.71 12C15.71 14.05 14.05 15.71 12 15.71C9.95004 15.71 8.29004 14.05 8.29004 12ZM9.71004 12C9.71004 13.26 10.74 14.29 12 14.29C13.26 14.29 14.29 13.26 14.29 12C14.29 10.74 13.26 9.71004 12 9.71004C10.74 9.71004 9.71004 10.74 9.71004 12Z" fill="currentColor" class="stroked"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 3.79004C16.69 3.79004 20.11 6.85999 21.7501 9.72998C22.5501 11.13 22.5501 12.87 21.7501 14.27C20.1201 17.14 16.69 20.21 12.0001 20.21C7.31012 20.21 3.89008 17.14 2.25006 14.27C1.45001 12.87 1.45001 11.13 2.25006 9.72998C3.88007 6.85999 7.31012 3.79004 12.0001 3.79004ZM8.2901 12C8.2901 14.05 9.95001 15.71 12.0001 15.71C14.0501 15.71 15.71 14.05 15.71 12C15.71 9.94995 14.0501 8.29004 12.0001 8.29004C9.95001 8.29004 8.2901 9.94995 8.2901 12ZM14.2901 12C14.2901 13.2648 13.2648 14.29 12.0001 14.29C10.7353 14.29 9.71002 13.2648 9.71002 12C9.71002 10.7352 10.7353 9.70996 12.0001 9.70996C13.2648 9.70996 14.2901 10.7352 14.2901 12Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
