import React from 'react';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { reset, selectPayment, setPaymentDetails, setProcessStep } from '@src/store/PaymentSlice';
import { Modal } from 'react-bootstrap';
import { ProcessPayment } from '../Common/ProcessPayment';
import { ConfirmCheckAmount } from './ConfirmCheckAmount';
import { CheckPaymentDescription } from './CheckPaymentDescription';
import { ScanCheck } from '../Common/ScanCheck';
/**
 * ProcessCheckModal is a modal wizard for processing check payments.
 * This component provides the modal shell, presents the modal content for
 *   each wizard step, and orchestrates control flow for the steps.
 */
export const ProcessCheckModal = ({ show }) => {
    const dispatch = useAppDispatch();
    const { step } = useAppSelector(selectPayment);
    return (React.createElement(Modal, { centered: true, show: show },
        show && step === 'Start' && (
        // Confirm the check amount and number
        React.createElement(ConfirmCheckAmount, { onContinue: checkNumber => {
                dispatch(setPaymentDetails({ checkNumber }));
                dispatch(setProcessStep('Scan'));
            }, onCancel: () => dispatch(reset()) })),
        show && step === 'Scan' && (
        // Prompt the associate to scan the check
        React.createElement(ScanCheck, { onContinue: () => dispatch(setProcessStep('Process')), onCancel: () => dispatch(reset()) },
            React.createElement(CheckPaymentDescription, null))),
        show && step === 'Process' && (
        // Submit the payment for processing
        React.createElement(ProcessPayment, { onComplete: () => dispatch(reset()), onCancel: () => dispatch(reset()) },
            React.createElement(CheckPaymentDescription, null)))));
};
