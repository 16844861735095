import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { getClientAppConfig } from './clientConfig';
const clientAppConfig = getClientAppConfig();
const reactPlugin = new ReactPlugin();
let appInsights = undefined;
if (clientAppConfig?.applicationInsightsInstrumentationKey?.length > 0) {
    console.log("Initializing Application Insights client");
    appInsights = new ApplicationInsights({
        config: {
            appId: 'checkout-web',
            connectionString: clientAppConfig.applicationInsightsInstrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {},
            enableAutoRouteTracking: true,
            enableUnhandledPromiseRejectionTracking: true,
            disableAjaxTracking: false,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
        },
    });
    appInsights.loadAppInsights();
}
else {
    console.log("Skipping Application Insights client initialization. No connection string found in client app config.");
}
export { reactPlugin, appInsights };
