import React, { useMemo } from 'react';
import { Card, CardRow } from './Card';
import { AsCurrency } from '@src/utils/Formatting';
import { getNegativeEquityBalance, getTotalEquity, getVehicleName } from '@src/utils/BasketFns';
export const CartDetails = ({ basket }) => {
    const totalEquity = useMemo(() => getTotalEquity(basket), [basket]);
    const balanceDue = useMemo(() => (totalEquity >= 0 ? AsCurrency(totalEquity) : AsCurrency(getNegativeEquityBalance(basket))), [basket]);
    return (React.createElement(Card, null,
        React.createElement("div", { className: "d-flex flex-column gap-4" },
            React.createElement(CardRow, { label: React.createElement("hzn-text", { weight: "bold", size: "small" }, "CHECKOUT"), value: 
                // TODO: cart states aren't yet hashed out. figure out what this should look like
                // after those requirements are established and codified in the API.
                // see example of paymentStateBadge for UI guidance when this is available.
                React.createElement("hzn-badge", { label: "In Progress", tone: "info" }) }),
            React.createElement("div", null,
                React.createElement("hzn-text", { weight: "bold", size: "large" }, basket.basketOwnerName)),
            React.createElement("hzn-divider", null),
            React.createElement(CardRow, { label: "Appraisal purchase", value: getVehicleName(basket) }),
            React.createElement(CardRow, { label: `Total due to ${totalEquity <= 0 ? 'CarMax' : 'Customer'}`, value: AsCurrency(Math.abs(totalEquity)) }),
            React.createElement(CardRow, { label: "Processed payments", value: AsCurrency(basket.customerProcessedPaymentAmount) }),
            React.createElement(CardRow, { label: `Balance due to ${totalEquity <= 0 ? 'CarMax' : 'Customer'}`, value: React.createElement("hzn-text", { weight: "bold", size: "large" }, balanceDue) }))));
};
