import React, { useCallback, useRef } from 'react';
import { FlexColumn } from '../Flex';
import { useEventListener } from '../../hooks/useEventListener';
export const Pager = ({ pageNumber, totalCount, onPageChange, onItemsPerPageChange, itemName }) => {
    const ref = useRef();
    const pageChangeListener = useCallback((_, ev) => {
        onPageChange(ev.detail.newCurrentPage);
    }, [onPageChange]);
    const itemsPerPageChangeListener = useCallback((_, ev) => {
        onItemsPerPageChange(ev.detail.newItemsPerPage);
    }, [onItemsPerPageChange]);
    // hzn-pagination events: https://horizon.carmax.com/build/components/pagination/code/#events-and-payloads
    //@ts-ignore hzn-pagination has synthtetic 'page-change' event
    useEventListener(ref, 'page-change', pageChangeListener);
    //@ts-ignore hzn-pagination has synthtetic 'next' event
    useEventListener(ref, 'next', pageChangeListener);
    //@ts-ignore hzn-pagination has synthtetic 'previous' event
    useEventListener(ref, 'previous', pageChangeListener);
    //@ts-ignore hzn-pagination has synthtetic 'items-per-page-change' event
    useEventListener(ref, 'items-per-page-change', itemsPerPageChangeListener);
    return (React.createElement(FlexColumn, { className: "align-items-center" },
        React.createElement("hzn-pagination", { class: "pager", ref: ref, "item-name": itemName, "total-items": totalCount, "current-page": pageNumber, "items-per-page-options": "[25]" })));
};
