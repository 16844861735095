import { LitElement, ReactiveElement, TemplateResult } from '@horizon/base';
import { nothing, html } from '@horizon/base/html.js';
import { property, query } from '@horizon/base/decorators.js';
import { classMap } from '@horizon/base/directives';
import { ScopedElementsMap, ScopedElementsMixin } from '@open-wc/scoped-elements/lit-element.js';
import { eventEmitter } from '@horizon/common/events';
import { HasSlotController } from '@horizon/common/controllers';
import { HznStack } from '@horizon/stack';
import { HznText } from '@horizon/text';
import {
  HznIconWarning,
  HznIconInfoCircle,
  HznIconCheck,
  HznIconClose,
  HznIconDanger,
} from '@horizon/icons/individual';

import AlertStyles from './alert.css.js';
import { HznAlertTone, HznAlertActionElement} from '../types.js';



/**
 *
 * @tag hzn-alert
 * @tagname hzn-alert
 * @summary A messaging component for displaying page or section level information and errors
 *
 * @fires {HznAlertActionEvent} action - Emitted when an alert action is present and clicked
 * @fires {HznAlertDismissEvent} dismiss - Preventable. Emitted when an alert's dismiss button is present and clicked. When not prevented, automatically sets the `show` property to false. When prevented, does not automatically set the `show` property
 */
export class HznAlert extends (ScopedElementsMixin(LitElement) as typeof ReactiveElement) {
  static styles = [AlertStyles];

  /**
   * The inner button element that dismisses the alert
   */
  @query('.alert-dismiss-button') innerDismissButton!: HTMLButtonElement;

  /**
   * The inner button element that is the action element
   */
  @query('.alert-action') innerActionButton!: HTMLButtonElement | HTMLAnchorElement;

  /**
   * Sets the show for alert
   */
  @property({ type: Boolean, reflect: true }) show?: boolean = false;

  /**
   * Sets the tone for alert
   * @playroomValues {'critical' | 'caution' | 'info' | 'neutral' | 'positive' | 'flagged'}
   */
  @property({ type: String }) tone: HznAlertTone = 'info';

  /**
   * When true, elevated for alert
   */
  @property({ type: Boolean }) elevated?: boolean = false;

  /**
   * When true, dismissible for alert
   */
  @property({ type: Boolean }) dismissible?: boolean = false;

  /**
   * Sets the heading for alert
   */
  @property({ type: String }) heading = '';

  /**
   * Sets the sub-heading for alert
   */
  @property({ type: String, attribute: 'sub-heading' }) subHeading?: string = '';

  /**
   * Sets the display text of the action
   */
  @property({ type: String, attribute: 'action-text' }) actionText?: string = '';

  /**
   * Set the tag for the action element according to what will happen when it is clicked
   * @playroomValues {'button' | 'a'}
   */
  @property({ type: String, attribute: 'action-element' }) actionElement?: HznAlertActionElement = 'button';

  private readonly hasSlotController = new HasSlotController(this, '[default]', 'leading-icon');

  /**
   * Programmatically dismiss the alert
   */
  dismiss() {
    if (this.innerDismissButton) {
      this.innerDismissButton.click();
    }
  }

  /**
   * Programmatically take the action if it exists
   */
  takeAction() {
    if (this.innerActionButton) {
      this.innerActionButton.click();
    }
  }

  /**
   * @private
   */
  #emit = eventEmitter(this);

  /**
   * @private
   */
  static get scopedElements(): ScopedElementsMap {
    return {
      'hzn-text': HznText,
      'hzn-stack': HznStack,
      'hzn-icon-warning': HznIconWarning,
      'hzn-icon-check': HznIconCheck,
      'hzn-icon-info-circle': HznIconInfoCircle,
      'hzn-icon-close': HznIconClose,
      'hzn-icon-danger': HznIconDanger,
    };
  }


  /**
   * @private
   */
  #handleClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    // emit action event
    this.#emit({ type: 'action' });
  }

  /**
   * @private
   */
  #handleDismiss($event: MouseEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    // emit action event
    this.#emit({
      type: 'dismiss',
      callback() {
        (this as HznAlert).show = false;
      },
    });
  }

  /**
   * @private renders the alert icon based on tone
   */
  #renderIconSlot() {
    if (this.tone === 'critical') {
      return html`<hzn-icon-danger class="alert-icon"></hzn-icon-danger>`;
    } else if (this.tone === 'caution') {
      return html`<hzn-icon-warning class="alert-icon"></hzn-icon-warning>`;
    } else if (this.tone === 'positive') {
      return html`<hzn-icon-check class="alert-icon"></hzn-icon-check>`;
    } else if (this.tone === 'info' || this.tone === 'neutral' || this.tone === 'flagged') {
      return this.hasSlotController.test('leading-icon')
        ? html` <span class="alert-icon">
            <slot name="leading-icon"></slot>
          </span>`
        : html`<hzn-icon-info-circle class="alert-icon"></hzn-icon-info-circle>`;
    }
  }

  /**
   * @private
   */
  #renderAction() {
    const hasSubhead = this.subHeading;

    if(this.actionText) {
      let action;
      switch(this.actionElement) {
        case 'button':
          action = html`<button @click=${this.#handleClick} class="alert-action ${hasSubhead ? 'has-subhead' : ''}">${this.actionText}</button>`;
          break;
        case 'a':
          action = html`<a @click=${this.#handleClick} class="alert-action ${hasSubhead ? 'has-subhead' : ''}">${this.actionText}</a>`;
          break;
      }
      return action;
    }
    return nothing;
  }

  render(): TemplateResult {
    return html`
      <div
        role="status"
        class=${classMap({
          'alert': true,
          'is-elevated': Boolean(this.elevated),
          'has-heading-only': !this.subHeading && !this.actionText,
          'is-dismissible': Boolean(this.dismissible),
          'is-tone-critical': this.tone === 'critical',
          'is-tone-caution': this.tone === 'caution',
          'is-tone-neutral': this.tone === 'neutral',
          'is-tone-info': this.tone === 'info',
          'is-tone-positive': this.tone === 'positive',
          'is-tone-flagged': this.tone === 'flagged'
        })}
      >
        <div class="alert-icon-container">${this.#renderIconSlot()}</div>
        <hzn-stack class="alert-message">
          <p class="alert-heading">
            ${this.heading}
          </p>
          ${this.subHeading ? html`<hzn-text class="alert-sub-heading" size="base" tone="subdued">${this.subHeading}</hzn-text>` : nothing}
          ${this.#renderAction()}
        </hzn-stack>
        ${this.dismissible && this.tone !== 'critical'
        ? html`<button aria-label="Dismiss alert." class="alert-dismiss-button" @click=${this.#handleDismiss}>
              <hzn-icon-close></hzn-icon-close>
            </button>`
        : nothing}
      </div>
    `;
  }
}

