import { LitElement, TemplateResult } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { classMap } from '@horizon/base/directives';
import { unsafeStatic, html } from '@horizon/base/static-html.js';

import HeadingStyles from './heading.css.js';
import { HznHeadingAlign, HznHeadingAs, HznHeadingLineHeight, HznHeadingOn, HznHeadingSize, HznHeadingTone, headingTags } from '../types.js';

/**
 *
 * @tag hzn-heading
 * @tagname hzn-heading
 * @summary A text component for adding and styling heading tags
 */
export class HznHeading extends LitElement {
  static styles = [HeadingStyles];

  /**
   * Sets the text-align of text along the x-axis
   * @playroomValues {'left' | 'center' | 'right'}
   */
  @property({ type: String }) align?: HznHeadingAlign = 'left';

  /**
   * Sets the heading tag of the shadow element
   * @playroomValues {'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'}
   */
  @property({ type: String }) as?: HznHeadingAs = 'h2';

  /**
   * Sets the color of text within element
   * @playroomValues {'brand' | 'brand-accent' | 'inverse' | 'neutral' | 'subdued'}
   */
  @property({ type: String }) tone?: HznHeadingTone = 'brand';

  /**
   * Sets the font size of text within element
   * @playroomValues {'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'}
   */
  @property({ type: String }) size?: HznHeadingSize = 'medium';

  /**
   * Sets the line height of text within element
   * @playroomValues {'none' | 'xsmall' | 'small' | 'base' | 'large' | 'xlarge'}
   */
  @property({ type: String, attribute: 'line-height' }) lineHeight?: HznHeadingLineHeight = 'base';

  /**
   * Sets the background color that this heading is appearing on top of in order to change its color accordingly
   * @playroomValues {'brand' | 'brand-accent'}
   */
  @property({ type: String }) on?: HznHeadingOn;

  /**
   * When defined, sets font-size to not be fluid (css clamp) and not change size fluidly oacross responsive breakpoints
   */
  @property({ type: Boolean }) static?: boolean = false;

  render(): TemplateResult {
    return html`<${unsafeStatic(this.as && headingTags.includes(this.as) ? this.as : 'h2')}
      class=${classMap({
        heading: true,
        'is-static': Boolean(this.static),
        'is-align-left': this.align === 'left',
        'is-align-center': this.align === 'center',
        'is-align-right': this.align === 'right',
        'is-tone-brand': this.tone === 'brand',
        'is-tone-brand-accent': this.tone === 'brand-accent',
        'is-tone-inverse': this.tone === 'inverse',
        'is-tone-neutral': this.tone === 'neutral',
        'is-tone-subdued': this.tone === 'subdued',
        'is-size-xsmall': this.size === 'xsmall',
        'is-size-small': this.size === 'small',
        'is-size-medium': this.size === 'medium',
        'is-size-large': this.size === 'large',
        'is-size-xlarge': this.size === 'xlarge',
        'is-size-xxlarge': this.size === 'xxlarge',
        'is-lineheight-none': this.lineHeight === 'none',
        'is-lineheight-xsmall': this.lineHeight === 'xsmall',
        'is-lineheight-small': this.lineHeight === 'small',
        'is-lineheight-base': this.lineHeight === 'base',
        'is-lineheight-large': this.lineHeight === 'large',
        'is-lineheight-xlarge': this.lineHeight === 'xlarge',
        'is-on-brand': this.on === 'brand',
        'is-on-brand-accent': this.on === 'brand-accent',
      })}>
        <slot></slot>
    </${unsafeStatic(this.as && headingTags.includes(this.as) ? this.as : 'h2')}>`;
  }
}
