import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    cashDrawer: { assigned: undefined, ready: undefined },
    cardReader: { ready: undefined },
};
export const HardwareSlice = createSlice({
    name: 'hardware',
    initialState,
    reducers: {
        setCashDrawerStatus: (state, action) => {
            state.cashDrawer = action.payload;
        },
        setCardReaderStatus: (state, action) => {
            state.cardReader = action.payload;
        },
    },
});
export const { setCashDrawerStatus, setCardReaderStatus } = HardwareSlice.actions;
export const selectCashDrawerStatus = (state) => state.hardware.cashDrawer;
export const selectCardReaderStatus = (state) => state.hardware.cardReader;
export const selectCardReaderReady = (state) => state.hardware.cardReader.ready;
export default HardwareSlice.reducer;
