import { property } from '@horizon/base/decorators';
import IMask from '@horizon/base/imask';
import { HznInput, bindEvents } from '@horizon/input';
import { HznMaskedInputEager, HznMaskedInputOverwrite } from '../types.js';

export const maskOptions = Symbol('_maskOptions');
export const dynamic = Symbol('_dynamic');
export { hasNoSlottedIcon, renderIconSlot } from '@horizon/input';


/**
 *
 * @tag hzn-masked-input
 * @tagname hzn-masked-input
 * @summary An input that can restrict user input according to a provided mask or pattern
 *
 * @fires {HznMaskedInputInputEvent} change - Emitted when the input is changed
 * @fires {HznMaskedInputChangeEvent} change - Emitted when the input is changed
 * @fires {HznMaskedInputClearEvent} clear - Emitted when the input is clearable and the clear button is clicked
 */
export class HznMaskedInput extends HznInput {

  // pseudo private options for IMask
  // so that extension components can pass different options
  /**
   * @private
   */
  [maskOptions] = {} as IMask.AnyMaskedOptions;

  // pseudo private options that enables
  // the mask and pattern setters to recalculate the IMask options
  // when changed dynamically.
  /**
   * @private
   */
  [dynamic] = true;

  /**
   * @private
   */
  #maskValue?: string;

  /**
   * @private
   */
  #patternValue!: string;

  /**
   * @private
   */
  #maskRef!: IMask.InputMask<IMask.AnyMaskedOptions>;

  /**
   * @private
   */
  #rawValue!: string;



  // PUBLIC
  // getter only for the rawValue so that it cant be set form the outside
  get rawValue() {
    return this.#rawValue;
  }

  /**
   * The masked input's mask string to mask against
   */
  @property({ type: String })
  get mask(): string | undefined {
    return this.#maskValue;
  }
  set mask(newValue: string | undefined) {
    const oldMask = this.mask;
    this.#maskValue = newValue;
    if (this.#maskRef && newValue && !this[dynamic]) {
      this.#maskRef.updateOptions({ mask: newValue, ...this[maskOptions] });
    }
    this.requestUpdate('mask', oldMask);
  }

  /**
   * The masked input's regex pattern to mask against
   */
  @property({ type: String })
  get pattern(): string {
    return this.#patternValue;
  }
  set pattern(newValue: string) {
    const oldValue = this.pattern;
    this.#patternValue = newValue;
    if (this.#maskRef && newValue && !this[dynamic]) {
      this.#maskRef.updateOptions({ mask: new RegExp(newValue, 'v'), ...this[maskOptions] });
    }
    this.requestUpdate('pattern', oldValue);
  }

  /**
   * Set the `eager` option of the imaskjs library to determine how mask characters are added when the user types : https://imask.js.org/guide.html#eager
   * @playroomValues { 'true' | 'false' | 'append' | 'remove'}
   */
  @property({ type: String, reflect: false }) eager: HznMaskedInputEager = 'append';

  /**
   * Set the `overwrite` option of the imaskjs library to determine how characters are changed when the user types : https://imask.js.org/guide.html#overwrite
   * @playroomValues { 'true' | 'false' | 'shift'}
   */
  @property({ type: String, reflect: false }) overwrite: HznMaskedInputOverwrite = 'true';


  connectedCallback() {
    super.connectedCallback();

    // overwrite if the type is password
    this.type = this.type === 'password' ? 'text' : this.type;
  }


  firstUpdated() {
    // typescript wants the options to represent a single version statically
    // even though this code will only ever start as a single type at runtime
    // @ts-ignore
    this.#maskRef = new IMask.InputMask(this.validationTarget, Object.assign(
      { mask: this.mask ? this.mask : new RegExp(this.pattern, 'v') },
      this[maskOptions],
      // if the mask property in options is an array, then its a dynamic mask
      // and overwrite/eager aren't supported at the top level config.
      Array.isArray(this[maskOptions].mask)
        ? {}
        // set global eager/overwrite either from provided maskOptions or the component props
        // with the maskOptions taking precedence
        : {
          eager: this[maskOptions].eager || this.#convertImaskConfig(this.eager),
          overwrite: this[maskOptions].overwrite || this.#convertImaskConfig(this.overwrite)
        }
    ));

    this.#maskRef.on('accept', () => {
      this.value = (this.validationTarget as HTMLInputElement).value;
      this.#rawValue = this.#maskRef.unmaskedValue;
    });

    // if there's an initial value then reset it according to
    // the masked value
    if (this.value) {
      this.#rawValue = this.#maskRef.unmaskedValue;

      if(this.value !== this.#maskRef.value) {
        this.value = this.#maskRef.value;
      }
    }

    // capture input event and prevent it
    // @ts-ignore
    this[bindEvents]();
    // add custom change listener
    // @ts-ignore
    this.addEventListener('input', this.#handleInput.bind(this));
    // @ts-ignore
    this.addEventListener('change', this.#handleChange.bind(this));

  }

  willUpdate() {
    // overwrite if the type is password
    this.type = this.type === 'password' ? 'text' : this.type;
  }

  /**
   * @private
   */
  #convertImaskConfig(booleanOrString: string): boolean | string {
    return ['true', 'false'].includes(booleanOrString) ? (booleanOrString.toLowerCase() === 'true') : booleanOrString
  }

  /**
   * @private
   */
  #handleInput() {
    // set rawValue on instance because safari has a bug where change events dont fire
    // if following an input even where the inputs value is overwritten.
    this.#rawValue = this.#maskRef.unmaskedValue;
  }

  /**
   * @private
   */
  #handleChange(event: CustomEvent) {
    event.detail.value = this.value;

    // set rawValue on instance because safari has a bug where change events dont fire
    // if following an input even where the inputs value is overwritten.
    this.#rawValue = this.#maskRef.unmaskedValue;
  }

  /**
   * @private
   */
  protected updated(changed: Map<string, unknown>): void {
    if (changed.has('value')) {
      if(this.#maskRef) {
        // synchronize the maskref's value with the value that is being programmatically set
        // updated runs after render, so the native input will already have a programmatic value set
        // so update maskref's value and then set the form value with the updated maskRef value
        this.#maskRef.updateValue();
        this.#rawValue = this.#maskRef.unmaskedValue;
        this.setValue(this.#maskRef.value);
      }

      // setting the mask/pattern will already update the value
      if (this.#maskRef && (!changed.has('mask') && !changed.has('pattern'))) {
        // setting the value programmatically doesnt fire events
        /// so imask doesnt know it needs to update so we manually
        // fire an uncomposed event that wont escape the shadow dom
        this.validationTarget.dispatchEvent(new CustomEvent('input', { composed: false }));
      }
    }
  }
}
