import { FlexRow } from '@src/components/Flex';
import { HorizonAlert } from '@src/components/horizon/HorizonAlert';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectPayment } from '@src/store/PaymentSlice';
import { AsCurrency } from '@src/utils/Formatting';
import React from 'react';
export const CertifiedFundsPaymentDescription = () => {
    const { payment, paymentDetails } = useAppSelector(selectPayment);
    const certifiedFundsDetails = paymentDetails;
    const refundDue = certifiedFundsDetails?.checkAmount > payment.amount
        ? certifiedFundsDetails.checkAmount - payment.amount
        : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement(FlexRow, { className: 'justify-content-between pt-3 ps-2 pe-2' },
            React.createElement("hzn-text", { tone: "subdued" }, "Payment method"),
            React.createElement("hzn-text", null, "Certified Funds")),
        React.createElement("hzn-divider", null),
        !certifiedFundsDetails && (React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
            React.createElement("hzn-text", { tone: "subdued" }, "Amount"),
            React.createElement("hzn-text", null, AsCurrency(payment.amount)))),
        certifiedFundsDetails && (React.createElement(React.Fragment, null,
            React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
                React.createElement("hzn-text", { tone: "subdued" }, "Check Amount"),
                React.createElement("hzn-text", null, AsCurrency(certifiedFundsDetails.checkAmount))),
            React.createElement("hzn-divider", null),
            React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
                React.createElement("hzn-text", { tone: "subdued" }, "Amount applied to balance"),
                React.createElement("hzn-text", null, AsCurrency(payment.amount))),
            React.createElement("hzn-divider", null),
            refundDue && (React.createElement(React.Fragment, null,
                React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
                    React.createElement("hzn-text", { tone: "subdued" }, "Mail refund due"),
                    React.createElement("hzn-text", { weight: "bold" }, AsCurrency(refundDue))),
                React.createElement("hzn-divider", null))),
            React.createElement(FlexRow, { className: 'justify-content-between ps-2 pe-2' },
                React.createElement("hzn-text", { tone: "subdued" }, "Check Number"),
                React.createElement("hzn-text", null, certifiedFundsDetails.checkNumber)),
            React.createElement(HorizonAlert, { show: !!refundDue, heading: "Mail refund will be issued automatically.", tone: "info", "sub-heading": "The refund due to customer will be mailed as a refund check, per CarMax policy. Customers should allow up to 14 business days to receive their refund." })))));
};
