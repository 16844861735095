
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-chevron-down
 * @tagname hzn-icon-chevron-down
 * @summary An individual icon for Horizon
 */
export class HznIconChevronDown extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M4.93005 9.81999C4.93005 9.63999 5.00005 9.45999 5.14005 9.31999C5.42005 9.03999 5.87005 9.03999 6.14005 9.31999L12.0001 15.18L17.8601 9.31999C18.1401 9.03999 18.5901 9.03999 18.8601 9.31999C19.1301 9.59999 19.1401 10.05 18.8601 10.32L12.5001 16.68C12.2201 16.96 11.7701 16.96 11.5001 16.68L5.14005 10.32C5.00005 10.18 4.93005 9.99999 4.93005 9.81999Z" fill="currentColor" class="stroked"></path><path d="M4.93005 9.81999C4.93005 9.63999 5.00005 9.45999 5.14005 9.31999C5.42005 9.03999 5.87005 9.03999 6.14005 9.31999L12.0001 15.18L17.8601 9.31999C18.1401 9.03999 18.5901 9.03999 18.8601 9.31999C19.1301 9.59999 19.1401 10.05 18.8601 10.32L12.5001 16.68C12.2201 16.96 11.7701 16.96 11.5001 16.68L5.14005 10.32C5.00005 10.18 4.93005 9.99999 4.93005 9.81999Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
