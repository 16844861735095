import { html } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';
import { eventEmitter } from '@horizon/common/events';
import type { HznIconButton } from '@horizon/icon-button';
import { HznInput, renderIconSlot, hasNoSlottedIcon } from '@horizon/input';
import { HznIconSearch } from '@horizon/icons/individual';
import type { ScopedElementsMap } from '@open-wc/scoped-elements/lit-element.js';
import { submit } from '@open-wc/form-helpers';

/**
 *
 * @tag hzn-search-input
 * @tagname hzn-search-input
 * @summary A plain text input specialized for search functions
 *
 * @fires {HznSearchInputInputEvent} input - Emitted every time a character is typed into the input
 * @fires {HznSearchInputChangeEvent} change - Emitted when the input is changed
 * @fires {HznSearchInputClearEvent} clear - Emitted when the input is clearable and the clear button is clicked
 * @fires {HznSearchInputSearchEvent} search - Emitted when the search icon is interactive and clicked
 */
export class HznSearchInput extends HznInput {

  /**
   * @private
   */
  #emit = eventEmitter(this);


  static get scopedElements(): ScopedElementsMap {
    return {
      ...super.scopedElements,
      'hzn-icon-search': HznIconSearch
    };
  }

  // INTERNAL ACCESS PROPERTIES
  /**
   * The inner native button element that can be clicked to perform searches. Defaults to null if `interactive-icon` is not set
   */
  get innerSearchButton() {
    return this.shadowRoot?.querySelector<HznIconButton>('.is-search-button')?.innerButton || null;
  }

  /**
   * Set the search icon to display as an interactive button that emits an event for performing searches
   */
  @property({ type: Boolean, attribute: 'interactive-icon' }) interactiveIcon?: boolean = false;

  constructor() {
    super();
    // default search inputs to non-sensitive always
    this.type = 'text';
    this.sensitive = false;
  }

  /**
   * Programmatically perform a search if `interactive-icon` is set
   */
  search() {
    if (this.innerSearchButton) {
      this.innerSearchButton.click();
    }
  }


  /**
   * @private
   */
  #handleSearchButtonClick(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (this.form) {
      submit(this.form);
    } else {
      this.#emit({ type: 'search' });
    }
  }

  /**
   * @private
   */
  [hasNoSlottedIcon]() {
    return true;
  }

  /**
   * @private
   */
  [renderIconSlot]() {
    if (this.interactiveIcon && (!this.disabled && !this.readonly)) {
      return html`<hzn-icon-button @click=${this.#handleSearchButtonClick} class="is-search-button is-icon-button" label="Click to search" input-button tone="interactive">
        <hzn-icon-search></hzn-icon-search>
      </hzn-icon-button>`;
    }

    return html`<span class="input-trailing-icon" role="presentation">
      <hzn-icon-search></hzn-icon-search>
    </span>`;
  }

  willUpdate() {
    // hardcoded overrides of hzn-input options
    // these will always be set right before any update
    this.type = 'text';
    this.sensitive = false;

  }
}
