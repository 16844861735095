import { LitElement, TemplateResult, html } from '@horizon/base';
import { property } from '@horizon/base/decorators.js';

import ColumnStyles from './column.css.js';
import { ColumnWidths } from '../types.js';


/**
 *
 * @tag hzn-column
 * @tagname hzn-column
 * @summary A single column defined in hzn-columns
 */
export class HznColumn extends LitElement {
  static styles = [ColumnStyles];

  /**
   * Set the width of a column
   * @playroomValues {'1/6' | '1/5' | '1/4' | '1/3' | '2/5' | '1/2' | '3/5' | '2/3' | '3/4' | '4/5' | '5/6' | 'content'}
   */
  @property({ type: String, reflect: true }) width?: ColumnWidths;

  render(): TemplateResult {
    return html`<div class="column">
      <slot></slot>
    </div>`;
  }
}


