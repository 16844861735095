import { configureStore } from '@reduxjs/toolkit';
import { CheckoutApi } from '../api/CheckoutApi';
import { CashDrawerApi } from '../api/CashDrawerApi';
import { CardFlowApi } from '../api/CardFlowApi';
import toastReducer from '../components/toast/ToastSlice';
import basketSearchReducer from '../pages/BasketSearch/BasketSearchSlice';
import authReducer from './AuthSlice';
import hardwareReducer from './HardwareSlice';
import paymentReducer from './PaymentSlice';
import { SystemInteractionApi } from '../api/SystemInteractionApi';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
export const store = configureStore({
    reducer: {
        toast: toastReducer,
        payment: paymentReducer,
        basketSearch: persistReducer({ key: 'basketSearch', storage }, basketSearchReducer),
        auth: authReducer,
        hardware: hardwareReducer,
        // Add the generated reducer as a specific top-level slice
        [CheckoutApi.reducerPath]: CheckoutApi.reducer,
        [CashDrawerApi.reducerPath]: CashDrawerApi.reducer,
        [CardFlowApi.reducerPath]: CardFlowApi.reducer,
        [SystemInteractionApi.reducerPath]: SystemInteractionApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
        CheckoutApi.middleware,
        CashDrawerApi.middleware,
        CardFlowApi.middleware,
        SystemInteractionApi.middleware,
    ]),
});
export const persistor = persistStore(store);
