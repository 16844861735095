import '@horizon/icons/individual/hzn-plus';
import { Card, CardRow } from '@src/components/Card';
import React, { useState } from 'react';
import { getTotalEquity, getVehicleName } from '@src/utils/BasketFns';
import { AsCurrency } from '@src/utils/Formatting';
import { FlexColumn } from '@src/components/Flex';
export const PositiveEquity = ({ basket }) => {
    const [verhicleName] = useState(getVehicleName(basket));
    const [equityAmount] = useState(AsCurrency(getTotalEquity(basket)));
    const [ownerNames] = useState(basket.lineItems[0].participants
        .filter(p => p.roles.includes('TITLE_HOLDER'))
        .map(p => p.name)
        .join(' and '));
    return (React.createElement("hzn-stack", { space: "medium", class: "pt-3" },
        React.createElement("hzn-heading", { as: "h3", size: "xsmall", class: "ps-3" }, "Review Equity Bank Draft"),
        React.createElement("ol", null,
            React.createElement("li", null, "Review the bank draft preview before continuing to the next step."),
            React.createElement("li", null, "The full amount of positive equity will be issued as a bank draft for an Appraisal-purchase."),
            React.createElement("li", null, "The bank draft will print after the cart is fully tendered."),
            React.createElement("li", null, "The bank draft will be issued to the owner(s) listed on the Title of the Appraisal-purchase vehicle."),
            React.createElement("li", null, "Partner with a Manager on Duty if an exception is needed on a bank draft.")),
        React.createElement("div", { className: "ps-3 w-100" },
            React.createElement(Card, { title: "Bank Draft Preview", titleHeader: "h3", titleSize: "xsmall" },
                React.createElement(FlexColumn, { className: "gap-3" },
                    React.createElement(CardRow, { label: `Available equity from ${verhicleName}`, value: React.createElement("hzn-text", { weight: "bold" }, equityAmount) }),
                    React.createElement("hzn-divider", null),
                    React.createElement(CardRow, { label: `Amount to be issued as bank draft`, value: React.createElement("hzn-text", { weight: "bold" }, equityAmount) }),
                    React.createElement("hzn-divider", null),
                    React.createElement(CardRow, { label: "Bank draft to be issued to", value: ownerNames }))))));
};
