import { CustomElement } from '@horizon/common/types';
import { HznInline } from './inline/inline.js';

// JSX / JS GLOBALS
declare global {
  interface HTMLElementTagNameMap {
    'hzn-inline': HznInlineInstance;
  }
  namespace JSX {
    interface IntrinsicElements {
      ['hzn-inline']: CustomElement<HznInlineInstance, HznInlineHTML>;
    }
  }
}

// INSTANCE
export type HznInlineInstance = HznInline;

// ATTRS & PROPS
export const inlineTags = ['ul', 'ol', 'div'] as const;
export type InlineTags = typeof inlineTags[number];

export type HznInlineSpace = 'none' | 'xxsmall' | 'xsmall' | 'small' | 'base' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
export type HznInlineAs = 'ul' | 'ol' | 'div';
export type HznInlineCollapseBelow = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
export type HznInlineAlignY = 'stretch' | 'start' | 'end' | 'center' | 'baseline';
export type HznInlineAlignX = 'stretch' | 'start' | 'end' | 'center' | 'between' | 'evenly' | 'around';

export type HznInlineHTML = {
  space?: HznInlineSpace;
  as?: HznInlineAs;
  reverse?: boolean;
  wrap?: boolean;
  'collapse-below'?: HznInlineCollapseBelow;
  'align-y'?: HznInlineAlignY;
  'align-x'?: HznInlineAlignX;
}
