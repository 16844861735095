import React from 'react';
import { CardRow } from '../../../../components/Card';
import { AsCurrency } from '@src/utils/Formatting';
import { HorizonButton } from '../../../../components/horizon/HorizonButton';
import { useAppSelector } from '@src/hooks/useAppSelector';
import { selectCardReaderStatus, selectCashDrawerStatus } from '@src/store/HardwareSlice';
import { setPayment } from '@src/store/PaymentSlice';
import { useAppDispatch } from '@src/hooks/useAppDispatch';
export const PaymentReadCard = ({ payment, basketIsBalanced }) => {
    const dispatch = useAppDispatch();
    const cardReaderStatus = useAppSelector(selectCardReaderStatus);
    const cashDrawerStatus = useAppSelector(selectCashDrawerStatus);
    // in order to process a payment:
    // 1. the negative equity balance must be accounted for
    // 2. a cash drawer must be assigned to this workstations
    // 3. if this is a debit payment the card reader hardware must be functional
    // note: we don't explicitly check for functional cash drawer hardware because BOAs/BOMs can manually open the cash drawer with a key.
    const canProcessPayment = basketIsBalanced && !!cashDrawerStatus.assigned && (payment.paymentType !== "DEBIT" || !!cardReaderStatus.ready);
    return (React.createElement(React.Fragment, null,
        React.createElement(CardRow, { className: "pb-4", label: "Amount", value: React.createElement("hzn-text", { weight: "bold", size: "base" }, AsCurrency(payment.amount)) }),
        payment.paymentType === 'DEBIT' && !!payment.cardSuffix && (React.createElement(CardRow, { className: "pb-4", label: 'Card Number', value: `...${payment.cardSuffix}` })),
        (payment.paymentType === 'CHECK' || payment.paymentType === 'CERTIFIED_FUNDS') && !!payment.checkNumber && (React.createElement(CardRow, { className: "pb-4", label: 'Check Number', value: payment.checkNumber })),
        // TODO: establish statuses for new vs. saved vs. to-be-processed vs. submitted & voidable payments.
        // need to build out state for managing that all.
        payment.status == 'NEW' && (React.createElement(HorizonButton, { compact: true, variant: "primary", onClick: () => dispatch(setPayment(payment)), disabled: !canProcessPayment }, "Process Payment")),
        (payment.status == 'SUBMITTED' || payment.status == 'PAID') && (React.createElement(HorizonButton, { compact: true, variant: "secondary", tone: "critical", onClick: () => { } }, "Void Payment"))));
};
