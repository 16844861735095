
// GENERATED FILE, DO NOT MANUALLY EDIT

import { classMap } from '@horizon/base/directives';
import { TemplateResult, html } from '@horizon/base';
import { HznIconBase } from '../../icon/icon-base.js';
import './types.js';

/**
 *
 * @tag hzn-icon-close
 * @tagname hzn-icon-close
 * @summary An individual icon for Horizon
 */
export class HznIconClose extends HznIconBase {

  render(): TemplateResult {
    return html`<div class="icon"
      aria-hidden="true"
      class=${classMap({
        icon: true,
        'is-variant-stroked': this.variant !== 'filled', // default value should be any value other than `filled`
        'is-variant-filled': this.variant === 'filled',
      })}><svg viewBox="0 0 24 24"><path d="M13 12L19.5 5.50001C19.78 5.22001 19.78 4.77001 19.5 4.50001C19.22 4.23001 18.77 4.22001 18.5 4.50001L12 11L5.50004 4.50001C5.22004 4.22001 4.77004 4.22001 4.50004 4.50001C4.23004 4.78001 4.22004 5.23001 4.50004 5.50001L11 12L4.50004 18.5C4.22004 18.78 4.22004 19.23 4.50004 19.5C4.64004 19.64 4.82004 19.71 5.00004 19.71C5.18004 19.71 5.36004 19.64 5.50004 19.5L12 13L18.5 19.5C18.64 19.64 18.82 19.71 19 19.71C19.18 19.71 19.36 19.64 19.5 19.5C19.78 19.22 19.78 18.77 19.5 18.5L13 12Z" fill="currentColor" class="stroked"></path><path d="M13 12L19.5 5.50001C19.78 5.22001 19.78 4.77001 19.5 4.50001C19.22 4.23001 18.77 4.22001 18.5 4.50001L12 11L5.50004 4.50001C5.22004 4.22001 4.77004 4.22001 4.50004 4.50001C4.23004 4.78001 4.22004 5.23001 4.50004 5.50001L11 12L4.50004 18.5C4.22004 18.78 4.22004 19.23 4.50004 19.5C4.64004 19.64 4.82004 19.71 5.00004 19.71C5.18004 19.71 5.36004 19.64 5.50004 19.5L12 13L18.5 19.5C18.64 19.64 18.82 19.71 19 19.71C19.18 19.71 19.36 19.64 19.5 19.5C19.78 19.22 19.78 18.77 19.5 18.5L13 12Z" fill="currentColor" class="filled"></path></svg></div>`;
  }
}
