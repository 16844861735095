import { createSlice } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
const initialState = {
    payment: undefined,
    startTime: undefined,
    paymentDetails: undefined,
    step: undefined,
};
export const PaymentSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setPayment: (state, action) => {
            state.payment = action.payload;
            state.startTime = formatISO(Date.now());
            state.step = 'Start';
        },
        setPaymentDetails: (state, action) => {
            state.paymentDetails = action.payload;
        },
        setProcessStep: (state, action) => {
            state.step = action.payload;
        },
        reset: (state, action) => initialState,
    },
});
export const { setPayment, setPaymentDetails, setProcessStep, reset } = PaymentSlice.actions;
export const selectPayment = (state) => state.payment;
export default PaymentSlice.reducer;
