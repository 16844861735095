/* eslint-disable @typescript-eslint/no-explicit-any */
import { Validator } from '@open-wc/form-control';

export const requiredValidator: Validator = {
  attribute: 'required',
  key: 'valueMissing',
  message(instance: any) {
    if (instance.errorMessages && instance.errorMessages.valueMissing) {
      if (instance.errorMessages.valueMissing instanceof Function) {
        return instance.errorMessages.valueMissing(instance);
      }
      return instance.errorMessages.valueMissing;
    }
    return `${instance.displayName ? instance.displayName : 'This field'} is required`;
  },
  isValid(instance: any) {
    if (instance.validationTarget) {
      // reverse the browser validity
      return !instance.validationTarget.validity.valueMissing;
    }
    return true;
  },
};

export const typeMismatchValidator: Validator = {
  attribute: 'required',
  key: 'typeMismatch',
  message(instance: any) {
    if (instance.errorMessages && instance.errorMessages.typeMismatch) {
      if (instance.errorMessages.typeMismatch instanceof Function) {
        return instance.errorMessages.typeMismatch(instance);
      }
      return instance.errorMessages.typeMismatch;
    }
    return `${instance.displayName ? `${instance.displayName} must be a valid` : 'Enter a valid'} ${instance.type} `;
  },
  isValid(instance: any) {
    if (instance.validationTarget) {
      // reverse the browser validity
      return !instance.validationTarget.validity.typeMismatch;
    }
    return true;
  },
};

export const minLengthValidator: Validator = {
  attribute: 'minlength',
  key: 'tooShort',
  message(instance: any): string {
    if (instance.errorMessages && instance.errorMessages.tooShort) {
      if (instance.errorMessages.tooShort instanceof Function) {
        return instance.errorMessages.tooShort(instance);
      }
      return instance.errorMessages.tooShort;
    }
    return `${instance.displayName ? `${instance.displayName} must be` : 'Enter'} at least ${instance.minlength} characters`;
  },
  isValid(instance: any, value: string): boolean {
    // keep as a custom validator that doesnt pass through the browser default validity so that programmatically setting the
    // value will still trigger a validity change
    /** If no value is provided, or the input is a number or date, this validator should return true */
    if (!value || ['number', 'date'].includes(instance.type)) {
      return true;
    }

    if (!!value && value !== instance.defaultInitialValue && instance.minlength > value.length) {
      return false;
    }

    return true;
  },
};

export const maxLengthValidator: Validator = {
  attribute: 'maxlength',
  key: 'tooLong',
  message(instance: any): string {
    if (instance.errorMessages && instance.errorMessages.tooLong) {
      if (instance.errorMessages.tooLong instanceof Function) {
        return instance.errorMessages.tooLong(instance);
      }
      return instance.errorMessages.tooLong;
    }

    return `${instance.displayName ? `${instance.displayName} must be` : 'Enter'} less than ${instance.maxlength} characters`;
  },
  isValid(instance: any, value: string): boolean {
    /** If no value is provided, or the input is a number or date, this validator should return true */
    if (!value || ['number', 'date'].includes(instance.type)) {
      return true;
    }

    if (!!value && value !== instance.defaultInitialValue && value.length > instance.maxlength) {
      return false;
    }

    return true;
  },
};

export const maxValidator: Validator = {
  attribute: 'max',
  key: 'rangeOverflow',
  message(instance: any): string {
    if (instance.errorMessages && instance.errorMessages.rangeOverflow) {
      if (instance.errorMessages.rangeOverflow instanceof Function) {
        return instance.errorMessages.rangeOverflow(instance);
      }
      return instance.errorMessages.rangeOverflow;
    }

    return `${instance.displayName ? `${instance.displayName} must be` : 'Enter a value that is'} less than ${instance.max}`;
  },
  isValid(instance: any): boolean {
    if (instance.validationTarget) {
      // reverse the browser validity
      return !instance.validationTarget.validity.rangeOverflow;
    }
    return true;
  },
};

export const minValidator: Validator = {
  attribute: 'min',
  key: 'rangeUnderflow',
  message(instance: any): string {
    if (instance.errorMessages && instance.errorMessages.rangeUnderflow) {
      if (instance.errorMessages.rangeUnderflow instanceof Function) {
        return instance.errorMessages.rangeUnderflow(instance);
      }
      return instance.errorMessages.rangeUnderflow;
    }

    return `${instance.displayName ? `${instance.displayName} must be` : 'Enter a value that is'} at least ${instance.min}`;
  },
  isValid(instance: any): boolean {
    if (instance.validationTarget) {
      // reverse the browser validity
      return !instance.validationTarget.validity.rangeUnderflow;
    }
    return true;
  },
};

export const stepValidator: Validator = {
  attribute: 'step',
  key: 'stepMismatch',
  message(instance: any): string {
    if (instance.errorMessages && instance.errorMessages.stepMismatch) {
      if (instance.errorMessages.stepMismatch instanceof Function) {
        return instance.errorMessages.stepMismatch(instance);
      }
      return instance.errorMessages.stepMismatch;
    }

    return `${instance.displayName ? `Enter a ${instance.displayName}` : 'Enter a value'} that is evenly divisible by ${instance.step}`;
  },
  isValid(instance: any): boolean {
    if (instance.validationTarget) {
      // reverse the browser validity
      return !instance.validationTarget.validity.stepMismatch;
    }
    return true;
  },
};

export const patternValidator: Validator = {
  attribute: 'pattern',
  key: 'patternMismatch',
  message(instance: any): string {
    if (instance.errorMessages && instance.errorMessages.patternMismatch) {
      if (instance.errorMessages.patternMismatch instanceof Function) {
        return instance.errorMessages.patternMismatch(instance);
      }

      return instance.errorMessages.patternMismatch;
    }

    return `${instance.displayName ? `Enter a ${instance.displayName}` : 'Enter a value'} that matches the requested pattern`;
  },
  isValid(instance: any): boolean {
    if (instance.validationTarget) {
      // reverse the browser validity
      return !instance.validationTarget.validity.patternMismatch;
    }
    return true;
  },
};

export const customValidator: Validator = {
  message(instance: any) {
    if (instance.validation && instance.validation instanceof Function) {
      return instance.validation(instance).message;
    }
    return '';
  },

  isValid(instance: any) {
    if (instance.validation && instance.validation instanceof Function) {
      return instance.validation(instance).valid;
    }
    return true;
  },
};

export const defaultValidators: Validator[] = [
  requiredValidator,
  typeMismatchValidator,
  minLengthValidator,
  maxLengthValidator,
  maxValidator,
  minValidator,
  stepValidator,
  patternValidator,
  customValidator,
];
