import { emptySplitApi as api } from './RtkQueryClient-Empty';
export const addTagTypes = ['Baskets', 'CloudEvent', 'Orders', 'Users', 'Workflows', 'Workstations'];
const injectedRtkApi = api
    .enhanceEndpoints({
    addTagTypes,
})
    .injectEndpoints({
    endpoints: build => ({
        getBasket: build.query({
            query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}` }),
            providesTags: ['Baskets'],
        }),
        getBasketSummaries: build.query({
            query: queryArg => ({
                url: `/api/v1/baskets`,
                params: {
                    Term: queryArg.term,
                    BasketIds: queryArg.basketIds,
                    BasketType: queryArg.basketType,
                    OwnerId: queryArg.ownerId,
                    SellingLocationId: queryArg.sellingLocationId,
                    SourceSystemId: queryArg.sourceSystemId,
                    Statuses: queryArg.statuses,
                    PaymentStates: queryArg.paymentStates,
                    SourceSystemIdType: queryArg.sourceSystemIdType,
                    PageNumber: queryArg.pageNumber,
                    PageSize: queryArg.pageSize,
                    SortField: queryArg.sortField,
                    SortDirection: queryArg.sortDirection,
                },
            }),
            providesTags: ['Baskets'],
        }),
        getOrderTenderSummary: build.query({
            query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}/order-tender-summary` }),
            providesTags: ['Baskets'],
        }),
        getBasketPayments: build.query({
            query: queryArg => ({
                url: `/api/v1/baskets/${queryArg.basketId}/payments`,
                params: {
                    PaymentStatuses: queryArg.paymentStatuses,
                    CheckNumbers: queryArg.checkNumbers,
                    PaymentTypes: queryArg.paymentTypes,
                    PageNumber: queryArg.pageNumber,
                    PageSize: queryArg.pageSize,
                    SortField: queryArg.sortField,
                    SortDirection: queryArg.sortDirection,
                },
            }),
            providesTags: ['Baskets'],
        }),
        addBasketPayment: build.mutation({
            query: queryArg => ({
                url: `/api/v1/baskets/${queryArg.basketId}/payments`,
                method: 'POST',
                body: queryArg.body,
            }),
            invalidatesTags: ['Baskets'],
        }),
        handleBasketPaymentAction: build.mutation({
            query: queryArg => ({
                url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                method: 'POST',
                body: queryArg.body,
            }),
            invalidatesTags: ['Baskets'],
        }),
        deletePayment: build.mutation({
            query: queryArg => ({
                url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Baskets'],
        }),
        updatePayment: build.mutation({
            query: queryArg => ({
                url: `/api/v1/baskets/${queryArg.basketId}/payments/${queryArg.paymentId}`,
                method: 'PATCH',
                body: queryArg.body,
            }),
            invalidatesTags: ['Baskets'],
        }),
        getBasketWorkflowProgression: build.query({
            query: queryArg => ({ url: `/api/v1/baskets/${queryArg.basketId}/workflow` }),
            providesTags: ['Baskets'],
        }),
        sendUpdatedWorkflowProgression: build.mutation({
            query: queryArg => ({
                url: `/api/v1/baskets/${queryArg.basketId}/workflow`,
                method: 'POST',
                body: queryArg.workflowProgression,
            }),
            invalidatesTags: ['Baskets'],
        }),
        options: build.mutation({
            query: () => ({ url: `/events`, method: 'OPTIONS' }),
            invalidatesTags: ['CloudEvent'],
        }),
        receiveCloudEvent: build.mutation({
            query: queryArg => ({ url: `/events`, method: 'POST', body: queryArg.body }),
            invalidatesTags: ['CloudEvent'],
        }),
        createOrders: build.mutation({
            query: queryArg => ({ url: `/api/v1/orders`, method: 'POST', body: queryArg.orderTenderRequestDto }),
            invalidatesTags: ['Orders'],
        }),
        getCheckoutAccess: build.query({
            query: queryArg => ({
                url: `/api/v1/users/checkout-access`,
                params: { storeLocationNumber: queryArg.storeLocationNumber },
            }),
            providesTags: ['Users'],
        }),
        getDocumentWorkflowStatus: build.query({
            query: queryArg => ({ url: `/api/v1/workflows/${queryArg.cartId}` }),
            providesTags: ['Workflows'],
        }),
        getWorkstationByNameAndLocation: build.query({
            query: queryArg => ({ url: `/api/v1/workstations/${queryArg.name}/${queryArg.storeLocationId}` }),
            providesTags: ['Workstations'],
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as CheckoutApi };
export const { useGetBasketQuery, useGetBasketSummariesQuery, useGetOrderTenderSummaryQuery, useGetBasketPaymentsQuery, useAddBasketPaymentMutation, useHandleBasketPaymentActionMutation, useDeletePaymentMutation, useUpdatePaymentMutation, useGetBasketWorkflowProgressionQuery, useSendUpdatedWorkflowProgressionMutation, useOptionsMutation, useReceiveCloudEventMutation, useCreateOrdersMutation, useGetCheckoutAccessQuery, useGetDocumentWorkflowStatusQuery, useGetWorkstationByNameAndLocationQuery, } = injectedRtkApi;
