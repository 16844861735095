import { useEventListener } from '../../hooks/useEventListener';
import React, { useRef } from 'react';
const Select = ({ label, className, onSelectionChanged, children, value, ...attrs }) => {
    const ref = useRef();
    //@ts-ignore 'clear' is a synthetic event emitted by hzn-select
    useEventListener(ref, 'clear', target => onSelectionChanged(undefined));
    useEventListener(ref, 'input', target => onSelectionChanged(target.value));
    useEventListener(ref, 'change', target => onSelectionChanged(target.value));
    return (React.createElement("hzn-select", { ref: ref, ...attrs, value: value ?? '', class: className, name: label, "aria-label": label, "display-name": label },
        label,
        children));
};
export default Select;
